import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Sports } from 'src/app/models/sports'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SportService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  get() {
    return this.http.get(this.api.getUrl(`/api/sport`), this.api.getOptions());
  }

  post(body): Observable<Sports> {
    return this.http.post<Sports>(this.api.getUrl('/api/profile'), body, this.api.getOptions());
  }
}
