import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from '../storage.service';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly _endpoint: string;
  private _token: string | null = null;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private toast: ToastController
  ) {
    this._endpoint = environment.apiEndpoint;
   }

  get endpoint(): string {
    return this._endpoint;
  }

  get token(): string | null {
    return this._token;
  }

  set token(value: string | null) {
    this.storage.set('jwt_token', value);
    this._token = value;
  }

  public getUrl(url: string): string {
    return `${this._endpoint}${url}`;
  }

  public getOptions(): { headers: HttpHeaders } {
    // console.log("get options")
    // console.log(this._token)
    if (this._token === null) {
      this._token = localStorage.getItem('jwt_token')
    }
    return {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${this._token}`,
      }),
    };
  }

  async toastError(err) {
    const toast = await this.toast.create({message: "Une erreur s'est produite", duration: 3000});
    await toast.present();
    console.log(err)
  }
  
}
