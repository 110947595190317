import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cgv-cgu',
  templateUrl: './cgv-cgu.component.html',
  styleUrls: ['./cgv-cgu.component.scss'],
})
export class CgvCguComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
