import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-inscription',
  templateUrl: './header-inscription.component.html',
  styleUrls: ['./header-inscription.component.scss'],
})
export class HeaderInscriptionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
