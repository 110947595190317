import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ModalRegisteredComponent } from '../modal/modal-registered/modal-registered.component';
import { ModalMailComponent } from '../modal/modal-mail/modal-mail.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-description',
  templateUrl: './description.component.html',
  styleUrls: ['./description.component.scss'],
})
export class DescriptionComponent implements OnInit {
  url: any;
  message: string;
  imagePath: string;

  constructor(
    private router: Router,
    private location: Location,
    public dialog: MatDialog
  ) { }

  ngOnInit() {}

  onFileChanged(event) {
    const files = event.target.files;
    if (files.length === 0)
        return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
        this.message = 'Seul les fichiers images sont acceptés';
        return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
        this.url = reader.result;
    };
  }

  onSubmit() {
  }

  onPrevious(): void {
    this.location.back();
  }

  openDialogRegistered() {
    const dialogRef = this.dialog.open(ModalRegisteredComponent, {
      width: '550px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'true') {
        this.onSubmit();
        this.router.navigate(['coach-inscription-done'])
      }
    });
  }

  openDialogMail() {
    const dialogRef = this.dialog.open(ModalMailComponent, {
      width: '550px'
    });
  }

}
