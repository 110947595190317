import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GouvService {

  constructor(
    private http: HttpClient
  ) { }

  getGouvRegions() {
    return this.http.get('https://geo.api.gouv.fr/regions')
  }

  getGouvSpecificRegions(idRegion) {
    return this.http.get(`https://geo.api.gouv.fr/regions/${idRegion}`)
  }

  getGouvCity(postalCode) {
    return this.http.get(`https://geo.api.gouv.fr/communes?codePostal=${postalCode}`)
  }
}
