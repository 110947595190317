import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GymService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  post(body) {
    return this.http.post(this.api.getUrl('/api/gym'), body, this.api.getOptions());
  }

  gymConnection(body) {
    return this.http.post(this.api.getUrl('/api/gym-company'), body, this.api.getOptions());
  }

  get(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/gym/${uuid}`), this.api.getOptions());
  }

  /*getAvailability(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/lesson-reservation/coach/${uuid}`), this.api.getOptions());
  }*/
}
