import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  createCard() {
    return this.http.get(this.api.getUrl(`/api/stripe/source/new/card`), this.api.getOptions())
  }

  retrieveCard(stripeAccountUiid: string): Observable<any> {
    const options = {
      params: {
        stripeAccountUiid: stripeAccountUiid,
      }
      , ...this.api.getOptions(),
    }
    return this.http.get(this.api.getUrl(`/api/stripe/source/card`), options);
  }

  createStripeAccount(body) {
    return this.http.post(this.api.getUrl(`/api/stripe/account/new`), body, this.api.getOptions())
  }

  postIBAN(body) {
    return this.http.post(this.api.getUrl(`/api/stripe/source/new/iban`), body, this.api.getOptions())
  }

  validation(body) {
    return this.http.post(this.api.getUrl(`/api/stripe/validation-account`), body, this.api.getOptions())
  }
}
