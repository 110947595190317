import { Component, OnInit } from '@angular/core';
import { ToggleMenuService } from 'src/app/services/toggle-menu.service';

@Component({
  selector: 'app-header-logged-out',
  templateUrl: './header-logged-out.component.html',
  styleUrls: ['./header-logged-out.component.scss'],
})
export class HeaderLoggedOutComponent implements OnInit {

  constructor(
    private toggleMenuService: ToggleMenuService
  ) { }

  ngOnInit() { }

  toggleMenu() {
    this.toggleMenuService.changeCollapsed(false)
  }

}
