// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  disableConsoleLogs: false,
  apiEndpoint: 'https://dev.api.coachs-france.com',//'https://api.coachs.usts.fr'//
  stripe: {
    key: 'pk_test_51Kz1ivBSwFNEmh6PLQfqGT9Q9iPNQSrS9Vts8Lp3hOmv5GoyGtaasdKUBMmyPiOfoyYAayvYH69Wn75T3LZysQJ800l85Sixwo'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
