import { Injectable } from '@angular/core';
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  getAllRooms(page: number) {
    return this.http.get(this.api.getUrl(`/api/room?direction=ASC&limit=20&page=${page}`), this.api.getOptions());
  }

  getAll(page: number) {
    return this.http.get(this.api.getUrl(`/api/room?page=${page}`), this.api.getOptions());
  }

  createRoom(body) {
    return this.http.post(this.api.getUrl(`/api/room`), body, this.api.getOptions());
  }

  getInfos(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/room/${uuid}`), this.api.getOptions());
  }

  getPage(uuid: string, page: number) {
    return this.http.get(this.api.getUrl(`/api/room/${uuid}/message?page=${page}`), this.api.getOptions());
  }

  lastMessage(uuid: number) {
    return this.http.get(this.api.getUrl(`/api/room/${uuid}/last-message`), this.api.getOptions())
  }

  sendMessage(uuid: string, body) {
    return this.http.post(this.api.getUrl(`/api/room/${uuid}/message`), body, this.api.getOptions());
  }

  readMessage(uuid: string) {
    return this.http.patch(this.api.getUrl(`/api/room/${uuid}/message/read`), {}, this.api.getOptions());
  }

  unreadMessages(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/room/${uuid}/unread-message`), this.api.getOptions());
  }

  search() {
    return this.http.get(this.api.getUrl(`/api/room/search-list-user`), this.api.getOptions());
  }

  createAdminRoom(body) {
    return this.http.post(this.api.getUrl(`/api/room-admin`), body, this.api.getOptions())
  }
}