import { Component, OnInit } from '@angular/core';
import { InvoiceService } from 'src/app/services/api/invoice.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
})
export class InvoicesComponent implements OnInit {
  // Page Title
  parentMessage = "Mes paiements";

  constructor(
    private invoiceService: InvoiceService
  ) { }

  ngOnInit() {
    this.invoiceService.getInvoices().subscribe(res => {
      console.log(res)
    })
  }

}
