export const article1 = 
    `<span class="big_text">LE CONCEPT COACHS</span>

    Depuis plusieurs années nous travaillons sur le concept de cette plateforme de mise en relation entre les Coachs sportifs et les clients.
    Après plusieurs années de réflexion, d’études et de constatations nous en avons conclu que ce marché n’était pas optimisé comme il se doit, que ce soit pour le client ou pour le coach sportif.
    Il s’avère que la demande est réelle et croissante mais que l’offre n’est pas adaptée.
    1 personne sur 3 aimerait faire appel à un coach sportif, mais ne le fait pas.
    De plus, beaucoup de Coachs sportifs donnent uniquement quelques cours par semaine, parfois aucun ! La raison ? Un tarif trop élevé !
    
    Nous avons deux objectifs :
    
    1 – Rendre le coaching sportif accessible à tous !
    2 – Remplir vos plannings et augmenter votre chiffre d’affaires !
    
    On t’explique comment atteindre ces objectifs, les conditions et le fonctionnement. 
    
    Nous avons opté pour des tarifs fixes que nous avons définis.
    Notre politique tarifaire a été étudiée afin de proposer le prix le plus bas du marché dans le monde du coaching sportif, mais rentable pour un coach sportif par rapport à ses charges, taxes et impôts.
    
    Nous proposons différentes offres pour différents clients
    
    - Pour les particuliers il y a les séances (1h) CLASSIC et SPÉCIFIQUE, en solo ou à plusieurs (max 4 personnes), pratiquées en extérieur, en salle de sport ou à domicile. 
    Et des évènements BOOTCAMP (1h) créés par le coach.
    
    - Des cours collectifs d’une heure pour des entreprises qui souhaitent réserver un cours pour les collaborateurs dans le cadre d’une amélioration de vie au travail.
    
    - Des cours collectifs d’une heure pour les Salles(majuscule ?) de Sport qui recherchent un coach pour assurer les cours collectifs dans leur établissement.
    
    Si tu n’es pas convaincu, contacte-nous pour supprimer ton profil.
    (peut-être mettre à la fin ?!)
    `;

export const article2 = 
    `<u>LES TARIFS</u>

    Découvre les tarifs des différentes offres proposées hors commission. 
    
    <b>CLASSIC</b>
    La séance Classic est à un tarif fixe de 19,99€/heure pour une personne.
    En extérieur dans un lieu défini par le coach ou dans la/les salle(s) de sport renseignée(s).
    Option supplémentaire : + 10 €/ personne (max 4 personnes/séance) ; + 10 € déplacement à domicile.
    
    <b>SPÉCIFIQUE</b>
    La séance Spécifique est à partir de 19,99€/heure pour une personne qui varie en fonction de la discipline.
    En extérieur dans un lieu défini par le coach ou dans la/les salle(s) de sport renseignée(s).
    Option supplémentaire : + 10 €/ personne (max 4 personnes/séance) ; + 10 € déplacement à domicile.
    Liste des prix/disciplines : Boxe 29,99€ ; Pilates 29,99€ ; Tennis (hors réservation terrain) 29,99€ ; Yoga 19,99€ ; Zumba 19,99€ ; Running 19,99€ ; Danse 19,99€ ; Stretching 19,99€ 
    Si ta discipline spécifique n’est pas dans cette liste, contacte-nous.
    
    <b>BOOTCAMP</b>
    Tu peux créer ton propre évènement BootCamp d’une heure via ton compte. (minimum 15 personnes et maximum 30 personnes)
    Le prix est à un tarif fixe de 2,99€/personne. 
    Soit l’heure minimum à 44,85€ 
    
    <b>COURS COLLECTIF EN ENTREPRISE</b>
    Une entreprise peut réserver un cours collectif d’une heure pour 10 personnes minimum et 50 maximum.
    Le prix par personne est de 3€ + des frais de déplacement de 20€/cours 
    Soit l’heure minimum à 50€
    
    <b>COURS COLLECTIF EN SALLE DE SPORT</b>
    L’heure est fixée à un tarif de 20€. 
    
    
    
    
    
    <u>NOS COMMISSIONS</u>
    
    Nos commissions varient en fonction des offres proposées.
    
    Pour les séances <b>CLASSIC</b>, <b>SPÉCIFIQUE</b>, et <b>BOOTCAMP</b> nous prenons une commission de 10% HT du prix total de chaque séance réservée.
    
    Pour les <b>Cours Collectifs en Entreprise</b> nous prenons une commission de 20% HT du prix de chaque cours réservé.
    
    Pour les <b>Cours Collectifs en Salle de Sport</b> nous prenons une commission à la salle de sport et non au coach.
    
    
    
    Le versement des paiements de chaque réservation se fait automatiquement 72h après via STRIPE dès que tu as finalisé le rendez-vous. C’est pour cela que tu devras être attentif à bien <b>FINALISER</b> tes rendez-vous dans ton agenda. 
    Une notification te sera envoyée après chaque rendez-vous.
    Tu trouveras l’historique de tes paiements via ton compte dans l’onglet « paiements », les factures sont envoyées par email.
    `;

export const article3 = 
    `LE PROFIL

    1) Statut Pro
    
    Tu as la possibilité de renseigner deux statuts pro différents, un statut pro SAP et un Normal, car un coach agréé SAP doit uniquement consacrer son activité uniquement à domicile et être sous le régime d’auto-entrepreneur. 
    Si tu souhaites proposer d’autres prestations (par exemple des bootcamps ou pour des entreprises) tu dois différencier ta comptabilité, soit créer une nouvelle société (en règle générale c’est une SASU) et donc renseigner deux statuts pro.
    
    <b><i>Tu as reçu l’agrément SAP après ton inscription. Comment faire le changement ?</i></b>
    
    <b>Obligation d’être sous le régime Auto-entrepreneur pour la conversion.</b>
    Tableau de bord > Statut pro > Sélection du statut > Convertir en SAP > Renseigner N° agrément et la date d’obtention > Enregistrer ou Ajouter un statut pro
    
    Attention : En convertissant ton statut pro en SAP, tu ne pourras uniquement donner des cours à domicile, c’est pour cela que nous te conseillons, avant de le convertir, de créer une nouvelle société si c’est pas déjà fait. Si tu as besoin d’aide pour la création de ta nouvelle structure contacte-nous.
    Dans le cas où tu as déjà la deuxième société tu peux l’ajouter directement après la conversion ou en allant sur ton Tableau de bord > Statut Pro > Ajouter un Statut pro.
    
    Exemples :
    
    -   Cas 1 : tu as renseigné uniquement un statut pro agréé SAP ou converti ton statut pro en SAP et tu n’as pas ajouté de statut pro supplémentaire, <b>alors tu peux proposer uniquement les prestations Classic et Spécifique uniquement à domicile. Toutes les autres prestations sont bloquées.</b>
    
    -   Cas 2 : tu as renseigné un statut pro normal ou tu as ajouté un statut pro Normal et un SAP <b>alors tu peux proposer toutes les prestations.</b>
    
    En savoir plus sur l’agrément SAP clique <a href=link0>ici</a>
    
    Nous recevrons une notification à chaque modification du profil afin de procéder à la validation.
    
    2) Informations bancaires
    
    Tu devras renseigner les informations bancaires de chaque statut pro afin de récolter les fruits de ton travail.
    Tu pourras les modifier à tout moment dans ton Tableau de bord > Informations Bancaires > Sélection du Statut pro > Modifier
    Attention : vérifie que ça soit le compte bancaire du statut pro correspondant.
    
    3) Ou j’exerce
    
    Tu pourras modifier à tout moment les lieux où tu exerces, sauf si tu es agréé(e) SAP et que tu n’as pas renseigné un deuxième statut pro, tu pourras uniquement régler le rayon de tes déplacements à domicile. 
    Attention au rayon de déplacement, plus il est large plus il faudra prendre en considération le temps de déplacement entre chaque rendez-vous.
    Nous recommandons pour les grandes villes un rayon moyen de 5 km, pour les moyennes 10km, et 20km pour les petites.
    
    4) Prestations 
    
    Il y a 5 types de prestations : 
    
    Séance Classique
    Séance Spécifique
    Bootcamp
    Cours collectifs en entreprise
    Cours collectifs en salle de sport
    
    Pour chaque prestation (sauf Bootcamp), tu peux renseigner plusieurs disciplines par contre attention à maîtriser celles-ci ! 
    
    Tu as la possibilité de créer des évènements Bootcamp via ton Tableau de bord.
    
    Tu es libre de modifier à tout moment les prestations que tu souhaites proposer.
    Plus tu proposes de prestations, plus tu auras de visibilité et donc plus de chance de remplir ton planning, soit augmenter ton chiffre d’affaires. Attention à maîtriser celles-ci !
    
    Donner des cours collectifs en <u>entreprise</u> et en <u>salle de sport</u> te permettront de remplir ton agenda et une opportunité d’augmenter ton chiffre d’affaire.
    
    5) Description
    
    <i>Attention : Il est interdit de renseigner des informations personnelles, numéro de téléphone, adresse mail ou encore des liens vers des réseaux sociaux ou autres sites.</i>
    
    Sur ton profil, qui sera vu par les clients, tu devras dans un premier temps, mettre une photo de type portrait et professionnelle. (Pas de nudité, filtres photo etc...)
    Ensuite il y aura deux blocs à remplir :
    Le premier sera celui où tu devras te présenter, parler de toi, raconter ton parcours sportif personnel et ton expérience professionnelle (diplôme, études...)
    Le deuxième sera celui où tu devras décrire une séance type générale, car les séances ne sont toutes pas identiques je ne t’apprends rien ;) et ça même si tu proposes plusieurs disciplines.
    `;    

export const article4 = 
    `LES FONCTIONNALITÉS

    1) L’agenda
    
    - Renseigne tes disponibilités
    Après avoir renseigné les séances/cours que tu proposes aux clients, et les lieux où tu exerces, tu vas devoir indiquer tes disponibilités afin d’être visible. Tu te doutes bien que plus tu renseignes de créneaux disponibles plus tu seras visible et plus de chance d’obtenir des rendez-vous.
    
    Comment faire ? 
    Tableau de bord > Agenda > Gérer mes disponibilités : il te suffira de renseigner tes heures disponibles par jour, ça s’appliquera automatiquement sur tout ton agenda. 
    
    Attention : des heures disponibles renseignées ça signifie un engagement pris auprès des clients, soit l’engagement d’assurer les rendez-vous à venir dans ces créneaux disponibles.
    
    - Disponibilités ok, place aux Indisponibilités !
    Effectivement tu auras sûrement des moments où tu seras indisponible, par exemple pour un rendez-vous avec un client extérieur, un rendez-vous personnel, des vacances, pause déjeuner etc... Il te suffira juste de l’indiquer et tu pourras même répéter cette indisponibilité.
    
    Comment faire ?
    Tableau de bord > Agenda > Sélectionne le créneau > Une fenêtre s’ouvre : 
    -   Renseigne dans description la raison si tu le souhaites (ex : rdv « client », rdv médecin ; pause déjeuner.....) 
    -   Répéter : aucun ; chaque jour ; chaque semaine ; une semaine sur deux
    -   Date de début et heure
    -   Date de fin et heure 
    -   Enregistrer
    
    Tu pourras à tout moment supprimer cette indisponibilité en cliquant dessus où il te sera demandé si tu souhaites la supprimer uniquement pour cette fois ou pour toutes les autres fois.
    
    
    
    
    - La validation des rendez-vous.
    Quand un client réserve un créneau disponible avec toi, tu recevras une notification et ce rendez-vous sera affiché sur ton agenda. Il faudra que tu le valides pour que le client recoive une confirmation. 
    <b><span class="red_text">NB : il y a un délai de réservation de 3h entre le moment de la prise de rendez-vous et ton prochain créneaux disponible. Tu peux gérer ce délai dans « gérer mes disponibilités »</span></b>
    Comment faire ? Les rendez-vous à valider seront de la couleur Orange sur ton agenda.
    
    Il te suffira de cliquer sur ta notification ou directement sur l’agenda en cliquant sur le rendez-vous concerné, ensuite la fenêtre s’ouvrira et tu n’auras plus qu’à cliquer sur le bouton « Valider le rendez-vous ».
    
    - La fiche du rendez-vous 
    En cliquant sur un rendez-vous la fiche apparaît, tu y trouveras toutes les informations nécessaires.
    <ul>
        <li> La date et l’heure</li>
        <li> Le client où tu pourras cliquer pour voir sa fiche client</li>
        <li> Le type de cours</li>
        <li> Le lieu de pratique</li>
        <li> Le temps bloqué avant et après</li>
    </ul>
    Très important !! Tu pourras gérer le temps entre chaque rendez-vous, nous avons paramétré un délai automatique pour chaque type de cours en fonction du lieu de pratique.
    <ul>
        <li>Pour un cours qui a lieu en extérieur ou en salle de sport nous avons bloqué 15min après afin que tu puisses avoir un temps de battement entre chaque cours.</li>
        <li>Pour un cours qui a lieu à domicile ou en entreprise nous avons bloqué 20min avant le cours et 30min après afin que tu puisses avoir le temps pour te déplacer.</li>
    </ul>
    Exemple : Imagine tu as un cours de 11h à 12h dans la salle de sport où tu exerces et un client souhaite réserver un cours à domicile, il verra que ce jour, ton prochain créneau disponible sera à 12h30. Pourquoi ? Car pour une prestation à domicile nous avons bloqué 20min avant pour le temps de déplacement + les 15min après ton cours de 11h que tu as donné dans ta salle de sport.
    
    - La fiche client 
    Lorsque tu cliques sur « voir ses infos » dans la fiche du rendez-vous, apparaîtra la fiche du client. Tu y trouveras ses renseignements, le bouton pour contacter le client via la messagerie, un encadré pour que tu puisses noter les objectifs et un autre pour des commentaires, et pour finir l’historique des séances effectuées.
    
    - La reprogrammation d’un rendez-vous
    Pour reprogrammer un rendez-vous il te suffira de faire glisser le bloc sur l’agenda du rendez-vous concerné jusqu’à l’heure et la date souhaitée ou en cliquant sur le rendez-vous tu appuieras sur « Reprogrammer le rendez-vous » et tu devras modifier la date et l’heure.
    Le client recevra une notification.
    
    
    
    2) La messagerie
    Lorsqu’un nouveau client réserve son 1er rendez-vous avec toi, après la validation du rendez-vous, tu pourras contacter le client afin d’établir un premier contact et ensuite convenir du lieu de rendez-vous, du matériel nécessaire, définir les objectifs et les différents exercices à faire lors de la séance etc...  
    Pour les entreprises ou les salles de sport c’est un peu différent, il y aura peu d’échanges mis à part pour s’assurer que l’entreprise à bien le matériel nécessaire pour le cours, comment y accéder etc... 
    
    Attention : il est strictement interdit de communiquer votre numéro de téléphone, adresse mail ou encore des liens vers des réseaux sociaux ou autres sites. La transmission des coordonnées entrainera une désactivation de votre compte.

    1) La création BootCamp (pas utile d’expliquer)
    2) Les statistiques (pas utile d’expliquer)
    3) Les paiements (pas utile d’expliquer)
    `;    

export const article5 = 
    `Après avoir pris connaissance des sujets précédents, place aux fondamentaux, les bases de ton quotidien, la COACH’S LIFE quoi !
    Ces trois sujets te permettront de vivre la meilleure expérience possible avec nous ☺ !
    
    1) La relation client
    
    Nous n’allons pas t’apprendre qu’entretenir une bonne relation avec le client est 	primordial. Mais quelques conseils sont toujours bon à prendre !
    
    - La ponctualité, les conseils, le suivi envers ton client c’est la base, tu le sais, pas besoin d’en parler ;)!
    - Reprogrammation/Annulation rendez-vous : Il peut arriver d’avoir un imprévu de dernière minute, que ce soit pour toi ou pour le client. 
    <i>Il faut savoir que le client peut annuler 24h avant un rendez-vous sans frais.</i>
    Grâce à la messagerie, entre toi et le client, vous allez pouvoir échanger si un imprévu arrive, afin de trouver une solution. 
    Comment gérer un imprévu ?
    Nous te donnons quelques conseils pour gérer en fonction de chaque cas.
    
    Si le client souhaite reprogrammer 24h avant son rendez-vous, il est dans son droit et tu dois l’accepter et échanger avec lui afin de fixer un nouveau rendez-vous, sinon il va l’annuler gratuitement et tu risques de perdre un client...
    
    Imprévu de dernière minute du client (moins de 24h avant le rdv): 
    Normalement il t’aura contacter pour te faire part de son imprévu, à toi de prendre une décision d’accepter ou pas ! Mais l’idéal serait d’accepter et de reprogrammer son rendez-vous... 
    Par contre s’il ne te préviens pas et qu’il n’est pas présent au rendez-vous il ne sera pas remboursé et tu seras payé ! 
    
    Tu as un imprévu de dernière minute ! Comment faire ?
    <u>Client particulier</u> : prendre contact avec le client, tu lui expliques la situation et tu essaies de reprogrammer le rendez-vous. 
    <u>Client entreprise ou salle de sport</u> : Tu peux évidemment lui proposer de reprogrammer le rendez-vous et l’orienter à trouver un autre coach disponible, mais pour le coup le client devra repayer un rendez-vous avec le coach remplaçant et c’est pas sûr qu’il accepte. 
    <b>Attention : Si tu as un rendez-vous avec une entreprise ou une salle de sport et que ton imprévu à lieu quelques heures avant ou dans l’heure qui précède le rendez-vous contacte-nous directement via la messagerie afin qu’on puisse trouver un remplaçant.</b>
    
    N’oublie pas les clients vont donner leurs avis après chaque rendez-vous avec toi, ces avis permettent une meilleure visibilité de ton profil.
    
    2) La visibilité de ton profil
    
    Les profils sont affichés par ordre en fonction de deux facteurs qui sont :
    
    Le premier : c’est la note que tu obtiens sur tes avis. 
    
    Plus ta note se rapproche de 5 étoiles, plus tu es en tête de liste dans les résultats de recherche. 
    Les avis avec 5 étoiles rassurent les clients et les encouragent à prendre rendez-vous avec toi.
    
    Le deuxième : être nommé <b>TOP COACHS</b>. 
    
    NB : Un coach agréé SAP (Service à la personne) permet au client de bénéficier 50% de réduction d’impôts sur le montant de leurs dépenses liées aux séances à domicile. 
    Cela rend plus attractif pour un client de prendre rendez-vous avec un coach SAP
    
    Un badge « SAP -50% » visible par le client, sera attribué aux profils des coachs agréés SAP, et un filtre SAP dans la fonction recherche.
    
    Si tu n’es pas agréé SAP clique <a href=link0>ici</a> pour plus d’informations.
    
    3) Le programme <b>TOP COACHS</b>
    
    C’est quoi ? <b><span class="red_text">C’est notre façon de récompenser tes efforts à rediriger sur la plateforme, un nouveau client que tu as obtenu grâce à COACHS pour ses futurs rendez-vous !</span></b> 
    
    Le badge TOP COACHS est attribué aux Coachs ayant eu le plus de rendez-vous sur une période de 3 mois. 
    Filtrer par région, nous sélectionnons les 50 premiers de chaque. <b><span class="red_text">(info pour le dév : ajouter champs région profil coach)</span></b>
    Dans les 15 jours suivants chaque fin de trimestre, tu recevras le résultat du classement par e-mail. 
    (Uniquement les numéros des coachs et le nombres de séances effectuées apparaitront) 
    
    Il y aura aussi une sélection des 10 meilleurs TOP COACHS de l’année sur toute la France.
    Le résultat du classement sera communiqué dans les 15 premier jours N+1
    
    Quels sont les récompenses pour les TOP COACHS ?
    
    Les récompenses trimestrielles :
    <ul>
        <li>Un badge Top Coachs sur ton profil pour te mettre en avant</li>
        <li>Un filtre Top Coachs pour permettre aux clients de te trouver plus facilement</li>
        <li>Des avantages supplémentaires avec nos partenaires.</li>
        <li>Une commission de 10% (au lieu de 20%) sur les cours collectifs en entreprise (pendant 1mois)</li>
    </ul>
    Les récompenses annuelles
    <ul>
        <li>Un voyage d’une semaine pour chaque gagnant. (destination à définir)</li>
    </ul>
    
    <i>Toutes les récompenses sont accordées à la discrétion de COACHS ; elles peuvent être modifiées ou supprimées à tout moment. COACHS pourra à tout moment changer les termes / conditions ou mettre fin au programme TOP COACHS.</i>
`;