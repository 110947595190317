import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { LoadingController } from '@ionic/angular';
import { FormStatusBis } from 'src/app/models/form-status-bis';
import { CompanyService } from 'src/app/services/api/company.service';
import { FormStorageService } from 'src/app/services/form-storage.service';
import { SecurityService } from 'src/app/services/security.service';
import { ModalRegisteredComponent } from '../modal/modal-registered/modal-registered.component';
import { ModalMentionsLegalesComponent } from '../modal/modal-mentions-legales/modal-mentions-legales.component';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/services/api/user.service';
import { ProfileService } from 'src/app/services/api/profile.service';
import { CoachService } from 'src/app/services/api/coach.service';
import { CardService } from 'src/app/services/api/card.service';
import { Observable } from 'rxjs';
import { IbanService } from 'src/app/services/api/iban.service';



@Component({
  selector: 'app-status-bis',
  templateUrl: './status-bis.component.html',
  styleUrls: ['./status-bis.component.scss'],
})
export class StatusBisComponent implements OnInit {
  // Files variables
  KBISfile: File | null = null;

  public form: FormGroup;
  private loader: HTMLIonLoadingElement;

  formPreview$: FormStatusBis;

  // User infos
  coachInfos;
  userInfos;
  companyInfos;

  //
  validationRes

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private security: SecurityService,
    private loadingController: LoadingController,
    private companyService: CompanyService,
    private location: Location,
    public dialog: MatDialog,
    private formStorageService: FormStorageService,
    private userService: UserService,
    private coachService: CoachService,
    private cardService: CardService,
    private ibanService: IbanService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      registrationNumber: ['', [Validators.required, Validators.minLength(9)]],
      type: ['', [Validators.required]],
      name: ['', [Validators.required]],
      KBIS: ['', [Validators.required]],
      // IBAN: ['', [Validators.required]],
      // BIC: ['', [Validators.required]],
    });

    /*** Form values storage when Form is not submitted yet ***/
    // Get the last form values save as an observable
    this.formStorageService.currentFormTwoBis.subscribe(formTwoBis => this.formPreview$ = formTwoBis)

    // Save the last values when user fill form inputs
    this.form.valueChanges.subscribe(value => {
      this.formPreview$ = value
      this.formStorageService.setFormTwoBis(value)
    })

    // Replace value of the inputs if no key CompanyUuid in the local storage
    if (this.formPreview$) {
      this.form.patchValue({
        type: this.formPreview$.type,
        name: this.formPreview$.name,
        registrationNumber: this.formPreview$.registrationNumber
        // KBIS: this.formPreview$.KBIS,
        // IBAN: this.formPreview$.IBAN,
        // BIC: this.formPreview$.BIC
      })
    }

    // Get user infos    
    this.userService.getCurrent().subscribe(res => {
      this.userInfos = res
      console.log('user')
      console.log(this.userInfos)
      this.coachService.get(this.userInfos.profile.coach.uuid).subscribe(res => {
        this.coachInfos = res
        console.log('coach')
        console.log(this.coachInfos)
        this.companyService.get(this.coachInfos.companies[0].uuid).subscribe(res => {
          this.companyInfos = res
          console.log('company')
          console.log(this.companyInfos)
        })
      })
    })
  }

  onFileKBISSelected(event) {
    this.KBISfile = <File>event.target.files[0];
    console.log(this.KBISfile)
  }

  openDialog() {
    if (this.form.valid) {
      const dialogRef = this.dialog.open(ModalRegisteredComponent, {
        width: '550px',
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result === 'true') {
          this.onSubmit();
          // this.router.navigate(['coach-inscription-done'])
        }
      });
    }
  }


  async onSubmit() {
    const formData = new FormData();
    formData.append('name', this.form.value.name)
    formData.append('type', this.form.value.type)
    formData.append('isSAPCertified', '0')
    formData.append('SAPNumber', '')
    formData.append('SAPCertificationObtentionDate', '')
    formData.append('registrationNumber', this.form.value.registrationNumber)
    formData.append('BIC', this.companyInfos.BIC)
    formData.append('KBIS[file]', this.KBISfile)
    formData.append('IBAN', this.companyInfos.IBAN)
    formData.append('coach', this.coachInfos.uuid)


    let func = null;
    this.loader = await this.loadingController.create({ message: 'Enregistrement des données' })
    await this.loader.present();

    func = this.companyService.post(formData).subscribe(
      res => {
        console.log(res)
        // localStorage.removeItem('jwt_token')
        localStorage.removeItem('profileUuid')
        localStorage.removeItem('coachUuid')
        localStorage.removeItem('companyUuid')
        localStorage.removeItem('userUuid')
        //-----------------------------------------------------------------------------------------------------------------------------------
        const that = this;
        this.handleForm();
        //-----------------------------------------------------------------------------------------------------------------------------------

      }
    )
  }

  openLegal() {
    const dialogRef = this.dialog.open(ModalMentionsLegalesComponent, {
      width: '550px',
      height: '80vh',
      autoFocus: false
    });
  }

  async handleForm() {

    const stripe = await loadStripe(environment.stripe.key);

    console.log('ACCOUNT RESULT')
    const accountResult = await stripe.createToken('account', {
      business_type: 'company',
      company: {
        'name': this.coachInfos.firstName + ' ' + this.coachInfos.lastName,
        'phone': '+33' + this.coachInfos.phone.slice(1),
        'directors_provided': true,
        'executives_provided': true,
        'owners_provided': true,
        "tax_id": "000000000",
        "address": {
          "city": this.coachInfos.profile.address.city,
          "country": "FR",
          "postal_code": this.coachInfos.profile.address.postalCode.toString(),
          "line1": this.coachInfos.profile.address.address
        },
      },
      tos_shown_and_accepted: true,
    });

    console.log("PERSON RESULT")
    const personResult = await stripe.createToken('person', {
      'email': this.userInfos.email,
      'phone': '+33' + this.coachInfos.phone.slice(1),
      "first_name": this.coachInfos.firstName,
      "last_name": this.coachInfos.lastName,
      "address": {
        "city": this.coachInfos.profile.address.city,
        "country": "FR",
        "postal_code": this.coachInfos.profile.address.postalCode.toString(),
        "line1": this.coachInfos.profile.address.address
      },
      "dob": {
        "day": parseInt(this.coachInfos.birthDate.slice(8, 10)),
        "month": parseInt(this.coachInfos.birthDate.slice(5, 7)),
        "year": parseInt(this.coachInfos.birthDate.slice(0, 4)),
      },
      "relationship": {
        "director": true,
        "executive": true,
        "owner": true,
        "representative": true,
        "title": "CEO"
      }
    });
    console.log(accountResult)
    console.log(personResult)
    if (accountResult.token && personResult.token) {
      const body = {
        user: this.userInfos.uuid,
        token_account: accountResult.token.id,
        token_person: personResult.token.id
      }
      console.log("body")
      console.log(body)

      this.cardService.createStripeAccount(body).subscribe(
        res => {
          console.log(res)
          this.verifUser2(res)
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  // async verifUser(event):Promise<any> {

  //   const stripe = await loadStripe(environment.stripe.key);
  //   // Call your backend to create the VerificationSession
  //   /*fetch('/admin/verification-user', {
  //       method: 'POST',
  //   })*/
  //   of(this.cardService.validation().pipe(
  //     map(
  //       res => {
  //         this.validationRes = res
  //         console.log(res)

  //       },
  //       err => {
  //         console.log(err)
  //       }
  //     )).toPromise()
  //     .then(result => {
  //       return result
  //     })
  //     .then (function (verificationSession) {
  //         return stripe.verifyIdentity(verificationSession.client_secret);
  //     })
  //     // .then(function (result) {
  //     //     // If `verifyIdentity` fails due to a browser or network
  //     //     // error, you should display the localized error message to your
  //     //     // customer using `error.message`.
  //     //     if (result.error) {
  //     //         alert(result.error.message);
  //     //     }
  //     // }
  //     );
  //   // )
  // }

  async verifUser2(event): Promise<any> {
    const stripe = await loadStripe(environment.stripe.key);

    const body = {
      refresh_url: "https://dev.coachs-france.com/",
      return_url: "https://dev.coachs-france.com/coach-inscription-done"
    }

    this.cardService.validation(body).subscribe(
      res => {
        console.log(res)
        let url = res.toString()
        let uri = encodeURI(url)


        this.coachService.get(this.coachInfos.uuid).subscribe(res => {
          this.coachInfos = res
          console.log(this.coachInfos)
          console.log(res)
          const bodyIban = {
            iban: 'FR1420041010050500013M02606',
            // TODO : décommenter en bas quand on passe en prod, en haut c'est le IBAN test pour Stripe
            // iban: this.companyInfos.IBAN,
            currency: "eur",
            titulaire: this.companyInfos.name,
            address: {
              city: this.userInfos.profile.address.city,
              address: this.userInfos.profile.address.address,
              postalCode: this.userInfos.profile.address.postalCode,
              country: this.userInfos.profile.address.country,
              region: this.userInfos.profile.address.region
            }
          }
          console.log(bodyIban)
          this.ibanService.createNewIban(bodyIban).subscribe(res => {
            console.log(res)
            this.loader.dismiss()
            window.location.assign(uri)
          })
        })

        // // stripe.verifyIdentity(res['_values'].client_secret)
        // .then(function (result) {
        //   // If `verifyIdentity` fails due to a browser or network
        //   // error, you should display the localized error message to your
        //   // customer using `error.message`.
        //   if (result.error) {
        //       alert(result.error.message);
        //   }
        // }
        // );
      },
      err => {
        console.log(err)
      }
    )
  }

  onPrevious(): void {
    this.location.back();
  }

}
