import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Bootcamp } from 'src/app/models/bootcamp';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class BootcampService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  post(body): Observable<Bootcamp> {
    return this.http.post<Bootcamp>(this.api.getUrl('/api/bootcamp'), body, this.api.getOptions());
  }
  
  get(uuid: string): Observable<Bootcamp> {
    return this.http.get<Bootcamp>(this.api.getUrl(`/api/bootcamp/${uuid}`), this.api.getOptions());
  }
  
  getAll(): Observable<Bootcamp> {
    return this.http.get<Bootcamp>(this.api.getUrl(`/api/bootcamp`), this.api.getOptions());
  }
  
  patch(body, uuid): Observable<Bootcamp> {
    return this.http.post<Bootcamp>(this.api.getUrl(`/api/bootcamp/${uuid}/edit`), body, this.api.getOptions());
  }
  
  delete(uuid: string): Observable<Bootcamp> {
    return this.http.delete<Bootcamp>(this.api.getUrl(`/api/bootcamp/${uuid}`), this.api.getOptions());
  }

}
