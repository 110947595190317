import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IonInfiniteScroll } from '@ionic/angular';
import { timer } from 'rxjs';
import { ClientService } from 'src/app/services/api/client.service';
import { GlobalGettersService } from 'src/app/services/api/global-getters.service';
import { MessageService } from 'src/app/services/api/message.service';
import { UserService } from 'src/app/services/api/user.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { DialogData } from '../messagerie/conversations/conversations.component';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit {

  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;

  conversations = [];
  possibleConversations = []

  pages;
  actualPages;

  morePages = 1;

  admin;

  //
  timerSubsciption;

  //
  notificationsOnInit
  nbrOfNotifications

  //
  userInfos

  //
  loaded;

  constructor(
    private messageService: MessageService,
    private router: Router,
    private clientService: ClientService,
    public dialog: MatDialog,
    private globalGetters: GlobalGettersService,
    private notificationsService: NotificationsService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.notificationsService.currentNotifications.subscribe(res => this.nbrOfNotifications = res)

    this.globalGetters.getNoticationSummary().subscribe(res => {
      this.notificationsOnInit = res["notifications"]
    })

    this.timerSubsciption = timer(5000, 5000).subscribe(() => {
      try {
        if (this.notificationsOnInit !== this.nbrOfNotifications) {
          this.notificationsOnInit = this.nbrOfNotifications
          this.reload()
        }
      }
      catch (e) {
        console.log(e)
      }
    });

    this.userService.getCurrent().subscribe(res => {
      this.userInfos = res
      this.reload()
    })
  }

  ngAfterViewInit() {
    const searchbar = document.querySelector('ion-searchbar');
    if (searchbar) {
      let items = Array.from(document.querySelector('#conversations')?.querySelectorAll('.conversations') || []);
      searchbar.addEventListener('ionInput', handleInput);
    }

    function handleInput(event) {
      const query = event.target.value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      requestAnimationFrame(() => {
        const items = Array.from(document.querySelector('#conversations')?.querySelectorAll('.conversations') || []);
        items.forEach((item) => {
          const shouldShow = item.querySelector('.names > div > .firstName').textContent.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").indexOf(query) > -1;
          (<HTMLElement>item).style.display = shouldShow ? 'flex' : 'none';
        });
      });
    }

  }

  ngOnDestroy() {
    this.timerSubsciption.unsubscribe();
  }

  //OUVRIR LE POPUP
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogNewConversationCoach, {
      width: '80vw',
      data: {
        conversations: this.possibleConversations
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.reload();
    });
  }

  reload() {
    this.getRooms(0);

    this.clientService.getAllRooms(this.userInfos.uuid).subscribe(
      res => {
        console.log('ROOMS: ');
        console.log(res);
        console.log(JSON.parse(JSON.stringify(res)).items);
        if (this.conversations && Array.isArray(this.conversations)) {
          this.possibleConversations = JSON.parse(JSON.stringify(res)).items
            .filter(el => !this.conversations
              .find(element => element.client.uuid == el.uuid));
        } else {
          // Handle the case when this.conversations is not defined or not an array
          this.possibleConversations = JSON.parse(JSON.stringify(res)).items;
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  goToMessages(conversation, isAdmin = false) {
    console.log(conversation)
    let nameClient
    if (!isAdmin) {
      nameClient = conversation.client.profile.client.firstName
    } else {
      nameClient = "Support Coachs"
    }
    this.router.navigate(['/coach/chat/message/' + conversation.uuid], { queryParams: { clientFirstName: nameClient } });
  }

  getRooms(i) {
    this.messageService.getAll(i + 1).subscribe(
      res => {
        console.log("ROOOMS")
        console.log(res)
        console.log(JSON.parse(JSON.stringify(res)).items)
        let conversationOfThePage = JSON.parse(JSON.stringify(res))

        if (i == 0) {
          this.conversations = [];
        }
        this.conversations = this.conversations.concat(conversationOfThePage.items)
          .map(v => ({ ...v, picture: v.client?.profile?.client?.gender === "female" ? "assets/icon/avatar femme.svg" : "assets/icon/avatar homme.svg" }));
        this.pages = conversationOfThePage.pageCount;
        this.actualPages = conversationOfThePage.currentPageNumber;

        console.log(this.conversations)

        for (let j = i * 20; j < i * 20 + conversationOfThePage.items.length; j++) {
          console.log('j', j)

          this.messageService.unreadMessages(conversationOfThePage.items[j - i * 20].uuid).subscribe(
            result => {
              console.log(this.conversations)
              console.log(this.conversations[j])
              this.conversations[j].newMessages = result
            },
            err => {
              console.log(err)
            }
          );

          // this.profileService.get(conversationOfThePage.items[j - i * 20].coach.profile?.uuid).subscribe(
          //   result => {
          //     console.log(result);
          //     if (result.picture != null) {
          //       this.conversations[j]["picture"] = result.picture.url;
          //     }
          //   },
          //   err => {
          //     console.log(err)
          //   }
          // )

          this.messageService.lastMessage(this.conversations[j].uuid).subscribe(res => {
            console.log(res)
            console.log(j)
            this.conversations[j]["lastMessage"] = res['message']['message']
            this.conversations[j]["createdAt"] = res['message']['createdAt']
            // Sort conversations from newest to old update
            this.conversations.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

            console.log(this.conversations[j])
          }, err => {
            console.log(j)
            console.log(err)
          })
        }

        if (this.actualPages < this.pages) {
          this.getRooms(i + 1)
        } else {
          console.log(this.conversations)
          this.loaded = true;
          // find role Admin
          this.conversations.map(item => {
            let roles = item.coach.roles
            console.log(roles)
            roles.forEach(el => {
              console.log(el)
              if (el === "ROLE_ADMIN") {
                this.admin = item
                return
              }
            });
          })
          console.log(this.admin)
          // remove it from the conversation array
          // this.conversations = this.conversations.filter(item => item !== this.admin)
          // console.log(this.conversations)

        }
      },
      err => {
        console.log(err)
      }
    )
  }

  checkRoleAmdin() {
    console.log(this.admin)
  }

}

@Component({
  selector: 'dialog-new-conversation-coach',
  templateUrl: 'dialog-new-conversation-coach.html',
  styleUrls: ['./chat.component.scss'],
})
export class DialogNewConversationCoach {
  userInfos;

  constructor(
    public dialogRef: MatDialogRef<DialogNewConversationCoach>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private messageService: MessageService,
    private userService: UserService
  ) { }


  onNoClick(): void {
    this.dialogRef.close();
  }

  createRoom(uuid) {
    this.userService.getCurrent().subscribe(res => {
      this.userInfos = res
      console.log(this.userInfos)
      const body = {
        coach: this.userInfos.uuid,
        client: uuid
      }

      this.messageService.createRoom(body).subscribe(
        res => {
          console.log('Room created : ')
          console.log(res);
          this.dialogRef.close();
        },
        err => {
          console.log(err)
        }
      )
    })

  }
}
