import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-arguments-home-user',
  templateUrl: './arguments-home-user.component.html',
  styleUrls: ['./arguments-home-user.component.scss'],
  styles: [
    `
      .bg-yellow {
        background-color: yellow;
      }
      .transition {
        transition: background 0.25s ease, color 0.25s ease;
      }
      .active-slide {
        background-color: green;
        color: #fff;
      }
      .bg-blue {
        background-color: blue;
        color: #fff;
      }
    `
  ],
})


export class ArgumentsHomeUserComponent implements OnInit {


images = [
  './../../../../assets/img/carousel1.jpg',
  './../../../../assets/img/carousel2.jpg',
  './../../../../assets/img/carousel3.jpg',
  './../../../../assets/icon/favoris.svg',
  './../../../../assets/icon/info.svg',
  
  './../../../../assets/icon/accessibilité.svg',
];
texts = [
  'CLASSIC',
  'SPÉCIFIQUE',
  'BOOTCAMP',
  'TEXT 4',
  'TEXT 5',
  'TEXT 6',
];
prices = [
  'À partir de 19,99€',
  'À partir de 19,99€',
  '2,99€ / Participant',
  'TEXT 4',
  'TEXT 5',
  'TEXT 6',
];
commentaires = [
  'En solo ou à plusieurs',
  'En solo ou à plusieurs',
  '',
  'TEXT 4',
  'TEXT 5',
  'TEXT 6',
]

displayed_img = [
  this.images[0], 
  this.images[1], 
  this.images[2]
]
displayed_text = [
  this.texts[0], 
  this.texts[1], 
  this.texts[2]
]
displayed_prices = [
  this.prices[0], 
  this.prices[1], 
  this.prices[2]
]
displayed_com = [
  this.commentaires[0], 
  this.commentaires[1], 
  this.commentaires[2]
]
//@Input() timing = '250ms ease-in';

switch(x) {
  x = parseInt(x)
  const n = this.texts.length;
  var currentImage;
  for (var i = 0; i < 3; i++) {
    if (this.texts.indexOf(this.displayed_text[i]) + x >= n) {
      currentImage = this.texts.indexOf(this.displayed_text[i]) + x - n;
      /*this.displayed_img[i] = this.images[0]
      this.displayed_text[i] = this.texts[0]
      this.displayed_prices[i] = this.prices[0]
      this.displayed_com[i] = this.commentaires[0]*/
    }
    else if (this.texts.indexOf(this.displayed_text[i]) + x < 0) {
      currentImage = n - this.texts.indexOf(this.displayed_text[i]) + x;
     /* this.displayed_img[i] = this.images[n - 1]
      this.displayed_text[i] = this.texts[n - 1]
      this.displayed_prices[i] = this.prices[n - 1]
      this.displayed_com[i] = this.commentaires[n - 1]*/
    }
    else {
      currentImage = this.texts.indexOf(this.displayed_text[i]) + x;
      /*this.displayed_img[i] = this.images[this.images.indexOf(this.displayed_img[i]) + x];
      this.displayed_text[i] = this.texts[this.texts.indexOf(this.displayed_text[i]) + x]
      this.displayed_prices[i] = this.prices[this.prices.indexOf(this.displayed_prices[i]) + x]
      this.displayed_com[i] = this.commentaires[this.commentaires.indexOf(this.displayed_com[i]) + x]*/
    }
    this.displayed_img[i] = this.images[currentImage]
    this.displayed_text[i] = this.texts[currentImage]
    this.displayed_prices[i] = this.prices[currentImage]
    this.displayed_com[i] = this.commentaires[currentImage]
  }
  
}

switchTo(x) {
  //console.log(this.images.indexOf(x))
  x = this.texts.indexOf(x)
  const n = this.texts.length;
  var currentImage;
  for (var i = 0; i < 3; i++) {
    console.log(x + i - 1)
    if (x + i - 1 >= n) {
      console.log('if : ' + (x + i - 1))
      currentImage = x + i - 1 - n;
    }
    else if (x+ i - 1 < 0) {
      
      console.log('elsif : ' + (x + i - 1))
      currentImage = n + (x + i - 1);
    }
    else {
      console.log('else' + (x + i - 1))
      currentImage = x + i - 1;
    }
    console.log(currentImage)
    this.displayed_img[i] = this.images[currentImage]
    this.displayed_text[i] = this.texts[currentImage]
    this.displayed_prices[i] = this.prices[currentImage]
    this.displayed_com[i] = this.commentaires[currentImage]
    //currentImage = this.images.indexOf(this.displayed_img[i]) + x - n;
  }
  //this.switch(x)
}

constructor() {
}

  ngOnInit() {}

}
