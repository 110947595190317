import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CoachService } from 'src/app/services/api/coach.service';
import { GlobalGettersService } from 'src/app/services/api/global-getters.service';
import { ProfileService } from 'src/app/services/api/profile.service';
import { SportService } from 'src/app/services/api/sport.service';
import { TestimonyService } from 'src/app/services/api/testimony.service';
import { UserService } from 'src/app/services/api/user.service';
import { SecurityService } from 'src/app/services/security.service';
import { ProfilePictureService } from 'src/app/services/shared-data/profile-picture.service';
import * as moment from 'moment';


@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  isSportModalOpen = false;
  isLessonModalOpen = false;

  // Page Title
  parentMessage = "Profil";

  url: any;
  message: string;
  imagePath: string;

  // Infos Coach
  profilPicture;
  name;
  sexe;
  age;
  city;
  description;
  presentation;

  // Testimony
  testimonies: any = [];
  stars: number;
  voters: number;

  // Sports
  sports = [];
  allSports = [];

  // Categories
  categories;

  // Courses
  courses = [];

  // Form
  public form: FormGroup;
  public formCoach: FormGroup;

  salles = [];

  checked = Array.from(Array(this.salles.length).keys());
  week;

  lieux = [
    { name: 'En extérieur', field: 'exterieur' },
    { name: 'À domicile', field: 'domicile' },
    { name: 'En salle', field: 'salle' }
  ]

  dropdownSettings = {
    idField: 'uuid',
    textField: 'name',
    noDataAvailablePlaceholderText: 'Sélectionner une catégorie'
  };

  displayPopup = 0;

  //
  picture;
  profilPictureUuid;
  profilPicExist: boolean;

  //
  waitToAdd = false;
  nbComments = 1;
  avis = [];
  patchedCourse;

  //
  radius: number;
  zoom: number;
  lat: number;
  lng: number;

  // User Infos
  userInfos;
  coachInfos;
  coachUuid;

  // Report button
  report(uuid: string) {
    this.testimonyService.report(uuid).subscribe(
      res => {
        console.log(res)
      },
      err => {
        console.log(err)
      }
    )
  }

  // 
  sportCategoryNotSelected: boolean = true;
  sportOfTheChoosenCategory = [];


  constructor(
    private route: ActivatedRoute,
    private testimonyService: TestimonyService,
    private globalGettersService: GlobalGettersService,
    private userService: UserService,
    private coachService: CoachService,
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private sportService: SportService,
    private profilePictureService: ProfilePictureService,
    private cd: ChangeDetectorRef,
    private router: Router
  ) {
    this.lat = null;
    this.lng = null;
  }

  ngOnInit() {
    //Form
    this.formCoach = this.formBuilder.group({
      description: [null],
      presentation: [null]
    })

    this.userService.getCurrent().subscribe(res => {
      console.log(res)
      this.userInfos = res
      this.coachUuid = this.userInfos.profile.coach.uuid

      //Profil Pic
      if (res.profile.picture) {
        this.profilPicture = res.profile.picture.url
        this.profilPictureUuid = res.profile.picture.uuid
        this.profilPicExist = true
      } else {
        let gender = res.profile.coach.gender
        if (gender === 'female') {
          this.profilPicture = 'assets/icon/avatar-female.svg'
        } else {
          this.profilPicture = 'assets/icon/avatar-male.svg'
        }
      }

      // Get coach informations
      this.coachService.get(this.userInfos.profile.coach.uuid).subscribe(res => {
        console.log(res)
        this.coachInfos = res;
        const information = JSON.parse(JSON.stringify(res))
        this.name = information.lastName
        this.sexe = information.gender
        // this.img = `/assets/icon/avatar-${information.gender}.svg`
        this.age = Math.floor((new Date().getTime() - new Date(information.birthDate).getTime()) / (365 * 24 * 60 * 60 * 1000))
        this.city = information.profile.address.city

        this.description = information.description;
        this.presentation = information.presentation;

        console.log("latitude:");
        this.lat = information.profile.address.latitude;
        console.log(this.lat);
        this.radius = information.activityRadius;
        this.zoom = ((1 - (information.activityRadius / 50000)) * 2.8 + 7.5);
        console.log("longitude:")
        this.lng = information.profile.address.longitude;
        console.log(this.lng);
        if (information.profile.address.latitude == null || information.profile.address.latitude == null) {
          console.log("NULL");
          //(<HTMLInputElement>document.getElementById("maps")).src = "https://www.google.com/maps/embed/v1/place?key=AIzaSyAn" +
          //"A7JB9tiXqVnTbVp6uWjEqLHqzG_WJ1M&q=14.8397617740313,42.368543445986691";
        }
        else {
          //(<HTMLInputElement>document.getElementById("maps")).src = "https://www.google.com/maps/embed/v1/place?key=AIzaSyAn" +
          //"A7JB9tiXqVnTbVp6uWjEqLHqzG_WJ1M&q=" + information.profile.address.latitude + "," + information.profile.address.latitude;
        }

        this.formCoach.patchValue({
          presentation: this.coachInfos.presentation,
          description: this.coachInfos.description
        })
      })



      // Get all sports from the coach
      this.globalGettersService.getSportCoach(this.userInfos.profile.coach.uuid).subscribe(
        res => {
          // console.log('1' + JSON.parse(JSON.stringify(res)));
          console.log(res);
          //PRENDRE SPORTS
        },
        err => {
          console.log(err);
        }
      );

      //
      this.globalGettersService.getSportCoach(this.userInfos.profile.coach.uuid).subscribe(
        res => {
          this.sports = JSON.parse(JSON.stringify(res)).items
          console.log(this.sports)
        },
        err => {
          console.log(err)
        }
      )

      // Get all the testimonies from the coach
      this.testimonyService.getCoach(this.userInfos.profile.coach.uuid).subscribe(res => {
        console.log(res)
        this.testimonies = JSON.parse(JSON.stringify(res)).items
        console.log(this.testimonies)
        this.stars = Math.round(JSON.parse(JSON.stringify(res)).items.reduce((accumulator, curr) => accumulator + curr.note, 0) / JSON.parse(JSON.stringify(res)).items.length);
        console.log(this.stars)
        this.voters = JSON.parse(JSON.stringify(res)).totalItemCount;
      })
    })

    this.form = this.formBuilder.group({
      type: ['', [Validators.required]],
      typeToAdd: ['', [Validators.required]],
      sportsToAdd: [, [Validators.required]],
      sports: [[], [Validators.required]],
      lieux: this.formBuilder.group({
        exterieur: [''],
        domicile: [''],
        salle: ['']
      }),
      timeBlockedAfter: [0, [Validators.required]],
      salles: [[], [Validators.required]],
      gyms: this.formBuilder.array(Array(this.salles.length).fill(
        this.formBuilder.group({
          gym: [false, [Validators.required]]
        })
      )
      ),

      monday: this.formBuilder.group({
        checked: [''],
        begin: [''],
        end: ['']
      }),
      tuesday: this.formBuilder.group({
        checked: [''],
        begin: [''],
        end: ['']
      }),
      wednesday: this.formBuilder.group({
        checked: [''],
        begin: [''],
        end: ['']
      }),
      thursday: this.formBuilder.group({
        checked: [''],
        begin: [''],
        end: ['']
      }),
      friday: this.formBuilder.group({
        checked: [''],
        begin: [''],
        end: ['']
      }),
      saturday: this.formBuilder.group({
        checked: [''],
        begin: [''],
        end: ['']
      }),
      sunday: this.formBuilder.group({
        checked: [''],
        begin: [''],
        end: ['']
      })
    })

    this.form.patchValue({
      timeBlockedAfter: this.form.value.timeBlockedAfter
    })

    this.week = [
      { day: 'Lundi', fieldChecked: 'monday', fieldBegin: 'monday.begin', fieldEnd: 'monday.end' },
      { day: 'Mardi', fieldChecked: 'tuesday', fieldBegin: 'tuesday.begin', fieldEnd: 'tuesday.end' },
      { day: 'Mercredi', fieldChecked: 'wednesday', fieldBegin: 'wednesday.begin', fieldEnd: 'wednesday.end' },
      { day: 'Jeudi', fieldChecked: 'thursday', fieldBegin: 'thursday.begin', fieldEnd: 'thursday.end' },
      { day: 'Vendredi', fieldChecked: 'friday', fieldBegin: 'friday.begin', fieldEnd: 'friday.end' },
      { day: 'Samedi', fieldChecked: 'saturday', fieldBegin: 'saturday.begin', fieldEnd: 'saturday.end' },
      { day: 'Dimanche', fieldChecked: 'sunday', fieldBegin: 'sunday.begin', fieldEnd: 'sunday.end' },
    ]

    // MES SPORTS
    this.globalGettersService.getCategories().subscribe(
      res => {
        console.log("categories : ")
        this.categories = JSON.parse(JSON.stringify(res)).items;
        console.log(JSON.parse(JSON.stringify(res)).items)

        if (JSON.parse(JSON.stringify(res)).items[0] != undefined) {
          console.log(true)
          // this.form.value.type = JSON.parse(JSON.stringify(res)).items[0].uuid
        }
      },
      err => {
        console.log(err)
      }
    )

    //
    for (let course = 0; course < this.courses.length; course++) {
      for (let comparedCourse = 0; comparedCourse < this.courses.length; comparedCourse++) {
        if (this.courses[course][1].toString() == this.courses[comparedCourse][1].toString()
          && this.courses[course][2].toString() == this.courses[comparedCourse][2].toString()
          && this.courses[course][0] != this.courses[comparedCourse][0]) {
          this.courses[course][0].push(this.courses[comparedCourse][0][0])
          this.courses.splice(comparedCourse, 1)
        }
      }
    }

    const range = document.querySelector('ion-range');
    console.log("RANGE", range);

    if (range) {
      range.addEventListener('ionChange', (detail) => {
        console.log("CHANGE")
        this.radius = (<CustomEvent>detail).detail.value;
        if (this.radius < 1000) {
          this.zoom = (1 - (this.radius / 50000)) + (1 - this.radius / 1000) * 15;
        }
        else {
          this.zoom = ((1 - (this.radius / 50000)) * 2.8 + 7.5);
        }
      });
    }

    this.sportService.get().subscribe(
      res => {
        this.allSports = JSON.parse(JSON.stringify(res)).items
        console.log(this.allSports)
      },
      err => {
        console.log(err)
      }
    );
  }

  moreComments() {
    this.testimonyService.getCoach(this.userInfos.profile.coach.uuid).subscribe(
      res => {
        if (JSON.parse(JSON.stringify(res)).items.length > this.nbComments) {
          this.nbComments += 5
        }
        console.log(JSON.parse(JSON.stringify(res)).items);
        this.avis = JSON.parse(JSON.stringify(res)).items.slice(0, this.nbComments + 5);
      },
      err => {
        console.log(err)
      }
    )
  }

  getSports() {
    let finalArray = []
    for (let sport of this.sports) {
      if (this.form.value.type == sport.sportCategory.uuid) {
        finalArray.push({
          name: sport.sport.name,
          uuid: sport.sport.uuid
        })
      }
    }
    return finalArray;
  }

  getSportsCat(uuid) {
    let finalArray = []
    for (let sport of this.sports) {
      if (sport.sportCategory.uuid == uuid) {
        finalArray.push({
          name: sport.sport.name,
          uuid: sport.sport.uuid
        })
      }
    }
    // console.log(finalArray)
    return finalArray;
  }

  // Change Profil Pic
  onFileChanged(event) {
    const files = event.target.files;
    if (files.length === 0)
      return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Seul les fichiers images sont acceptés';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.profilPicture = reader.result;
    };

    this.picture = <File>event.target.files[0];
    const formData = new FormData()
    formData.append('file', this.picture)

    if (this.profilPicExist) {
      console.log('put pic')
      this.profileService.putProfilePicture(this.profilPictureUuid, formData).subscribe(res => {
        console.log(res)
        this.profilePictureService.setProfilePicture(this.profilPicture)
      })
    } else {
      console.log('post pic')
      this.profileService.postProfilePicture(formData).subscribe(res => {
        console.log(res)
        this.profilePictureService.setProfilePicture(this.profilPicture)
      })
    }
  }

  // @Output() profilPictureEvent = new EventEmitter<string>();

  // sendProfilPicToDashboard() {
  //   this.profilPictureEvent.emit(this.profilPicture)
  // }


  getSportsToAdd(categoryUuid: string) {
    console.log(categoryUuid)
    console.log('All Sports', this.allSports)
    let finalArray = []
    for (let sport of this.allSports) {
      for (let cat of sport.categories) {
        if (categoryUuid == cat.uuid) {
          finalArray.push({
            name: sport.name,
            uuid: {
              uuid: sport.uuid,
              category: cat.uuid
            }
          })
        }
      }
    }
    console.log(finalArray)

    console.log(this.coachInfos.coachSports)

    let resultArray = finalArray.filter(obj1 => {
      return !this.coachInfos.coachSports.some(obj2 => {
        return obj1.uuid.uuid === obj2.sport.uuid && obj1.uuid.category === obj2.sportCategory.uuid;
      });
    });


    console.log(resultArray);

    console.log('Categories', this.categories)
    // return finalArray;
    this.sportOfTheChoosenCategory = resultArray

    console.log(this.coachInfos.coachSports)
    this.cd.detectChanges();
  }

  onItemSelect(e: any) {
    console.log(e);
  }

  addSport() {
    console.log(this.form.value.sportsToAdd)
    console.log(this.sports)
    if (!this.waitToAdd) {
      this.waitToAdd = true;
      for (let sport of this.form.value.sportsToAdd) {
        if (!this.sports.find(element => element.sport.uuid === sport.uuid.uuid && element.sportCategory.uuid === sport.uuid.category)) {
          console.log(this.coachInfos)
          const body = {
            coach: this.coachInfos.uuid,
            sport: sport.uuid.uuid,
            sportCategory: sport.uuid.category
          }
          console.log(body)
          this.coachService.addSport(body).subscribe(
            res => {
              console.log(res);
              this.form.reset();
              this.isSportModalOpen = false;
              this.globalGettersService.getSportCoach(this.coachInfos.uuid).subscribe(
                res => {
                  this.sports = JSON.parse(JSON.stringify(res)).items
                  if (sport == this.form.value.sportsToAdd[this.form.value.sportsToAdd.length - 1]) {
                    this.waitToAdd = false;
                  }
                },
                err => {
                  console.log(err)
                  this.waitToAdd = false;
                }
              )
            },
            err => {
              console.log(err)
              this.waitToAdd = false;
            }
          );
        }
        else {
          console.log("nope")
        }
      }
    }
  }

  updateRadius() {
    const body = {
      activityRadius: this.radius,
      presentation: 'hello presentation',
      description: 'hello description'
    }

    this.coachService.patch(body, this.userInfos.profile.coach.uuid).subscribe(
      res => {
        console.log("PATCH : ")
        console.log(res)
      },
      err => {
        console.log(err)
      }
    )
  }

  addCourse() {
    console.log(this.form)
    //DAYS
    const textBody = [
      [
        this.form.value.monday.checked,
        this.form.value.monday.begin,
        this.form.value.monday.end,
      ],
      [
        this.form.value.tuesday.checked,
        this.form.value.tuesday.begin,
        this.form.value.tuesday.end,
      ],
      [
        this.form.value.wednesday.checked,
        this.form.value.wednesday.begin,
        this.form.value.wednesday.end,
      ],
      [
        this.form.value.thursday.checked,
        this.form.value.thursday.begin,
        this.form.value.thursday.end,
      ],
      [
        this.form.value.friday.checked,
        this.form.value.friday.begin,
        this.form.value.friday.end,
      ],
      [
        this.form.value.saturday.checked,
        this.form.value.saturday.begin,
        this.form.value.saturday.end,
      ],
      [
        this.form.value.sunday.checked,
        this.form.value.sunday.begin,
        this.form.value.sunday.end,
      ],
    ]

    let weekdays = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
    let test = false;
    const availabilityDays = [];

    for (let [i, el] of textBody.entries()) {
      if (el[0]) {
        if (el[1] == '' || el[2] == '') {
          alert("Les temps de jours cochés doivent être définis");
          // return
        }
        if (el[2] <= el[1]) {
          alert("Le temps de début doit être inférieur au temps de fin");
          // return
        }
        availabilityDays.push({
          startAt: el[1],
          endAt: el[2],
          currentState: 'available',
          day: weekdays[i].toLowerCase(),
        })
        test = true;
      }

    }
    if (!test) {
      alert("Vous devez sélectionner au moins un jour de la semaine");
      //return
    }
    //PLACES
    let places = [];
    let gyms = [];
    if (this.form.value.lieux.exterieur == true) {
      places.push("outdoor");
    }
    if (this.form.value.lieux.domicile == true) {
      places.push("home");
    }
    if (this.form.value.lieux.salle == true) {
      places.push("gym");
      //GYMS
      for (let i = 0; i < this.salles.length; i++) {
        if (this.form.value.gyms[i].gym) {
          gyms.push(this.salles[i].name)
        }
      }
      //gyms.push(this.form.value.salles);
    }

    //TIME
    var time = moment.utc("00:" + this.form.value.timeBlockedAfter + ":00", "HH:mm:ss").format("HH:mm");

    const body = {
      coach: this.coachInfos.uuid,
      sports: this.form.value.sports.map(o => ['uuid'].reduce((acc, curr) => {
        acc = o[curr];
        return acc;
      }, {})),

      places: places,
      type: this.form.value.type,
      availabilityDays: availabilityDays,
      timeBlockedAfter: time,
      gyms: gyms
    }

    console.log(body)

    console.log("REQUETE : ")
    this.isLessonModalOpen = false;
    this.coachService.postAvailabilities(body).subscribe(
      res => {
        console.log(res);
        this.form.reset();
        console.log(this.isLessonModalOpen)

        this.router.navigate(['/coach-home-agenda']);
      },
      err => {
        alert("Votre créneau hebdomadaire n'a pas pu être sauvegradé")
        console.log(err)
      }
    )
  }

  patchCourse(course) {
    //DAYS
    const textBody = [
      [
        this.form.value.monday.checked,
        this.form.value.monday.begin,
        this.form.value.monday.end,
      ],
      [
        this.form.value.tuesday.checked,
        this.form.value.tuesday.begin,
        this.form.value.tuesday.end,
      ],
      [
        this.form.value.wednesday.checked,
        this.form.value.wednesday.begin,
        this.form.value.wednesday.end,
      ],
      [
        this.form.value.thursday.checked,
        this.form.value.thursday.begin,
        this.form.value.thursday.end,
      ],
      [
        this.form.value.friday.checked,
        this.form.value.friday.begin,
        this.form.value.friday.end,
      ],
      [
        this.form.value.saturday.checked,
        this.form.value.saturday.begin,
        this.form.value.saturday.end,
      ],
      [
        this.form.value.sunday.checked,
        this.form.value.sunday.begin,
        this.form.value.sunday.end,
      ],
    ]

    let weekdays = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]
    let test = false;
    const availabilityDays = [];

    for (let [i, el] of textBody.entries()) {
      if (el[0]) {
        if (el[1] == '' || el[2] == '') {
          alert("Les temps de jours cochés doivent être définis");
          // return
        }
        if (el[2] <= el[1]) {
          alert("Le temps de début doit être inférieur au temps de fin");
          // return
        }
        availabilityDays.push({
          startAt: el[1],
          endAt: el[2],
          currentState: 'available',
          day: weekdays[i].toLowerCase(),
        })
        test = true;
      }

    }
    if (!test) {
      alert("Vous devez sélectionner au moins un jour de la semaine");
      //return
    }
    //PLACES
    let places = [];
    let gyms = [];
    if (this.form.value.lieux.exterieur == true) {
      places.push("outdoor");
    }
    if (this.form.value.lieux.domicile == true) {
      places.push("home");
    }
    if (this.form.value.lieux.salle == true) {
      places.push("gym");
      //GYMS
      for (let i = 0; i < this.salles.length; i++) {
        if (this.form.value.gyms[i].gym) {
          gyms.push(this.salles[i].name)
        }
      }
    }

    //TIME
    var time = moment.utc("00:" + this.form.value.timeBlockedAfter + ":00", "HH:mm:ss").format("HH:mm");

    const body = {
      coach: this.coachInfos.uuid,
      sports: this.form.value.sports.map(o => ['uuid'].reduce((acc, curr) => {
        acc = o[curr];
        return acc;
      }, {})),

      places: places,
      type: this.form.value.type,
      availabilityDays: availabilityDays,
      timeBlockedAfter: time,
      gyms: gyms
    }

    console.log(body)

    console.log("REQUETE : ")
    this.coachService.patchAvailabilities(body, course[3]).subscribe(
      res => {
        console.log(res);
        this.isLessonModalOpen = false;
      },
      err => {

        console.log(err)
      }
    )
  }

  edit(course) {
    console.log(course);
    this.patchedCourse = course;
    this.displayPopup = 2;
  }


  onSubmit(form: NgForm) {
    console.log(form)
    const body = {
      activityRadius: this.radius,
      presentation: form.value.presentation,
      description: form.value.description
    }

    this.coachService.patch(body, this.userInfos.profile.coach.uuid).subscribe(
      res => {
        console.log("PATCH : ")
        console.log(res)
      },
      err => {
        console.log(err)
      }
    )
    console.log(this.presentation)
  }

  onSportModalDismiss(e: Event) {
    console.log(e)
    this.isSportModalOpen = false;
  }

  onLessonModalDismiss(e: Event) {
    console.log(e)
    this.isLessonModalOpen = false;
  }

  setSportModalOpen(isOpen: boolean) {
    this.isSportModalOpen = isOpen;
  }

  setLessonModalOpen(isOpen: boolean) {
    this.isLessonModalOpen = isOpen;
  }

  adjustTimeBlockAfter(valueChange: number): void {
    let currentValue = this.form.get('timeBlockedAfter').value || 0;
    let newValue = currentValue + valueChange;

    // Ensure newValue does not go below 0
    if (newValue < 0) {
      newValue = 0;
    }

    this.form.get('timeBlockedAfter').setValue(newValue);
  }

}
