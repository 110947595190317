import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatMap, map, tap } from 'rxjs/operators';
import { ProfileService } from './api/profile.service';

@Injectable({
  providedIn: 'root'
})
export class GoogleApiService {

  constructor(
    private http: HttpClient,
    private profileService: ProfileService
  ) { }

  getLatLong(address, id) {
    console.log('lattitude')
    this.http.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}+&key=AIzaSyAnA7JB9tiXqVnTbVp6uWjEqLHqzG_WJ1M`).subscribe(res => {
      const lat = res.results[0].geometry.location.lat
      const lng = res.results[0].geometry.location.lng
      const body = {
        address : {
          latitude: lat,
          longitude: lng
        }
      }
      this.profileService.patch(body, id).subscribe(res => console.log(res))
    })
  }
}
