import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(
    private api: ApiService, 
    private http: HttpClient
  ) { }

  getStatus () {
    return this.http.get(this.api.getUrl('/api/stripe/status-account'), this.api.getOptions());
  }
}
