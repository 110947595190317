import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Coach } from 'src/app/models/coach';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CoachService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  getAllCoach(): Observable<Coach> {
    return this.http.get<Coach>(this.api.getUrl('/api/coach'), this.api.getOptions());
  }

  post(body): Observable<Coach> {
    return this.http.post<Coach>(this.api.getUrl('/api/coach'), body, this.api.getOptions());
  }

  get(uuid: string): Observable<Coach> {
    return this.http.get<Coach>(this.api.getUrl(`/api/coach/${uuid}`), this.api.getOptions());
  }

  patch(body, uuid): Observable<Coach | null> {
    return this.http.post<Coach>(this.api.getUrl(`/api/coach/${uuid}`), body, this.api.getOptions());
  }

  getStats(startDate: string, endDate: string): Observable<any> {
    const options = {
      params: {
        startDate: startDate,
        endDate: endDate,
      }
      , ...this.api.getOptions(),
    }
    return this.http.get(this.api.getUrl(`/api/coach-stat`), options);
  }

  // postStats() {

  // }

  addSport(body): Observable<Coach> {
    return this.http.post<Coach>(this.api.getUrl('/api/coach-sport'), body, this.api.getOptions());
  }
  
  showSports(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/coach-sport?coachUuid=${uuid}`), this.api.getOptions());
  }

  deleteSport(uuid: string) {
    return this.http.delete<Coach>(this.api.getUrl(`/api/coach-sport/${uuid}`), this.api.getOptions());
  }


  getAvailabilities() {
    return this.http.get(this.api.getUrl(`/api/coach-availability`), this.api.getOptions());
  }

  postAvailabilities(body) {
    return this.http.post(this.api.getUrl(`/api/coach-availability`), body, this.api.getOptions());
  }

  patchAvailabilities(body, uuid: string) {
    return this.http.patch(this.api.getUrl(`/api/coach-availability/${uuid}`), body, this.api.getOptions());
  }
  deleteAvailabilities(uuid: string) {
    return this.http.delete(this.api.getUrl(`/api/coach-availability/${uuid}`), this.api.getOptions());
  }
  
  getJSP() {
    return this.http.get(this.api.getUrl(`/api/room/search-list-user`), this.api.getOptions());
  }
}