import { Component, OnInit } from '@angular/core';
import { ToggleMenuService } from 'src/app/services/toggle-menu.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { GlobalGettersService } from 'src/app/services/api/global-getters.service';
import { timer } from 'rxjs';
import { SecurityService } from 'src/app/services/security.service';
import { UserService } from 'src/app/services/api/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  collapsed;

  notifications;
  // unreadNotifications;
  
  firstName;
  lastName;

  //
  nbrOfNotifications;
  notifInProgress;

  //
  timer;

  //
  valid;

  constructor(
    private toggleMenuService: ToggleMenuService,
    private notificationsService: NotificationsService,
    private globalGetters: GlobalGettersService,
    private security: SecurityService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.toggleMenuService.currentCollapsed.subscribe(data => this.collapsed = data)
    this.notificationsService.currentNotifications.subscribe(res => this.nbrOfNotifications = res)
    this.notificationsService.currentNotifInProgress.subscribe(res => this.notifInProgress = res)

    this.userService.getCurrent().subscribe(res => {
      console.log(res)
      res.profile.coach.firstName ? this.firstName = res.profile.coach.firstName : 
          this.firstName = res.profile.client.firstName
      res.profile.coach.lastName ? this.lastName = res.profile.coach.lastName : 
          this.lastName = res.profile.client.lastName

      // Profil Valid
      const roles = res.roles
      this.valid = roles.includes("ROLE_VALID")

      if (this.valid) {
        this.timer = timer(0, 60000).subscribe(() => {
          // if (!this.notifInProgress) {
            console.log('CHECK THE NOTIFICATIONS')
            this.checkForNotifications();
          // }
        })
      }
    })
  }


  toggleMenu() {
    this.toggleMenuService.changeCollapsed(false) 
  }

  goToNotications() {
    // this.notificationsService.setNotifInProgress(true);
  }

  checkForNotifications() {
    this.globalGetters.getNotifications(1, false, 20).subscribe(res => {
      this.notifications = res
      this.nbrOfNotifications = this.notifications.totalItemCount
      this.notificationsService.setNotifications(this.nbrOfNotifications)
      // console.log(this.nbrOfNotifications)
    })
  }

}
