import { Component, OnInit } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { Coach } from 'src/app/models/coach';
import { User } from 'src/app/models/user';
import { ProfileService } from 'src/app/services/api/profile.service';
import { UserService } from 'src/app/services/api/user.service';
import { SecurityService } from 'src/app/services/security.service';
import { CoachService } from 'src/app/services/api/coach.service';
import { TestimonyService } from 'src/app/services/api/testimony.service';
import { ProfilePictureService } from 'src/app/services/shared-data/profile-picture.service';
import { StatusService } from 'src/app/services/api/status.service';
import { ToggleMenuService } from 'src/app/services/toggle-menu.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  profile: Profile;
  coach: Coach;
  user: User;
  profilPicture;

  firstName;
  lastName;

  //
  rating;
  testimonies;
  voters;

  //
  valid;

  //
  collapsed;

  constructor(
    private userService: UserService,
    private testimonyService: TestimonyService,
    private security: SecurityService,
    private profilePictureService : ProfilePictureService,
    private statusService: StatusService,
    private toggleMenuService: ToggleMenuService
  ) { }

  async ngOnInit() {
    this.toggleMenuService.currentCollapsed.subscribe(data => this.collapsed = data)

    this.statusService.getStatus().subscribe(res => {
      console.log(res)
    })
    
    // profile picture
    this.profilePictureService.currentProfilePicture.subscribe(profilePicture => this.profilPicture = profilePicture)
    
    console.log("hello")

    const connectToken = this.security.connectWithToken()

    this.userService.getCurrent().subscribe(res => {
      console.log(res)
      localStorage.setItem('profileUuid', res.profile.uuid)
      localStorage.setItem('coachUuid',res.profile.coach.uuid) 

      this.firstName = res.profile.coach.firstName
      this.lastName = res.profile.coach.lastName
      
      // Profil Valid
      const roles = res.roles
      this.valid = roles.includes("ROLE_VALID")
      console.log(this.valid)
      
      // Profil Pic
      if(res.profile.picture) {
        this.profilPicture = res.profile.picture.url
      } else {
        let gender = res.profile.coach.gender
        if(gender === 'female') {
          this.profilPicture = 'assets/icon/avatar-female.svg'
        } else {
          this.profilPicture = 'assets/icon/avatar-male.svg'
        }
      }
    })
  }

  ionViewDidEnter() {

  }

  toggleMenu() {
    this.toggleMenuService.changeCollapsed(true) 
  }

}
