import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ClientService } from 'src/app/services/api/client.service';
import { CoachService } from 'src/app/services/api/coach.service';
import { CompanyService } from 'src/app/services/api/company.service';
import { UserService } from 'src/app/services/api/user.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'app-w-login',
  templateUrl: './w-login.component.html',
  styleUrls: ['./w-login.component.scss'],
})
export class WLoginComponent implements OnInit {
  form: FormGroup;
  passwordShown: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private security: SecurityService,
    private coach : CoachService,
    private company : CompanyService,
    private client : ClientService,
    private router : Router
  ) { }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      email: 
      [ '',
        // 'jellujujafra-9861@yopmail.com',
        // 'coach@usts.fr',
        // "sijudussihau-2082@yopmail.com", // 1 seul statut
       [Validators.required, Validators.email]],
      password: ['',[Validators.required]]
    })
  }

  async onSubmit() {
    if (this.form.valid) {
      const body = {
        email: this.form.value.email,
        plainPassword: this.form.value.plainPassword,
      };

      this.security.login({ username: this.form.value.email, password: this.form.value.password})
       .subscribe((res) => {
        console.log(res)
        localStorage.setItem('userUuid', res.uuid)
       },
       err => {
        console.log(err)
       })
    }
  }

  goToDashboard() {
    this.router.navigate(['/coach-home-dashboard'])
  }

  togglePasswordVisibility() {
    this.passwordShown = !this.passwordShown;
  }

}