import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import { CoachService } from 'src/app/services/api/coach.service';
import { CompanyService } from 'src/app/services/api/company.service';
import { SportCategoryService } from 'src/app/services/api/sport-category.service';
import { SportService } from 'src/app/services/api/sport.service';
import { SecurityService } from 'src/app/services/security.service';
import { MatDialog } from '@angular/material/dialog';
import { NewStatusComponent } from '../modal/new-status/new-status.component';
import { UserService } from 'src/app/services/api/user.service';


@Component({
  selector: 'app-status-pro',
  templateUrl: './status-pro.component.html',
  styleUrls: ['./status-pro.component.scss'],
})
export class StatusProComponent implements OnInit {
  // Form
  public form: FormGroup;
  public formStatus: FormGroup;
  public formStatusBis: FormGroup;
  public formNewStatus: FormGroup;

  // Loader
  private loader: HTMLIonLoadingElement;

  // Files variables
  KBISfile: File | null = null;

  // Page Title
  parentMessage = "Statut Professionnel";

  // Infos
  userInfos;

  //
  companies: any[] = [];
  companiesNbr;
  companiesUuid;

  date = new Date();

  // Popup
  popupNewStatus = false;

  //SAP certified
  sap;

  constructor(
    private formBuilder: FormBuilder,
    private sportService: SportService,
    private sportCategoryService: SportCategoryService,
    private coachService: CoachService,
    private security: SecurityService,
    private loadingController: LoadingController,
    private companyService: CompanyService,
    private userService: UserService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    // Form Status
    this.formStatus = this.formBuilder.group({
      isSAPCertified: [null, [Validators.required]],
      SAPCertificationObtentionDate: [null, []],
      SAPNumber: [null, []],
      registrationNumber: ['', [Validators.required]],
      type: ['', [Validators.required]],
      name: ['', [Validators.required]],
      KBIS: ['', [Validators.required]],
      IBAN: ['', [Validators.required]],
      BIC: ['', [Validators.required]],
    })

    // Form New Status
    this.formNewStatus = this.formBuilder.group({
      registrationNumber: ['', [Validators.required]],
      type: ['', [Validators.required]],
      name: ['', [Validators.required]],
      KBIS: ['', [Validators.required]],
      IBAN: ['', [Validators.required]],
      BIC: ['', [Validators.required]],
    });

    this.userService.getCurrent().subscribe(res => {
      console.log(res)
      this.userInfos = res
      
      this.coachService.get(this.userInfos.profile.coach.uuid).subscribe(res => {
        this.companiesNbr = res.companies.length
        this.companiesUuid = res.companies
  
        console.log(res)
        console.log(this.companiesNbr)
        console.log(this.companiesUuid)
  
        this.companiesUuid.map((company, index) => {
          console.log(company)
          this.companyService.get(company.uuid).subscribe(res => {
            this.companies.push(res)
            console.log(this.companies)
            switch (this.companies[index]?.type) {
              case 'self_employed' :
                this.companies[index].typeName = "Auto-entrepreneur";
                break;
              case 'eurl' :
                this.companies[index].typeName = "EURL";
                break;
              case 'sasu' :
                this.companies[index].typeName = "SASU";
                break;
              case 'other' :
                this.companies[index].typeName = "Autre";
                break;         
            }
  
            // if(value === 1) {
            //   this.formStatus.patchValue({
            //     SAPNumber: this.companies[index].SAPNumber,
            //     registrationNumber: this.companies[index].registrationNumber,
            //   })
            // }
            console.log(this.companies)
            console.log(this.companies[0].SAPNumber)
            console.log(this.companies[0].isSAPCertified)

            this.formStatus.patchValue({
              name: this.companies[0].name,
              type: this.companies[0].typeName,
              isSAPCertified: this.companies[0].isSAPCertified,
              registrationNumber: this.companies[0].registrationNumber,
              SAPNumber: this.companies[0].SAPNumber,
              SAPCertificationObtentionDate: formatDate(new Date(this.companies[0].SAPCertificationObtentionDate), 'yyyy-MM-dd', 'en')
            })
            this.date = new Date(this.companies[0].SAPCertificationObtentionDate)
            this.displaySap(0)
          })
        })
      })
    })
  }

  onChangeStatus(e) {
    console.log(e)
    console.log(e.target.value)
    let choosenStatus = this.companies.find(item => item.type === e.target.value)
    console.log(choosenStatus)
    if(choosenStatus.SAPNumber === null) {
      this.sap = true
    } else {
      this.sap = false
    }
    this.pacthFormValues(choosenStatus)
  }

  
  pacthFormValues(status) {
    let sapCertified = false
    if(status.SAPNumber) {
      sapCertified = true
    }
    this.formStatus.patchValue({
      name: status.name,
      type: status.typeName,
      registrationNumber: status.registrationNumber,
      SAPNumber: status.SAPNumber,
    })

    console.log(status.SAPCertificationObtentionDate)
    this.date = status.SAPCertificationObtentionDate
  }

  displaySap(index) {
    if(this.companies[index].SAPNumber === null) {
      this.sap = true
    } else {
      this.sap = false
    }
  }

  addNewStatus() {
    const dialogRef = this.dialog.open(NewStatusComponent, {
      width: '550px'
    });
  }

  
  setDate(data: any) {
    // Set today date using the patchValue function
    const date = new Date(data);
    return {
      date: {
        year: date.getFullYear(),
        month: date.getMonth() + 1,
        day: date.getDate()
      }
    };
  }

  showDate(e) {
    console.log(e)
  }


  async onSubmitForm() {
    const body = {
      isSAPCertified: this.formStatus.value.isSAPCertified
    }
    console.log(body)
  }

  onSelectSap() {

  }

  async onSubmit() {
    this.loadingController.create({ message: 'Mise à jour des préférences' }).then(loader => {
      loader.present()

      loader.dismiss()
    })
  }

  onFileKBISSelected(event) {
    this.KBISfile = <File>event.target.files[0];
    console.log(this.KBISfile)
  }

  async onSubmitNewStatus() {
    if (this.formNewStatus.valid) {
      const formData = new FormData();
      formData.append('name', this.formNewStatus.value.name)
      formData.append('type', this.formNewStatus.value.type)
      formData.append('isSAPCertified', '0')
      formData.append('SAPNumber', '')
      formData.append('SAPCertificationObtentionDate', '')
      formData.append('registrationNumber', this.formNewStatus.value.registrationNumber)
      formData.append('BIC', this.formNewStatus.value.BIC)
      formData.append('KBIS[file]', this.KBISfile)
      formData.append('IBAN', this.formNewStatus.value.IBAN)
      formData.append('coach', this.security.user.profile.coach.uuid)
  
  
  
      let func = null;
      this.loader = await this.loadingController.create({ message: 'Loading' })
      this.loader.present()
  
      func = this.companyService.post(formData).subscribe(
        res => {
          console.log(res)
          this.loader.dismiss().then(() => {
            this.popupNewStatus = false
            this.ngOnInit()
          })
        }
      )
    }
  }

  displayPopup() {
    this.popupNewStatus = this.popupNewStatus === false
  }

}
