import { Component, OnInit, Input } from '@angular/core';
import { TestimonyService } from 'src/app/services/api/testimony.service';
import { UserService } from 'src/app/services/api/user.service';
import { SecurityService } from 'src/app/services/security.service';
import { ToggleMenuService } from 'src/app/services/toggle-menu.service';

@Component({
  selector: 'app-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent implements OnInit {
  @Input() childMessage: string
  testimonies;
  rating;
  voters;

  //
  coachUuid
  coachID

  // Menu
  collapsed

  constructor(
    private security: SecurityService,
    private testimonyService: TestimonyService,
    private userService: UserService,
    private toggleMenuService: ToggleMenuService
  ) { }

  ngOnInit() {
    // Menu
    this.toggleMenuService.currentCollapsed.subscribe(data => this.collapsed = data)

    this.coachUuid = localStorage.getItem('coachUuid')
    if (this.coachUuid === null) {
      this.userService.getCurrent().subscribe(res => {
        console.log(res)
        this.coachUuid = res.profile.coach.uuid
        this.getRating()
      })
    } else {
      this.getRating()
    }
    this.userService.getCurrent().subscribe(res => {
      this.coachID = res.profile.coach.identifier
    })
  }

  getRating() {
    // Ratings
    this.testimonyService.getCoach(this.coachUuid).subscribe(res => {
      this.testimonies = res
      console.log(this.testimonies)
      this.rating = (JSON.parse(JSON.stringify(res)).items.reduce((accumulator, curr) => accumulator + curr.note, 0)/JSON.parse(JSON.stringify(res)).items.length).toFixed(1);
      this.voters = JSON.parse(JSON.stringify(res)).totalItemCount;
      console.log(this.rating)
      console.log(this.voters)
    })
  }

  toggleMenu() {
    this.toggleMenuService.changeCollapsed(true) 
  }
  
}


