import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CoachService } from 'src/app/services/api/coach.service';
import { CompanyService } from 'src/app/services/api/company.service';
import { IbanService } from 'src/app/services/api/iban.service';
import { UserService } from 'src/app/services/api/user.service';
import { SecurityService } from 'src/app/services/security.service';

@Component({
  selector: 'app-info-bank',
  templateUrl: './info-bank.component.html',
  styleUrls: ['./info-bank.component.scss'],
})

export class InfoBankComponent implements OnInit {
  public form: FormGroup;

  // Page Title
  parentMessage = "Informations Bancaires";

  // User Infos
  userInfos;
  coachsInfos;
  companyInfos:any[] = [];
  infosComp;

  //
  addressCheck: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private security: SecurityService,
    private companyService: CompanyService,
    private ibanService: IbanService,
    private userService: UserService,
    private coachService: CoachService
  ) { }

  ngOnInit() {
    this.userService.getCurrent().subscribe(res => {
      this.userInfos = res
      console.log(this.userInfos)
      this.coachService.get(this.userInfos.profile.coach.uuid).subscribe(res => {
        this.coachsInfos = res
        console.log(this.coachsInfos)
        console.log(this.coachsInfos.companies.length)
        for (let i = 0; i < this.coachsInfos.companies.length; i++) {
          this.companyService.get(this.coachsInfos.companies[i].uuid).subscribe(res => {
            console.log('get company')
            console.log(res)
            this.infosComp = res
            this.companyInfos.push(this.infosComp)
            console.log(this.companyInfos)
            this.form.patchValue({
              iban: this.companyInfos[0].IBAN
            })
          })
        }
      })
      this.form.patchValue({
        titulaire: `${this.userInfos.profile.coach.firstName} ${this.userInfos.profile.coach.lastName}`,
      })
    })

    this.form = this.formBuilder.group({
      titulaire : ['', [Validators.required]],
      iban : ['', [Validators.required]],
      city : ['', ],
      address : ['', ],
      country : ['', ],
      region : ['', ],
      postalCode : ['', ],
    })


  }

  async onSubmit() {
    if (this.form.valid) {
      let city;
      let address;
      let postalCode;
      let country;
      let region;

      if (this.addressCheck) {
        city = this.form.value.city,
        address = this.form.value.address,
        postalCode = this.form.value.postalCode,
        country = this.form.value.country,
        region = this.form.value.region
      } else {
        city = this.userInfos.profile.city,
        address = this.userInfos.profile.city,
        postalCode = this.userInfos.profile.postalCode,
        country = this.userInfos.profile.country,
        region = this.userInfos.profile.region
      }

      const body = {
        iban: this.form.value.iban,
        currency: "eur",
        titulaire: this.form.value.titulaire,
        address: {
          city: city,
          address: address,
          postalCode: postalCode,
          country: country,
          region: region
        }
      }

      console.log(body)

      let func = null;

      // func = this.ibanService.createNewIban(body).subscribe(res => {
      //   console.log(res)
      // })
    } else {
      console.log("Form not valid")
    }
  }
  
  onSelectAddress() {
    if (this.addressCheck === false) {
      this.addressCheck = true
      // add validators
      this.form.controls["city"].setValidators([Validators.required])
      this.form.controls["address"].setValidators([Validators.required])
      this.form.controls["postalCode"].setValidators([Validators.required])
      this.form.controls["country"].setValidators([Validators.required])
      this.form.controls["region"].setValidators([Validators.required])
      // update validators
      this.form.controls["city"].updateValueAndValidity()
      this.form.controls["address"].updateValueAndValidity()
      this.form.controls["postalCode"].updateValueAndValidity()
      this.form.controls["country"].updateValueAndValidity()
      this.form.controls["region"].updateValueAndValidity()
    } else {
      this.addressCheck = false
      // erase validators
      this.form.controls["city"].clearValidators()
      this.form.controls["address"].clearValidators()
      this.form.controls["postalCode"].clearValidators()
      this.form.controls["country"].clearValidators()
      this.form.controls["region"].clearValidators()
      // update validators
      this.form.controls["city"].updateValueAndValidity()
      this.form.controls["address"].updateValueAndValidity()
      this.form.controls["postalCode"].updateValueAndValidity()
      this.form.controls["country"].updateValueAndValidity()
      this.form.controls["region"].updateValueAndValidity()
    }
  }

}
