import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class LessonService {

  constructor(
    private api: ApiService, 
    private http: HttpClient
  ) { }

  post(body) {
    return this.http.post(this.api.getUrl('/api/lesson/search'), body, this.api.getOptions());
  }

  getAvailability(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/lesson-reservation/coach/${uuid}`), this.api.getOptions());
  }

  postLessonReservation(body) {
    return this.http.post(this.api.getUrl(`/api/lesson-reservation`), body, this.api.getOptions());
  }

  getLesson(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/lesson-reservation/${uuid}`), this.api.getOptions());
  }

  cancelLesson(uuid: string) {
    return this.http.post(this.api.getUrl(`/api/lesson-reservation/${uuid}/canceled`), this.api.getOptions());
  }
}