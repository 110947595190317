import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-registered',
  templateUrl: './modal-registered.component.html',
  styleUrls: ['./modal-registered.component.scss'],
})
export class ModalRegisteredComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
