import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SportCategoryService } from 'src/app/services/api/sport-category.service';
import { SportService } from 'src/app/services/api/sport.service';
import { Sports } from 'src/app/models/sports'

@Component({
  selector: 'app-fields',
  templateUrl: './fields.component.html',
  styleUrls: ['./fields.component.scss'],
})
export class FieldsComponent implements OnInit {
  public form: FormGroup;

  // Fields variables
  classicFields = false;
  specificFields = false;
  publicFields = false;
  companyFields = false;
  homeFields = false;
  outsideFields = false;
  distanceFields = false;
  gymFields = false;

  // Distance Value
  distance: number;

  // 
  sportsRes:any;
  sports:[];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private location: Location,
    private sportService: SportService,
    private sportCategoryService: SportCategoryService
  ) { }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      classic: ['', [Validators.required]],
      classicSports: ['', [Validators.required]],
      specific: ['', [Validators.required]],
      specificSports: ['', [Validators.required]],
      public: ['', [Validators.required]],
      publicSports: ['', [Validators.required]],
      company: ['', [Validators.required]],
      companySports: ['', [Validators.required]],
      home: ['', [Validators.required]],
      outside: ['', [Validators.required]],
      distance: ['', [Validators.required]],
      gym: ['', [Validators.required]],
    });

    this.sportService.get().subscribe(res => {
      this.sportsRes = res;
      this.sports = this.sportsRes.items
    })
  }

  async onSubmit() {
    const body = {
      classic: this.form.value.classic,
      classicSports: this.form.value.classicSports,
      specific: this.form.value.specific,
      specificSports: this.form.value.specificSports,
      public: this.form.value.public,
      publicSports: this.form.value.publicSports,
      company: this.form.value.company,
      companySports: this.form.value.companySports,
      home: this.form.value.home,
      outside: this.form.value.outside,
      country: this.form.value.country,
      distance: this.form.value.distance,
      gym: this.form.value.gym
    };

    let func = null;
    func = 
    this.router.navigate(['coach-inscription-description']);
  }

  onPrevious(): void {
    this.location.back();
  }

  // Checkbox Behaviours
  onSelectClassic() {
    this.classicFields = this.classicFields === false;
  }
  onSelectSpecific() {
    this.specificFields = this.specificFields === false;
  }
  onSelectPublic() {
    this.publicFields = this.publicFields === false;
  }
  onSelectCompany() {
    this.companyFields = this.companyFields === false;
  }
  onSelectHome() {
    this.homeFields = this.homeFields === false;
    this.onDisplayDistance();
  }
  onSelectOutside() {
    this.outsideFields = this.outsideFields === false;
    this.onDisplayDistance();
  }

  onDisplayDistance() {
    if (this.homeFields || this.outsideFields) {
      this.distanceFields = true;
    } else {
      this.distanceFields = false;
    }
  }

  onSelectGym() {
    this.gymFields = this.gymFields === false;
  }

  onSelectDistance(e) {
    this.distance = e.target.value;
  }

  //
  onLookingForGym() {
    this.router.navigate(['coach-inscription-gym']);
  }

}
