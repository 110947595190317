import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/security.service';
import { ToggleMenuService } from 'src/app/services/toggle-menu.service';
import { navbarUtiles, navbarLegales } from './nav-data';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  @Input() childMessage: boolean;
  collapsed;
  navDataUtiles = navbarUtiles;
  navDataLegales = navbarLegales;

  //
  isConnected:boolean = false;

  constructor(
    private toggleMenuService: ToggleMenuService,
    private security: SecurityService,
    private router: Router
  ) { }

  ngOnInit() {
    this.toggleMenuService.currentCollapsed.subscribe(data => this.collapsed = data)
    this.isConnected = this.security.checkForToken()
    console.log(this.isConnected)
  }

  closeMenu():void {
    this.toggleMenuService.changeCollapsed(true)
  }

  logout() {
    this.security.logout()
    this.toggleMenuService.changeCollapsed(true)
  }

  goTo(url) {
    this.router.navigate(url)
  }

}
