import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bootcamp-created',
  templateUrl: './bootcamp-created.component.html',
  styleUrls: ['./bootcamp-created.component.scss'],
})
export class BootcampCreatedComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
