import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class GlobalGettersService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  getFaq() {
    return this.http.get(this.api.getUrl('/api/faq'), this.api.getOptions());
  }
  getSport() {
    return this.http.get(this.api.getUrl(`/api/sport`), this.api.getOptions());
  }
  getSportCoach(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/coach-sport/${uuid}?sort=id&direction=ASC&limit=20&page=1`), this.api.getOptions());
  }
  getCategories() {
    return this.http.get(this.api.getUrl(`/api/sport-categories`), this.api.getOptions());
  }
  getArticles() {
    return this.http.get(this.api.getUrl(`/api/article`), this.api.getOptions());
  }
  getNotification() {
    return this.http.get(this.api.getUrl(`/api/notification`), this.api.getOptions());
  }
  getReadNotification(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/notification/${uuid}/read`), this.api.getOptions());
  }
  getFidelityPoints() {
    return this.http.get(this.api.getUrl(`/api/fidelity/me`), this.api.getOptions());
  }
  getFidelityAdvantages() {
    return this.http.get(this.api.getUrl(`/api/fidelity`), this.api.getOptions());
  }

    //Notifications

    getNoticationSummary() {
      return this.http.get(this.api.getUrl(`/api/notification/summary`), this.api.getOptions());
    }
    getNotificationPage(page) {
      return this.http.get(this.api.getUrl(`/api/notification?page=${page}`), this.api.getOptions());
    }
  
    getNotifications(page, isRead, limit) {
      return this.http.get(this.api.getUrl(`/api/notification/?direction=DESC&limit=${limit}&page=${page}&read=${isRead}`), this.api.getOptions());
    }
}