import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private formSource = new BehaviorSubject(this.formBuilder.group({test: ['test']}));
  currentForm = this.formSource.asObservable();

  constructor(
    private formBuilder: FormBuilder
    ) { }

  changeForm(form: FormGroup) {
    this.formSource.next(form)
  }
}
