import { ChangeDetectorRef, Component, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
//import { NavController } from 'ionic-angular';
import { CalendarMode, Step } from 'ionic2-calendar/calendar';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { CalendarComponent } from "ionic2-calendar";
import { GlobalGettersService } from 'src/app/services/api/global-getters.service';
import { SecurityService } from 'src/app/services/security.service';
import { CoachService } from 'src/app/services/api/coach.service';
import { LessonService } from 'src/app/services/api/lesson.service';
import { CalendarOptions } from '@fullcalendar/angular';
import { EventInput } from '@fullcalendar/angular';

import { DateSelectArg, EventClickArg, EventApi } from '@fullcalendar/angular';
import { ThrowStmt } from '@angular/compiler';
import { ClientService } from 'src/app/services/api/client.service';
import { GymService } from 'src/app/services/api/gym.service';
@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.scss'],
})
export class AgendaComponent implements OnInit {
  // Page Title
  parentMessage = "Informations Bancaires";

  INITIAL_EVENTS: EventInput[] = []
  //Events: any[] = [];SUPPR
  calendarVisible = true;
  currentLesson = {};
  calendarOptions: CalendarOptions = {
    headerToolbar: {
      left: 'prev',
      center: 'title today',
      right: 'next'
    },
    buttonText: {
      today: 'Aujourd\'hui'
    },
    initialView: 'timeGridWeek',
    //timeZone: 'France/Paris',
    //events: undefined, // alternatively, use the `events` setting to fetch from a feed
    weekends: true,
    editable: false,
    selectable: true,
    selectMirror: true,
    dayMaxEvents: true,
    allDaySlot: false,
    dateClick: this.handleDateSelect.bind(this),
    eventClick: this.handleEventClick.bind(this),
    eventsSet: this.handleEvents.bind(this),
    eventColor: '#378006',
    firstDay: 1,
    locale: 'fr',//Dates
    slotLabelContent: function (arg) {//Heures
      return arg.date.getHours() + 'h';
    },
    eventOrder: "title",
    eventOverlap: true/*function(stillEvent, movingEvent) {
      return stillEvent.title == 'Disponibilité' && movingEvent.title == 'Cours';
    },*/
    /* you can update a remote database when these fire:
    eventAdd:
    eventChange:
    eventRemove:
    */
  };
  currentEvents: EventApi[] = [];

  handleCalendarToggle() {
    this.calendarVisible = !this.calendarVisible;
  }

  handleWeekendsToggle() {
    const { calendarOptions } = this;
    calendarOptions.weekends = !calendarOptions.weekends;
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    console.log("INFOS /////////")
    console.log(selectInfo)
    console.log(selectInfo["dateStr"])
    var d = new Date(selectInfo["dateStr"]);
    var day = d.getDay(),
      diff = d.getDate() - day + (day == 0 ? -6 : day); // adjust when day is sunday
    this.currentDate = new Date(d.setDate(diff));
    console.log(d.toISOString().slice(0, 12) + (parseInt(d.toISOString()[12]) + 1).toString() + d.toISOString().slice(13, 16))
    this.begin_time = d.toISOString().slice(0, 12) + (parseInt(d.toISOString()[12]) + 1).toString() + d.toISOString().slice(13, 16)
    this.displayPopup = 3;
  }

  handleEventClick(clickInfo: EventClickArg) {
    console.log("EVENT")
    console.log(clickInfo.event._def)
    console.log(clickInfo.event._def.extendedProps.typeDispo == "Disponibilité")
    this.currentEvent = clickInfo.event._def;
    if (clickInfo.event._def.extendedProps.typeDispo == "Disponibilité") {
      this.display_popup(4)
    }
    else if (clickInfo.event._def.extendedProps.typeDispo == "Indisponibilité") {
      this.display_popup(2)
    }
    else if (clickInfo.event._def.extendedProps.typeDispo == "Cours") {
      this.searchLesson(clickInfo.event._def.extendedProps.uuid);
    }
    /* else {
        this.display_popup(1)
    }*/
  }

  searchLesson(uuid) {
    this.lessonService.getLesson(uuid).subscribe(
      res => {
        console.log("LESSON :")
        console.log(res)
        this.currentLesson = res;

        if (res['place'] == 'gym') {
          this.gymService.get(res['gym'].uuid).subscribe(
            result => {
              console.log("GYM :")
              console.log(result)
              this.currentLesson['gym'] = result;
              this.display_popup(1);
            },
            err => {
              console.log(err)
            }
          )
        }
        else {
          this.display_popup(1);
        }
      },
      err => {
        console.log(err)
      }
    )
  }

  closeCourse() {
    this.display_popup(0);
    this.currentLesson = {};
  }

  handleEvents(events: EventApi[]) {
    this.currentEvents = events;
  }
  //GESTION DES EVENTS -----------------------------------

  eventGuid = 0;
  TODAY_STR = new Date().toISOString().replace(/T.*$/, ''); // YYYY-MM-DD of today

  createEventId() {
    return String(this.eventGuid++);
  }

  //FIN DU CALENDAR----------------------------------------------------------------------------

  public form: FormGroup;
  first_time = false;
  begin_time;
  after = 0;
  date;
  categories = [];
  sports = [];
  globalCss: HTMLElement;
  currentEvent;

  recurringEvents = [];

  displayPopup = 0;
  public dispoForm: FormGroup;
  public unDispoForm: FormGroup;

  //@ViewChild(CalendarComponent, null) myCalendar:CalendarComponent;SUPPR
  display_popup(x: number) {
    this.displayPopup = x;
    console.log(this.displayPopup)
  }

  weekDays = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

  dates = [];
  disponibilités = [
    { class: 'reservé', day: [(new Date()).toLocaleDateString("fr-FR", { weekday: 'long' }), (new Date()).getDate(), (new Date()).toLocaleDateString("fr-FR", { month: 'long' })], start: 9, end: 11 },
    { class: 'annulé', day: [(new Date()).toLocaleDateString("fr-FR", { weekday: 'long' }), (new Date()).getDate(), (new Date()).toLocaleDateString("fr-FR", { month: 'long' })], start: 13, end: 14 },
    { class: 'annulé', day: [(new Date()).toLocaleDateString("fr-FR", { weekday: 'long' }), (new Date()).getDate(), (new Date()).toLocaleDateString("fr-FR", { month: 'long' })], start: 7, end: 17 },
  ]



  eventSource = [];

  viewTitle;

  /*test(day, hour) {
    for (let i = 0; i < this.disponibilités.length; i++) {
        //console.log(day)
        if (day === this.disponibilités[i].day && hour >= this.disponibilités[i].start && hour < this.disponibilités[i].end) {
            console.log("ouiii")
            return true;
        }
    }
    return false;
  }*/

  getLastDay(date: Date) {
    return new Date(date.getTime() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString("fr")
  }

  getHour(date) {
    return new Date(date).getHours() - 1
  }
  getMinute(date) {
    return ((new Date(date).getMinutes()).toString().length === 1) ? '0' + (new Date(date).getMinutes()).toString() : (new Date(date).getMinutes()).toString();
  }
  getCategory(uuid) {
    console.log(this.categories)
    console.log(uuid)
    console.log(this.categories.find(e => uuid == e.uuid))
    return this.categories.find(e => uuid == e.uuid).name
  }
  getPlaces(element) {
    const array = [];
    for (var i in element) {
      array.push(element[i])
    }
    return array;
  }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private globalGettersService: GlobalGettersService,
    private security: SecurityService,
    private coachService: CoachService,
    private gymService: GymService,
    private cd: ChangeDetectorRef,
    private lessonService: LessonService
  ) { }


  addEvent(event) {
    this.INITIAL_EVENTS.push(event)
  }

  printPlace(place) {
    if (place = "outdoor") {
      return "Dehors";
    }
    else if (place = "home") {
      return "À la maison"
    }
    else {
      return "En salle"
    }
  }


  ngOnInit() {

    // document.getElementById("anchor").scrollIntoView({
    //     behavior: "smooth",
    //     block: "start",
    //     inline: "nearest"
    // });
    /* var x = new Date()
    console.log(x.getDate())
    console.log('second')
    console.log(new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000).toLocaleDateString("fr"))
    console.log(new Date().setDate(this.calendar.currentDate.getDate()+6))
    console.log(x)
    var day = new Date('Apr 30, 2000');
      console.log(day); // Apr 30 2000

      var nextDay = new Date(day);
      nextDay.setDate(day.getDate() + 1);
      console.log(nextDay); // May 01 2000 */

    this.globalGettersService.getCategories().subscribe(
      res => {
        console.log("CATEGORIES :")
        console.log(JSON.parse(JSON.stringify(res)).items)
        this.categories = JSON.parse(JSON.stringify(res)).items;
      },
      err => {
        console.log(err)
      }
    )

    this.reloadEvents();

    this.form = this.formBuilder.group({
      sport: ['', [Validators.required]],
      localisation: ['', [Validators.required]],
      participants: ['', [Validators.required]],
      sportHall: ['', [Validators.required]]
    })

    this.dispoForm = this.formBuilder.group({
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],
      timeBlockedBefore: [0, [Validators.required]],
      sport: ['', [Validators.required]],
      currentState: ['', [Validators.required]],
      hebdomadaire: ['', [Validators.required]]
    })

    this.unDispoForm = this.formBuilder.group({
      start: ['', [Validators.required]],
      end: ['', [Validators.required]],
      repetition: [0, [Validators.required]]
    })

    console.log("EVENTS :")
    console.log(this.eventSource)

  }

  reloadEvents() {
    this.INITIAL_EVENTS = [];
    this.coachService.getAvailabilities().subscribe(
      res => {
        console.log("AVAILABILITIES :")
        console.log(res)
        const weekDays = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
        for (let el of JSON.parse(JSON.stringify(res)).availabilities) {
          this.addEvent({
            //DONNÉES FONCTIONNELLES
            startTime: el.startAt.slice(11, 16),//15:20
            endTime: el.endAt.slice(11, 16),//08:00
            startRecur: new Date(),
            daysOfWeek: [weekDays.indexOf(el.day)],
            color: 'green',

            //DONNÉES INFORMATIVES
            id: el.availability.uuid,
            uuid: el.availability.uuid,
            title: "Disponibilité",
            description: 'Description',
            day: el.day,
            categorie: el.availability.type,
            tempsBloque: el.availability.timeBlockedAfter,
            salle: el.availability.gyms,
            places: el.availability.places,
            typeDispo: "Disponibilité",

            clientUuid: undefined,
            nom: undefined,
          })
        }

        for (let el of JSON.parse(JSON.stringify(res)).unAvailabilities) {
          console.log(el.staticDate)
          console.log(el.staticDate == null)
          console.log(el.staticDate)
          if (el.staticDate != null) {
            this.addEvent({
              //DONNÉES FONCTIONNELLES
              color: 'grey',
              start: (new Date(el.staticDate.slice(0, 10) + el.startAt.slice(10, 25))),
              end: (new Date(el.staticDate.slice(0, 10) + el.endAt.slice(10, 25))),
              //daysOfWeek: [ weekDays.indexOf(el.day) ],

              //DONNÉES INFORMATIVES
              id: el.availability.uuid,
              uuid: el.availability.uuid,
              title: "Indisponibilité",
              description: 'Indisponibilité',
              day: el.day,
              typeDispo: "Indisponibilité",

              clientUuid: undefined,
            })
          }
          else {
            this.addEvent({
              //DONNÉES FONCTIONNELLES
              startTime: el.startAt.slice(11, 16),
              endTime: el.endAt.slice(11, 16),
              startRecur: (el.staticDate == null) ? (new Date()) : (null),
              color: 'grey',
              daysOfWeek: [weekDays.indexOf(el.day)],

              //DONNÉES INFORMATIVES
              id: el.availability.uuid,
              startHour: (el.startAt.slice(11, 13) + 'h' + el.startAt.slice(14, 16)),
              endHour: (el.endAt.slice(11, 13) + 'h' + el.endAt.slice(14, 16)),
              uuid: el.availability.uuid,
              title: "Indisponibilité",
              description: 'Indisponibilité',
              day: el.day,
              typeDispo: "Indisponibilité",

              clientUuid: undefined,
            })
          }
        }

        for (let el of JSON.parse(JSON.stringify(res)).lessons) {
          this.addEvent({
            //DONNÉES FONCTIONNELLES
            start: (new Date(el.startAt)),
            end: (new Date(el.endAt)),
            color: 'purple',

            //DONNÉES INFORMATIVES
            type: el.type,
            id: el.uuid,
            /*startHour: (el.startAt.slice(11,13) + 'h' + el.startAt.slice(14,16)),
            endHour: (el.endAt.slice(11,13) + 'h' + el.endAt.slice(14,16)),*/
            uuid: el.uuid,
            title: "Cours",
            description: 'Cours',
            typeDispo: "Cours",

            clientUuid: undefined,
          })
        }

        //EXEMPLE DE COURS
        const dateCours = new Date('2022-04-10T08:24:00')
        const dateFinCours = new Date('2022-04-10T09:24:00')
        this.addEvent({
          //DONNÉES FONCTIONNELLES
          start: dateCours,
          end: dateFinCours,
          color: 'purple',

          //daysOfWeek: [ new Date().getDate() ],

          //DONNÉES INFORMATIVES
          startHour: ((dateCours.getHours() - 1) + 'h' + (dateCours.getMinutes())),
          endHour: ((dateFinCours.getHours() - 1) + 'h' + (dateFinCours.getMinutes())),
          uuid: '0',
          title: "Reservé",
          description: 'Description',
          day: dateCours.getDate(),
          categorie: 'Classique',
          tempsBloque: 15,
          salle: undefined,
          places: 'À domicile',
          typeDispo: "Cours",

          id: 'uuid',
          clientUuid: '0',
          nom: 'Nom',
        })

        this.INITIAL_EVENTS = this.INITIAL_EVENTS.slice();
        this.calendarOptions.events = this.INITIAL_EVENTS;
        this.calendarOptions.events = this.calendarOptions.events.slice();
      },
      err => {
        console.log(err)
      }
    )
  }

  isToday: boolean;
  calendar = {
    mode: 'week' as CalendarMode,
    step: 30 as Step,
    currentDate: new Date(),
    dateFormatter: {
      formatWeekViewHourColumn: function (date: Date) {
        return date.getUTCHours() + 1 + 'h';
      }
    }
  };

  currentDate = this.calendar.currentDate;

  // scrollUp() {
  //     document.getElementById("anchor").scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //         inline: "nearest"
  //     });
  // }

  delete(event) {
    console.log(this.currentEvent)
    console.log(this.INITIAL_EVENTS)

    this.coachService.deleteAvailabilities(this.currentEvent.extendedProps.uuid).subscribe(
      res => {
        console.log(res)
        this.displayPopup = 0;
        this.reloadEvents();
      },
      err => {
        console.log(err)
      }
    )
    console.log(this.calendarOptions.events)
    console.log(event)
    console.log((this.INITIAL_EVENTS).findIndex(el => el.uuid == event.extendedProps.uuid))
  }

  cancelLesson(event) {
    console.log(this.currentEvent)
    this.lessonService.cancelLesson(this.currentEvent.extendedProps.uuid).subscribe(
      res => {
        console.log(res)
        this.displayPopup = 0;
        this.reloadEvents();
      },
      err => {
        console.log(err)
      }
    )
    /*const index = this.INITIAL_EVENTS.findIndex(el => el.uuid == event.extendedProps.uuid);
    if (index > -1) {
      this.INITIAL_EVENTS.splice(index, 1);
      console.log(this.calendarOptions.events)
    }*/
  }

  infosClient(uuid: string) {
    this.router.navigate(['/m-coach-clients-second/' + uuid]);
  }

  /*slideNext() {
      this.first_time = false;
      this.myCalendar.slideNext();
  }
  slidePrev() {
      this.first_time = false;
      this.myCalendar.slidePrev();
  }

  onViewTitleChanged(title) {
      this.viewTitle = title;
  }

  today() {
      this.calendar.currentDate = new Date();
  }

  onTimeSelected(ev) {
      console.log('Selected time: ' + ev.selectedTime + ', hasEvents: ' +
          (ev.events !== undefined && ev.events.length !== 0) + ', disabled: ' + ev.disabled);
          var d = new Date(ev.selectedTime);
          var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
      //return new Date(d.setDate(diff));
      this.currentDate = new Date(d.setDate(diff));//ev.selectedTime
      if (this.first_time && !(ev.events !== undefined && ev.events.length !== 0)) {
          console.log(ev.selectedTime.toISOString().slice(0, 12) + (parseInt(ev.selectedTime.toISOString()[12]) + 1).toString() + ev.selectedTime.toISOString().slice(13, 16))
          console.log(new Date().toISOString().slice(0, 16))
          this.begin_time = ev.selectedTime.toISOString().slice(0, 12) + (parseInt(ev.selectedTime.toISOString()[12]) + 1).toString() + ev.selectedTime.toISOString().slice(13, 16)
          this.displayPopup = 3;
      }
      this.first_time = true;
      console.log('vvv')
  }

  onCurrentDateChanged(event:Date) {
      var today = new Date();
      today.setHours(0, 0, 0, 0);
      event.setHours(0, 0, 0, 0);
      this.isToday = today.getTime() === event.getTime();
      this.first_time = false;
  }

 
  onRangeChanged(ev) {
      console.log('range changed: startTime: ' + ev.startTime + ', endTime: ' + ev.endTime);
  }

  markDisabled = (date:Date) => {
      var current = new Date();
      current.setHours(0, 0, 0);
      return date < current;
  };*/

  addUnavailability() {
    console.log(this.unDispoForm.value.start)
    console.log(this.unDispoForm.value.end)
    console.log(this.unDispoForm.value.repetition)
    console.log(new Date(this.unDispoForm.value.start))
    let weekdays = ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"]

    if (this.unDispoForm.value.repetition == 1) {
      let availabilityDays = [];
      for (let el of weekdays) {
        availabilityDays.push(
          {
            startAt: this.unDispoForm.value.start.substring(11, 16),
            endAt: this.unDispoForm.value.end.substring(11, 16),
            currentState: 'unavailable',
            day: el
          }
        )
      }

      const body = {
        coach: this.security.user.profile.coach.uuid,
        availabilityDays: availabilityDays
      }
      console.log(body)

      this.coachService.postAvailabilities(body).subscribe(
        res => {
          console.log(res);
          this.displayPopup = 0;
          this.reloadEvents();
        },
        err => {
          console.log(err)
        }
      )
    }
    else {
      const body = {
        coach: this.security.user.profile.coach.uuid,
        availabilityDays: [{
          startAt: this.unDispoForm.value.start.substring(11, 16),
          endAt: this.unDispoForm.value.end.substring(11, 16),
          currentState: 'unavailable',
          staticDate: null,
          day: weekdays[new Date(this.unDispoForm.value.start).getDay()]
        }]
      }
      if (this.unDispoForm.value.repetition == 0) {
        body.availabilityDays[0].staticDate = new Date(this.unDispoForm.value.start).toISOString();
      }
      console.log(body)

      this.coachService.postAvailabilities(body).subscribe(
        res => {
          console.log(res);
          this.displayPopup = 0;
          this.reloadEvents();
        },
        err => {
          console.log(err)
        }
      )
    }


  }
}