import { Injectable } from '@angular/core';
import { FormGroup } from "@angular/forms";
import { ProfileService } from './api/profile.service';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor(
    private profileService: ProfileService
  ) { }

  setFormValues(form: FormGroup, json) {
    Object.keys(json).forEach(key => {
      const formControl = form.get(key);
      if (formControl) {
        if (formControl instanceof FormGroup) {
          this.setFormValues(formControl, json[key]);
        } else {
          formControl.setValue(json[key]);
        }
      }
    })
  }

  formErrors(form: FormGroup, errors) {
    errors = errors.children;
    Object.keys(errors).forEach(key => {
      const formControl = form.get(key);
      if (formControl) {
        if (errors[key]['children'] && formControl instanceof FormGroup) {
          this.formErrors(formControl, errors[key]);
        } else if (errors[key]['errors']) {
          formControl.setErrors({serverErrors: errors[key]['errors']});
        }
      }
    })
  }


}
