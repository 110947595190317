import { Component, OnInit } from '@angular/core';
import { article1, article2, article3, article4, article5 } from './articles';
@Component({
  selector: 'app-faq-user',
  templateUrl: './faq-user.component.html',
  styleUrls: ['./faq-user.component.scss'],
})
export class FaqUserComponent implements OnInit {
  //selectedCategory = 0;
  categories = [
    "LE CONCEPT",
    "LES TARIFS & COM",
    "LE PROFIL",
    "LES FONCTIONNALITÉS",
    "COACH’S LIFE"
  ]
  articles = [
    article1,
    article2,
    article3,
    article4,
    article5
  ]
  newArticles =[]
  links = [
    'https://www.servicesalapersonne.gouv.fr/actualites/travailler-dans-sap/metiers-des-sap/l-activite-de-coach-sportif'
  ]
  link=0;

  items = ['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5'];
  expandedIndex = 0;
  changeText(cat) {//selectedCategory = categories.indexOf(category)
    document.getElementById("text").innerHTML = this.articles[this.categories.indexOf(cat)];
  }
  constructor() { }

  ngOnInit() {
    for (var i = 0; i < this.articles.length; i++) {
      console.log(i)
      this.articles[i] = this.articles[i].replace(/\n/g, "<br />");
      this.articles[i] = this.articles[i].replace(/<u>/g, "<u>");//MAUVAISE IDEE
      //A REVOIR
      this.articles[i] = this.articles[i].replace(/<\/u>/g, "</u>");
      this.articles[i] = this.articles[i].replace(/<b>/g, "<b>");
      this.articles[i] = this.articles[i].replace(/<\/b>/g, "</b>");
      this.articles[i] = this.articles[i].replace(/<i>/g, "<i>");
      this.articles[i] = this.articles[i].replace(/<\/i>/g, "</i>");
      

      //POLICE
      this.articles[i] = this.articles[i].replace(/<span class="big_text">/g, "<span style='font-size: 50px;'>");
      this.articles[i] = this.articles[i].replace(/<span class="red_text">/g, "<span style='color:red;'>");
      this.articles[i] = this.articles[i].replace(/<\/span>/g, "</span>");
      this.articles[i] = this.articles[i].replace(/<ul>/g, "<ul style='text-align: left;'>");
      this.articles[i] = this.articles[i].replace(/<\/ul>/g, "</ul>");
      this.articles[i] = this.articles[i].replace(/<li>/g, "<li>");
      this.articles[i] = this.articles[i].replace(/<\/li>/g, "</li>");
      for (var j = 0; j < this.links.length; j++) {
        const regex = new RegExp(`<a href=link${j}>`,'g');
        this.articles[i] = this.articles[i].replace(regex, "<a href='" + this.links[j] + "'>");
      }
      this.articles[i] = this.articles[i].replace(/<\/a>/g, "</a>");
      this.newArticles.push(this.articles[i])
    }
    document.getElementById("text").innerHTML = this.articles[0];
  }

}
