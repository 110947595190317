import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DescriptionComponent } from './web/coach/form/description/description.component';
import { DoneComponent } from './web/coach/form/done/done.component';
import { EmailComponent } from './web/coach/form/email/email.component';
import { FieldsComponent } from './web/coach/form/fields/fields.component';
import { GymComponent } from './web/coach/form/gym/gym.component';
import { ProfileComponent } from './web/coach/form/profile/profile.component';
import { StatusBisComponent } from './web/coach/form/status-bis/status-bis.component';
import { StatusComponent } from './web/coach/form/status/status.component';
import { AgendaComponent } from './web/coach/home/dashboard/agenda/agenda.component';
import { CreateBootcampComponent } from './web/coach/home/dashboard/create-bootcamp/create-bootcamp.component';
import { DashboardComponent } from './web/coach/home/dashboard/dashboard.component';
import { InfoBankComponent } from './web/coach/home/dashboard/info-bank/info-bank.component';
import { InfoPersoComponent } from './web/coach/home/dashboard/info-perso/info-perso.component';
import { InvoicesComponent } from './web/coach/home/dashboard/invoices/invoices.component';
import { MyProfileComponent } from './web/coach/home/dashboard/my-profile/my-profile.component';
import { StatisticsComponent } from './web/coach/home/dashboard/statistics/statistics.component';
import { StatusProComponent } from './web/coach/home/dashboard/status-pro/status-pro.component';
import { LandingPageComponent } from './web/coach/landing-page/landing-page.component';
import { HomeUserComponent } from './web/user/home-user/home-user.component';
import { FaqUserComponent } from './web/user/faq-user/faq-user.component';
import { AuthGuard } from './services/auth/auth.guard';


import { WLoginComponent } from './web/w-login/w-login.component';
import { MessagerieComponent } from './web/coach/home/dashboard/messagerie/messagerie.component';
import { CgvCguComponent } from './web/others/cgv-cgu/cgv-cgu.component';
import { MentionsLegalesComponent } from './web/others/mentions-legales/mentions-legales.component';
import { BlogComponent } from './web/others/blog/blog.component';
import { ChatComponent } from './web/coach/home/dashboard/chat/chat.component';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: '',
    // redirectTo: 'm-user-login',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./web/coach/home/dashboard/dashboard-routing.module')
  },
  { path: 'coach-landing-page', component: LandingPageComponent },
  { path: 'coach-inscription-email', component: EmailComponent },
  {
    path: 'coach-inscription-profile', component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'coach-inscription-status', component: StatusComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'coach-inscription-status-bis', component: StatusBisComponent,
    canActivate: [AuthGuard]
  },
  { path: 'coach-inscription-done', component: DoneComponent },

  // Old Inscription Pages
  { path: 'coach-inscription-fields', component: FieldsComponent },
  { path: 'coach-inscription-description', component: DescriptionComponent },
  { path: 'coach-inscription-gym', component: GymComponent },

  // Login Web
  { path: 'login', component: WLoginComponent },

  // Dashboard
  { path: 'coach-home-dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'coach-home-info-perso', component: InfoPersoComponent, canActivate: [AuthGuard] },
  { path: 'coach-home-info-bank', component: InfoBankComponent, canActivate: [AuthGuard] },
  { path: 'coach-home-status-pro', component: StatusProComponent, canActivate: [AuthGuard] },
  { path: 'coach-home-profil', component: MyProfileComponent, canActivate: [AuthGuard] },
  { path: 'coach-home-create-bootcamp', component: CreateBootcampComponent, canActivate: [AuthGuard] },
  { path: 'coach-home-agenda', component: AgendaComponent, canActivate: [AuthGuard] },
  { path: 'coach-home-messages', component: MessagerieComponent, canActivate: [AuthGuard] },
  { path: 'coach-home-invoices', component: InvoicesComponent, canActivate: [AuthGuard] },
  { path: 'coach-home-statistics', component: StatisticsComponent, canActivate: [AuthGuard] },
  { path: 'coach-home-chat', component: ChatComponent, canActivate: [AuthGuard] },


  // Autres
  { path: 'faq', component: FaqUserComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'cgv-cgu', component: CgvCguComponent },
  { path: 'mentions-legales', component: MentionsLegalesComponent },

  // USER
  { path: 'user-home', component: HomeUserComponent },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
