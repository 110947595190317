import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { ProfileService } from 'src/app/services/api/profile.service';
import { SecurityService } from 'src/app/services/security.service';
import { FormService } from 'src/app/services/form.service';
import { finalize } from 'rxjs/operators';
import { CoachService } from 'src/app/services/api/coach.service';
import * as moment from 'moment'
import { validDate } from 'src/app/shared/valid-date.validator';
import { MatDialog } from '@angular/material/dialog';
import { ModalCgcComponent } from '../modal/modal-cgc/modal-cgc.component';
import { UserService } from 'src/app/services/api/user.service';
import { GouvService } from 'src/app/services/api/gouv.service';
import { GoogleApiService } from 'src/app/services/google-api.service';
import { ModalMentionsLegalesComponent } from '../modal/modal-mentions-legales/modal-mentions-legales.component';
import { ModalCgvComponent } from '../modal/modal-cgv/modal-cgv.component';
import { ModalCguComponent } from '../modal/modal-cgu/modal-cgu.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})

export class ProfileComponent implements OnInit {
  public form: FormGroup;
  private loader: HTMLIonLoadingElement;

  //
  apaProfile = false;

  // Files variables
  diplomaFile: File | null = null;
  APADiplomaFile: File | null = null;

  // Date
  years: number[] = [];
  days: any[] = [];

  // Error Messages
  errorMsgDate = '';

  // Regions
  regions:string[] = [];

  // Pattern
  // phonePattern = "(0|\\+[1-9]{2}|00[1-9]{2})[1-9][0-9]{8-12}"
  phonePattern = `^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$`  

  //
  userInfos
  coachInfos

  //
  hasADiploma = false;
  hasApaDiploma = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private security: SecurityService,
    private userService: UserService,
    private profileService: ProfileService,
    private coachService: CoachService,
    private loadingController: LoadingController,
    public dialog: MatDialog,
    private gouvService: GouvService,
    private googleApi: GoogleApiService
  ) { }

  

  async ngOnInit() {

    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.pattern(this.phonePattern)]],
      birthDate: this.formBuilder.group({
        birthDay: ['', [Validators.required]],
        birthMonth: ['', [Validators.required]],
        birthYear: ['', [Validators.required]],
      }),
      gender: ['', [Validators.required]],
      professionalCardNumber: ['', [Validators.required, Validators.minLength(11), Validators.maxLength(11)]],

      diploma: [''],
      APADiploma: [{value: '', disabled: true }],

      address: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: ['', [Validators.required]],
      region: ['', [Validators.required]],

      agree: [null , [Validators.requiredTrue]],
      agreeCG: [null, [Validators.requiredTrue]],
      agreeApa: ['']
    });

    // Conditionnnal Validation for APA File
    this.form.get('agreeApa').valueChanges
      .subscribe(checkedValue => {
        const APADiploma = this.form.get('APADiploma')
        if (checkedValue) {
          APADiploma.setValidators(Validators.required)
        } else {
          APADiploma.clearValidators()
        }
        APADiploma.updateValueAndValidity();
      })

    // Infos
    this.userService.getCurrent().subscribe(res=> {
      console.log('user')
      this.security.user = res
      this.userInfos = res
      console.log(this.userInfos)
      if (this.userInfos.profile !== null) {
        this.form.patchValue({
          address: this.userInfos.profile.address.address,
          postalCode: this.userInfos.profile.address.postalCode,
          city: this.userInfos.profile.address.city,
          country:  this.userInfos.profile.address.city,
          region: this.userInfos.profile.address.region,
          agree: true,
          agreeCG: true
        })

        this.coachService.get(this.userInfos.profile.coach.uuid).subscribe(res => {
          this.coachInfos = res
          console.log('coach')
          console.log(this.coachInfos)

          this.form.patchValue({
            firstName: this.coachInfos.firstName,
            lastName: this.coachInfos.lastName,
            phone: this.coachInfos.phone,
            gender: this.coachInfos.gender,
            professionalCardNumber: this.coachInfos.professionalCardNumber
          })
          this.form.get(['birthDate']).patchValue({
            birthYear: this.coachInfos.birthDate.slice(0,4),
            birthMonth: this.coachInfos.birthDate.slice(5,7),
            birthDay: this.coachInfos.birthDate.slice(8,10),
          })

          if (this.coachInfos.diploma) {
            console.log('diploma');
            this.hasADiploma = true
          }

          if (this.coachInfos.APADiploma) {
            console.log('Apadiploma');
            this.hasApaDiploma = true;
            this.form.patchValue({
              agreeApa: true
            })
          }


        })
      }
    })

    /*** DATE DISPLAY***/
    // Year
    let date = new Date().getFullYear()
    for (let i = 0; i <= 80; i++) {
      let year = (date - i) - 16
      this.years.push(year)
    }
    // Day
    for (let i = 1; i <= 31; i++) {
      if (i <= 9) {
        this.days.push("0" + i)
      } else {
        this.days.push(i.toString())
      }
    }

    this.gouvService.getGouvRegions().subscribe(res => {
      Object.keys(res).map(index => {
        let region = res[index]
        this.regions.push(region.nom)
      })
    })
  }

  editDiploma() {
    this.hasADiploma = false;
  }

  openCgc() {
    const dialogRef = this.dialog.open(ModalCgcComponent, {
      width: '550px'
    })
  }
  
  openCgv() {
    const dialogRef = this.dialog.open(ModalCgvComponent, {
      width: '550px'
    })
  }

  openCgu() {
    const dialogRef = this.dialog.open(ModalCguComponent, {
      width: '550px'
    })
  }

  onPostalCode(e) {
    let postalCode = e.target.value
    if(postalCode.length === 5) {
      this.gouvService.getGouvCity(postalCode).subscribe(res => {
        this.form.patchValue({
          city: res[0]['nom'],
          
        })
        let idRegion = res[0]['codeRegion']
        this.gouvService.getGouvSpecificRegions(idRegion).subscribe(res => {
          this.form.patchValue({
            region: res['nom']
          })
        })
      })
      this.form.patchValue({
        country: 'France'
      })
    }
  }

  onSelectApa() {
    this.apaProfile = this.apaProfile === false;
    if (this.apaProfile) {
      this.form.controls.APADiploma.enable()
    } else {
      this.form.controls.APADiploma.disable()
    }
  }

  onFileDiplomaSelected(event) {
    this.diplomaFile = <File>event.target.files[0];
  }

  onFileAPADiplomaSelected(event) {
    this.APADiplomaFile = <File>event.target.files[0];
  }

  onDate() {
    let validDate = moment(`${this.form.value.birthYear}-${this.form.value.birthMonth}-${this.form.value.birthDay}`).isValid();
    console.log(validDate)
  }

  openLegal() {
    const dialogRef = this.dialog.open(ModalMentionsLegalesComponent, {
      width: '550px',
      height: '80vh',
      autoFocus: false
    });
  }

  async onSubmit() {
    if (this.form.valid) {
      let birthDateFormatted = `${this.form.controls['birthDate'].value.birthYear}-${this.form.get(['birthDate', 'birthMonth']).value}-${this.form.controls['birthDate'].value.birthDay}`
      let dateValid = moment(birthDateFormatted).isValid()
      if (!dateValid) {
        this.errorMsgDate = "La date est invalide"
        return
      } else {
        this.errorMsgDate = ""
      }

      const bodyProfile = {
        user: this.userInfos.uuid,
        address: {
          city: this.form.value.city,
          address: this.form.value.address,
          postalCode: this.form.value.postalCode,
          country: this.form.value.country,
          region: this.form.value.region,
        }
      }

      const address = this.form.value.address + this.form.value.postalCode + this.form.value.city
      console.log(address)

      console.log(bodyProfile)

      const formData = new FormData();
      formData.append('firstName', this.form.value.firstName)
      formData.append('lastName', this.form.value.lastName)
      formData.append('phone', this.form.value.phone)
      formData.append('birthDate', `${this.form.controls['birthDate'].value.birthYear}-${this.form.get(['birthDate', 'birthMonth']).value}-${this.form.controls['birthDate'].value.birthDay}`)
      formData.append('gender', this.form.value.gender)
      formData.append('professionalCardNumber', this.form.value.professionalCardNumber)
      formData.append('diploma[file]', this.diplomaFile)
      formData.append('APADiploma[file]', this.APADiplomaFile)

      new Response(formData).text().then(console.log)

      let func = null;
      this.loader = await this.loadingController.create({ message: 'Enregistrement des données' })
      await this.loader.present();

      console.log(this.userInfos.profile)
      if (this.userInfos.profile !== null) {
        console.log("Patch")

        func = this.profileService.patch(bodyProfile, this.userInfos.profile.uuid)
          .subscribe(
            profile => {
              this.security.user.profile = profile
              formData.append('profile', profile.uuid)

              /******/
              this.googleApi.getLatLong(address, profile.uuid)
              this.coachService.patch(formData, this.userInfos.profile.coach.uuid).subscribe(
                res => {
                  console.log(res)
                  this.security.user.profile.coach = res

                  this.router.navigate(['/coach-inscription-status']);
                },
                err => {
                  console.log(err)
                }
              )
            }
          )
        this.loader.dismiss()
      } else {
        console.log('Post')
        console.log(bodyProfile)
        func = this.profileService.post(bodyProfile)
          // .pipe(finalize(() => this.loader.dismiss()))

          .subscribe(
            profile => {
              console.log(profile)
              formData.forEach((value,key) => {
                console.log(key+" "+value)
              });

              this.security.user.profile = profile
              formData.append('profile', profile.uuid)
              this.googleApi.getLatLong(address, profile.uuid)

              this.coachService.post(formData).subscribe(
                res => {
                  console.log(res)
                  this.security.user.profile.coach = res

                  this.loader.dismiss()
                  this.router.navigate(['/coach-inscription-status']);
                }
              )
            }
          )
      }
    }
  };

}
