import { Injectable } from '@angular/core';
import { LoginJWT } from 'src/app/models/login-jwt';
import { User } from 'src/app/models/user';
import { ApiService } from './api.service';
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { StorageService } from '../storage.service';
import { ProfileService } from './profile.service';
import { flatMap } from "rxjs/internal/operators";

export interface ResetBody {
  email: string;
  token: string;
  plainPassword: {
    first: string;
    second: string;
  }
}

@Injectable({
  providedIn: 'root'
})

export class UserService {
  constructor(
    private api: ApiService,
    private http: HttpClient,
    private storage: StorageService,
    private profileService: ProfileService
  ) { }

  register(body): Observable<any> {
    return this.http.post(this.api.getUrl('/api/register/coach'), body);
  }

  registerClient(body): Observable<any> {
    return this.http.post(this.api.getUrl('/api/register/client'), body);
  }

  login(body) {
    return this.http.post<LoginJWT>(this.api.getUrl('/api/login'), body);
  }

  requestReset(body: { email: string }): Observable<any> {
    return this.http.post(this.api.getUrl(`/api/reset-password/request`), body);
  }

  reset(body: ResetBody) {
    return this.http.post(this.api.getUrl(`/api/reset-password`), body);
  }

  loginWithGoogle(body) {
    return this.http.post(this.api.getUrl('/api/google'), body);
  }

  loginWithFacebook(body) {
    return this.http.post(this.api.getUrl('/api/facebook'), body);
  }

  facebookLogin(body: { accessToken: string }): Observable<LoginJWT> {
    return this.http.post<LoginJWT>(this.api.getUrl('/api/facebook'), body);
  }

  getCurrent(): Observable<User> {
    return this.http.get<User>(this.api.getUrl('/api/user/current'), this.api.getOptions())
      .pipe(
        flatMap(user =>
          user.profile ?
            this.profileService.get(`${user.profile.uuid}`).pipe(catchError(err => of(null))) :
            of(null),
          (outerValue, innerValue) => {
            if (innerValue !== null) {
              outerValue.profile = innerValue;
            }
            return outerValue
          }),
      );
  }

  getCurrent2(): Observable<User> {
    return this.http.get<User>(this.api.getUrl('/api/user/current'), this.api.getOptions())
  }
}
