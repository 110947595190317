import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
// import { FacebookLogin } from '@capacitor-community/facebook-login';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  goTo(path : string) {
    this.router.navigate([path]);
    this.menu.close();
  }
  
  constructor(
    private router: Router,
    private menu: MenuController,
    private platform: Platform
  ) {
    // this.initializeApp();
  }

  // initializeApp() {
  //   this.platform.ready().then(() => {
  //     FacebookLogin.initialize({appId: '5056005157841269'});
  //   });
  // }
}