import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sponsors-user',
  templateUrl: './sponsors-user.component.html',
  styleUrls: ['./sponsors-user.component.scss'],
})
export class SponsorsUserComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
