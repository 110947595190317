import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class SportCategoryService {

  constructor( 
    private api: ApiService,
    private http: HttpClient
  ) { }

  get() {
    return this.http.get(this.api.getUrl(`/api/sport-categories`), this.api.getOptions());
  }
}
