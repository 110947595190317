import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { Client } from 'src/app/models/client';
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  post(body): Observable<Client> {
    return this.http.post<Client>(this.api.getUrl('/api/client'), body, this.api.getOptions(),);
  }

  getCurrent(): Observable<Client> {
    return this.http.get<Client>(this.api.getUrl('/api/client'), this.api.getOptions(),);
  }

  patch(body, uuid): Observable<Client | null> {
    return this.http.patch<Client>(this.api.getUrl(`/api/client/${uuid}`), body, this.api.getOptions());
  }

  postFavorite(body) {
    return this.http.post(this.api.getUrl(`/api/coach-favors`), body, this.api.getOptions(),);
  }

  getFavorite() {
    return this.http.get(this.api.getUrl(`/api/coach-favors`), this.api.getOptions(),);
  }

  deleteFavorite(body, uuid: string) {
    return this.http.delete(this.api.getUrl(`/api/coach-favors/${uuid}`),/* body,*/ this.api.getOptions(),);
  }

  get(uuid: string): Observable<Client> {
    return this.http.get<Client>(this.api.getUrl(`/api/client/${uuid}`), this.api.getOptions());
  }

  getAllRooms(uuid: string): Observable<Client> {
    return this.http.get<Client>(this.api.getUrl(`/api/room/search-list-user`), this.api.getOptions());
  }

  getLessons(type: string): Observable<Client> {
    return this.http.get<Client>(this.api.getUrl(`/api/client/lessons?type=${type}`), this.api.getOptions());
  }

}