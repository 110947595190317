import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoadingController } from '@ionic/angular';
import { CompanyService } from 'src/app/services/api/company.service';

@Component({
  selector: 'app-new-status',
  templateUrl: './new-status.component.html',
  styleUrls: ['./new-status.component.scss'],
})
export class NewStatusComponent implements OnInit {
  // Form
  public form: FormGroup;

  // Files variables
  KBISfile: File | null = null;

  // Loader
  private loader: HTMLIonLoadingElement;

  constructor(
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
    private companyService: CompanyService,
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      registrationNumber: ['', [Validators.required]],
      type: ['', [Validators.required]],
      name: ['', [Validators.required]],
      KBIS: ['', [Validators.required]],
      IBAN: ['', [Validators.required]],
      BIC: ['', [Validators.required]],
    });

    
  }

  async onSubmit() {
    const formData = new FormData();
    formData.append('name', this.form.value.name)
    formData.append('type', this.form.value.type)
    formData.append('isSAPCertified', '0')
    formData.append('SAPNumber', '')
    formData.append('SAPCertificationObtentionDate', '')
    formData.append('registrationNumber', this.form.value.registrationNumber)
    formData.append('BIC', this.form.value.BIC)
    formData.append('KBIS[file]', this.KBISfile)
    formData.append('IBAN', this.form.value.IBAN)
    // formData.append('coach', this.security.user.profile.coach.uuid)

    let func = null;
    this.loader = await this.loadingController.create({ message: 'Loading' })

    func = this.companyService.post(formData).subscribe(
      res => {
        console.log(res)
        this.loader.dismiss()
      }
    )
  }

  onFileKBISSelected(event) {
    this.KBISfile = <File>event.target.files[0];
    console.log(this.KBISfile)
  }

}
