import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfilePictureService {

  private profilePicture = new BehaviorSubject<string>('')
  currentProfilePicture = this.profilePicture.asObservable()

  constructor() { }

  setProfilePicture(profilePicture: string) {
    this.profilePicture.next(profilePicture)
  }
}
