import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { loadStripe } from '@stripe/stripe-js';
import { CompanyService } from 'src/app/services/api/company.service';
import { SecurityService } from 'src/app/services/security.service';
import { ModalSapComponent } from '../modal/modal-sap/modal-sap.component';
import { ModalStatusComponent } from '../modal/modal-status/modal-status.component';
import { ModalRegisteredComponent } from '../modal/modal-registered/modal-registered.component';
import { ModalMentionsLegalesComponent } from '../modal/modal-mentions-legales/modal-mentions-legales.component';

import { CoachService } from 'src/app/services/api/coach.service';
import { ProfileService } from 'src/app/services/api/profile.service';
import { LoadingController } from '@ionic/angular';
import { FormService } from 'src/app/services/form.service';
import { UserService } from 'src/app/services/api/user.service';
import { FormStorageService } from 'src/app/services/form-storage.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { FormStatus } from 'src/app/models/form-status';
import { CardService } from 'src/app/services/api/card.service';
import { IbanService } from 'src/app/services/api/iban.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  // SAP
  sap = false
  types = [
    { name: "Auto-entrepreneur", value: "self_employed" },
    { name: "EURL", value: "eurl" },
    { name: "SASU", value: "sasu" },
    // { name: "Autre", value: "other" },
  ]

  // Files variables
  KBISfile: File | null = null;

  public form: FormGroup;
  private loader: HTMLIonLoadingElement;

  formPreview$: FormStatus

  // User infos
  coachInfos;
  userInfos;

  //
  ibanIsInvalid;

  // Patterns
  iban = "^([A-Z]{2}[ \-]?[0-9]{2})(?=(?:[ \-]?[A-Z0-9]){9,30}$)((?:[ \-]?[A-Z0-9]{3,5}){2,7})([ \-]?[A-Z0-9]{1,3})?$"
  bic = "([a-zA-Z]{4})([a-zA-Z]{2})(([2-9a-zA-Z]{1})([0-9a-np-zA-NP-Z]{1}))((([0-9a-wy-zA-WY-Z]{1})([0-9a-zA-Z]{2}))|([xX]{3})|)"

  errorMessages = [];


  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private security: SecurityService,
    private companyService: CompanyService,
    private userService: UserService,
    private location: Location,
    public dialog: MatDialog,
    private coachService: CoachService,
    private profileService: ProfileService,
    private loadingController: LoadingController,
    private formService: FormService,
    private formStorageService: FormStorageService,
    private cardService: CardService,
    private ibanService: IbanService

  ) { }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      isSAPCertified: ['', [Validators.required]],
      SAPCertificationObtentionDate: ['', []],
      SAPNumber: ['', []],
      registrationNumber: ['', [Validators.required, Validators.minLength(9)]],
      type: ['self_employed', [Validators.required]],
      name: ['', [Validators.required]],
      KBIS: ['', [Validators.required]],
      IBAN: ['', [Validators.required, Validators.pattern(this.iban)
        // Validators.pattern(
        // this.andorra || this.uae || this.uae || this.albania || this.austria || this.azerbaijan || this.bosnia || this.belgium || this.bulgaria ||
        // this.bahrain || this.brazil || this.belarus || this.switzerland || this.costaRica || this.cyprus || this.czechia || this.germany || this.denmark || this.dominicanRepublic || this.estonia || this.egypt || this.spain || this.finland || this.faroeIslands || this.france || this.faroeIslands || this.france || this.uk || this.georgia || this.gibraltar || this.greenland || this.greece || this.guatemala || this.croatia || this.hungary || this.ireland || this.israel || this.iraq || this.iceland || this.italy || this.jordan || this.kuwait || this.kazakhstan || this.lebanon || this.saintLucia || this.lithuania || this.luxembourg || this.latvia || this.monaco || this.moldova || this.montenegro || this.macedonia || this.mauritania || this.malta || this.mauritius || this.netherlands || this.norway || this.pakistan || this.poland || this.palestine || this.portugal || this.qatar || this.romania || this.serbia || this.saudiArabia || this.seychelles || this.sweden || this.slovenia || this.slovakia || this.sanMarino || this.sanMarino || this.saoTome || this.elSalvador || this.timor || this.tunisia || this.turkey || this.ukraine || this.vatican || this.virginIslands || this.kosovo
        // )
      ]],
      BIC: ['', [Validators.required, Validators.pattern(this.bic)]],
    });

    this.form.get('SAPCertificationObtentionDate').disable()
    this.form.get('SAPNumber').disable()

    // Get user infos
    this.userService.getCurrent().subscribe(res => {
      this.userInfos = res
      this.security.user = res
      console.log('user')
      console.log(this.userInfos)

      this.coachService.get(this.userInfos.profile.coach.uuid).subscribe(res => {
        this.coachInfos = res
        console.log('coach')
        console.log(this.coachInfos)

        if (this.coachInfos.companies.length !== 0) {
          this.companyService.get(this.coachInfos.companies[0].uuid).subscribe((data => {
            console.log(data)
            this.form.patchValue({
              isSAPCertified: (data.isSAPCertified ? '1' : '0'),
              SAPCertificationObtentionDate: data.SAPCertificationObtentionDate.slice(0, 10),
              SAPNumber: data.SAPNumber,
              registrationNumber: data.registrationNumber,
              type: data.type,
              name: data.name,
              IBAN: data.IBAN,
              BIC: data.BIC,
            })

            if (data.isSAPCertified) {
              this.form.get('SAPCertificationObtentionDate').enable()
              this.form.get('SAPNumber').enable()
            }
          }))
        }
      })
    })


    /*** Form values storage when Form is not submitted yet ***/
    // Get the last form values save as an observable
    this.formStorageService.currentFormTwo.subscribe(formTwo => this.formPreview$ = formTwo)

    // Save the last values when user fill form inputs
    this.form.valueChanges.subscribe(value => {
      this.formPreview$ = value
      this.formStorageService.setFormTwo(value)
    })

    // Replace value of the inputs if no key CompanyUuid in the local storage
    if (this.formPreview$) {
      console.log("hello")
      this.form.patchValue({
        isSAPCertified: this.formPreview$.isSAPCertified,
        SAPCertificationObtentionDate: this.formPreview$.SAPCertificationObtentionDate,
        SAPNumber: this.formPreview$.SAPNumber,
        registrationNumber: this.formPreview$.registrationNumber,
        type: this.formPreview$.type,
        name: this.formPreview$.name,
        // KBIS: this.formPreview$.KBIS,
        IBAN: this.formPreview$.IBAN,
        BIC: this.formPreview$.BIC
      })
      if (this.formPreview$.isSAPCertified === '1') {
        console.log('enable inputs')
        this.form.get('SAPCertificationObtentionDate').enable()
        this.form.get('SAPNumber').enable()
      }
    }
    console.log("hello bis")
  }

  formPreview() {
    console.log(this.formPreview$)
  }

  openDialogSap() {
    const dialogRef = this.dialog.open(ModalSapComponent, {
      width: '550px'
    });
  }

  // ibanCheck(e) {
  //   // const iban = (e.target.value).split(" ").join("")
  //   // this.ibanIsInvalid = validateIBAN({
  //   //   value: e.target.value,
  //   // }).ibanInvalid;
  //   // console.log(this.ibanIsInvalid)
  //   if (this.form.get('iban').errors) {
  //     this.ibanIsInvalid = this.form.get('IBAN').errors.ibanInvalid
  //     if (!this.form.get('IBAN').errors.ibanInvalid) {
  //       this.form.get('IBAN').clearValidators()
  //     } else {
  //       this.form.get('IBAN').markAsPristine();
  //       this.form.get('IBAN').markAsUntouched();
  //     }
  //     this.form.get('IBAN').updateValueAndValidity();
  //   } else {
  //     this.ibanIsInvalid = false
  //   }
  //   console.log(this.ibanIsInvalid)
  // }

  openDialog() {
    console.log("1")
    if (this.form.valid) {
      console.log("2")
      if (this.form.value.isSAPCertified === "1") {
        console.log("3")
        const dialogRef = this.dialog.open(ModalStatusComponent, {
          width: '550px',
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result === 'true') {
            this.onSubmit().then(() => {
              this.loader.dismiss().then(() => this.router.navigate(['/coach-inscription-status-bis']))
            })
          }
          if (result === 'false') {
            console.log('hello 1000')
            const dialogRef = this.dialog.open(ModalRegisteredComponent, {
              width: '550px',
            });
            dialogRef.afterClosed().subscribe(result => {
              if (result === 'true') {
                this.onSubmit().then(() => {
                  // this.router.navigate(['/coach-inscription-done'])
                  //----------------
                  const that = this;
                  this.handleForm();
                  //----------------
                })
              }
            });
          }
        });
      } else {
        console.log('hello 1000')
        const dialogRef = this.dialog.open(ModalRegisteredComponent, {
          width: '550px',
        });
        dialogRef.afterClosed().subscribe(result => {
          console.log('Result', result)
          if (result === 'true') {
            this.onSubmit().then((res) => {
              console.log(res)
              // this.router.navigate(['/coach-inscription-done'])
              if (res) {
                const that = this;
                this.handleForm();
              } else {
                return
              }
            })
          }
        });
      }
    }
  }

  openLegal() {
    const dialogRef = this.dialog.open(ModalMentionsLegalesComponent, {
      width: '550px',
      height: '80vh',
      autoFocus: false
    });
  }

  async handleForm() {

    const stripe = await loadStripe(environment.stripe.key);

    console.log('ACCOUNT RESULT')
    const accountResult = await stripe.createToken('account', {
      business_type: 'company',
      company: {
        'name': this.coachInfos.firstName + ' ' + this.coachInfos.lastName,
        'phone': '+33' + this.coachInfos.phone.slice(1),
        'directors_provided': true,
        'executives_provided': true,
        'owners_provided': true,
        "tax_id": "000000000",
        "address": {
          "city": this.coachInfos.profile.address.city,
          "country": "FR",
          "postal_code": this.coachInfos.profile.address.postalCode.toString(),
          "line1": this.coachInfos.profile.address.address
        },
      },
      tos_shown_and_accepted: true,
    });

    console.log("PERSON RESULT")
    const personResult = await stripe.createToken('person', {
      'email': this.userInfos.email,
      'phone': '+33' + this.coachInfos.phone.slice(1),
      "first_name": this.coachInfos.firstName,
      "last_name": this.coachInfos.lastName,
      "address": {
        "city": this.coachInfos.profile.address.city,
        "country": "FR",
        "postal_code": this.coachInfos.profile.address.postalCode.toString(),
        "line1": this.coachInfos.profile.address.address
      },
      "dob": {
        "day": parseInt(this.coachInfos.birthDate.slice(8, 10)),
        "month": parseInt(this.coachInfos.birthDate.slice(5, 7)),
        "year": parseInt(this.coachInfos.birthDate.slice(0, 4)),
      },
      "relationship": {
        "director": true,
        "executive": true,
        "owner": true,
        "representative": true,
        "title": "CEO"
      }
    });
    console.log(accountResult)
    console.log(personResult)
    if (accountResult.token && personResult.token) {
      const body = {
        user: this.userInfos.uuid,
        token_account: accountResult.token.id,
        token_person: personResult.token.id
      }
      console.log("body")
      console.log(body)

      this.cardService.createStripeAccount(body).subscribe(
        res => {
          console.log(res)
          this.verifUser2(res)
        },
        err => {
          console.log(err)
        }
      )
    }
  }

  async verifUser2(event): Promise<any> {
    const stripe = await loadStripe(environment.stripe.key);

    const body = {
      refresh_url: "https://dev.coachs-france.com/",
      return_url: "https://dev.coachs-france.com/coach-inscription-done"
    }

    this.cardService.validation(body).subscribe(
      res => {
        console.log(res)
        let url = res.toString()
        let uri = encodeURI(url)
        // console.log(url)


        this.coachInfos = res
        console.log(this.coachInfos)

        console.log(res)
        const bodyIban = {
          iban: 'FR1420041010050500013M02606',
          // TODO : décommenter en bas quand on passe en prod, en haut c'est le IBAN test pour Stripe
          // iban: this.form.value.IBAN,
          currency: "eur",
          titulaire: this.form.value.name,
          address: {
            city: this.userInfos.profile.address.city,
            address: this.userInfos.profile.address.address,
            postalCode: this.userInfos.profile.address.postalCode,
            country: this.userInfos.profile.address.country,
            region: this.userInfos.profile.address.region
          }
        }
        console.log(bodyIban)
        this.ibanService.createNewIban(bodyIban).subscribe(res => {
          console.log(res)
          this.loader.dismiss()
          // const urlString = url.toString()
          window.location.assign(uri)
        })

      },
      err => {
        console.log(err)
      }
    )
  }

  onFileKBISSelected(event) {
    this.KBISfile = <File>event.target.files[0];
  }

  // async onSubmit(): Promise<boolean> {
  //   let resolver = false;
  //   const promise = new Promise<boolean>(resolve => {
  //     resolver = resolve;
  //   });

  //   console.log('hello 2000')
  //   const body = {
  //     isSAPCertified: this.form.value.isSAPCertified,
  //     SAPCertificationObtentionDate: this.form.value.SAPCertificationObtentionDate,
  //     registrationNumber: this.form.value.registrationNumber,
  //     type: this.form.value.type,
  //     name: this.form.value.name,
  //     KBIS: this.form.value.KBIS,
  //     IBAN: this.form.value.IBAN,
  //     BIC: this.form.value.BIC,
  //   };

  //   const formData = new FormData();
  //   formData.append('name', this.form.value.name)
  //   formData.append('type', this.form.value.type)
  //   formData.append('isSAPCertified', this.form.value.isSAPCertified)
  //   formData.append('SAPNumber', (this.form.value.SAPNumber ? this.form.value.SAPNumber : null))
  //   formData.append('SAPCertificationObtentionDate', (this.form.value.SAPCertificationObtentionDate ? this.form.value.SAPCertificationObtentionDate : "2000-01-01"))
  //   formData.append('registrationNumber', this.form.value.registrationNumber)
  //   formData.append('BIC', this.form.value.BIC)
  //   formData.append('KBIS[file]', this.KBISfile)
  //   formData.append('IBAN', this.form.value.IBAN)
  //   formData.append('coach', this.userInfos.profile.coach.uuid)


  //   let func = null;
  //   this.loader = await this.loadingController.create({ message: 'Enregistrement des données' })
  //   this.loader.present()
  //   console.log('hello 4000')
  //   // console.log(this.security.user.profile.coach.company)
  //   if (this.coachInfos.companies.length !== 0) {
  //     alert('Inside Form Patch')
  //     console.log('Patch')
  //     func = this.companyService.patch(formData, this.coachInfos.companies[0].uuid).subscribe(
  //       res => {
  //         console.log(res);
  //         resolver(true);
  //       },
  //       err => {
  //         console.log(err);
  //         alert('Erreur Patch');
  //         resolver('error Patch');
  //       }
  //     );
  //   } else {
  //     console.log('Post')
  //     alert('Inside Form Post')
  //     func = this.companyService.post(formData).subscribe(
  //       res => {
  //         console.log(res);
  //         resolver('Post OK');
  //       },

  //       err => {
  //         console.log(err);
  //         alert('Erreur Post');
  //         return;
  //         resolver('error Post');
  //       }
  //     )
  //   }
  //   return promise
  // }

  checkForErrors(obj, isInitialCall = true) {
    // Clear the errorMessages array only on the initial call
    if (isInitialCall) {
      this.errorMessages = [];
    }

    for (let key in obj) {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        if (obj[key].hasOwnProperty('errors')) {
          // Assume errors property is an array and concatenate its contents to the errorMessages array
          this.errorMessages = this.errorMessages.concat(obj[key].errors);
        } else {
          // Recurse into nested objects, marking subsequent calls as recursive (not initial)
          this.checkForErrors(obj[key], false);
        }
      }
    }
  }

  async onSubmit(): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      // Removed the `resolver` as it's no longer needed.


      console.log('hello 2000')
      const body = {
        isSAPCertified: this.form.value.isSAPCertified,
        SAPCertificationObtentionDate: this.form.value.SAPCertificationObtentionDate,
        registrationNumber: this.form.value.registrationNumber,
        type: this.form.value.type,
        name: this.form.value.name,
        KBIS: this.form.value.KBIS,
        IBAN: this.form.value.IBAN,
        BIC: this.form.value.BIC,
      };

      const formData = new FormData();
      formData.append('name', this.form.value.name)
      formData.append('type', this.form.value.type)
      formData.append('isSAPCertified', this.form.value.isSAPCertified)
      formData.append('SAPNumber', (this.form.value.SAPNumber ? this.form.value.SAPNumber : null))
      formData.append('SAPCertificationObtentionDate', (this.form.value.SAPCertificationObtentionDate ? this.form.value.SAPCertificationObtentionDate : "2000-01-01"))
      formData.append('registrationNumber', this.form.value.registrationNumber)
      formData.append('BIC', this.form.value.BIC)
      formData.append('KBIS[file]', this.KBISfile)
      formData.append('IBAN', this.form.value.IBAN)
      formData.append('coach', this.userInfos.profile.coach.uuid)

      this.loader = await this.loadingController.create({ message: 'Enregistrement des données' });
      await this.loader.present();
      console.log('hello 4000');

      const handler = (res: any) => {
        console.log(res);
        this.loader.dismiss();
        resolve(true);
      };

      const errorHandler = (err: any) => {
        console.log(err);
        console.log(err.error.errors.children)
        this.checkForErrors(err.error.errors.children)
        console.log(this.errorMessages);
        this.loader.dismiss();
        resolve(false); // Use resolve instead of reject to fulfill the Promise<boolean> type.
      };

      if (this.coachInfos.companies.length !== 0) {
        console.log('Patch');
        this.companyService.patch(formData, this.coachInfos.companies[0].uuid).subscribe(handler, errorHandler);
      } else {
        console.log('Post');
        this.companyService.post(formData).subscribe(handler, errorHandler);
      }
    });
  }

  onPrevious(): void {
    this.location.back();
  }

  onSapYes(e) {
    this.sap = true
    this.form.get('SAPCertificationObtentionDate').enable()
    this.form.get('SAPCertificationObtentionDate').setValidators([Validators.required])
    this.form.get('SAPCertificationObtentionDate').updateValueAndValidity()
    this.form.get('SAPNumber').enable()
    this.form.get('SAPNumber').setValidators([Validators.required])
    this.form.get('SAPNumber').updateValueAndValidity()
  }

  onSapNo(e) {
    this.sap = false
    this.form.get('SAPCertificationObtentionDate').disable()
    this.form.get('SAPCertificationObtentionDate').updateValueAndValidity()
    this.form.get('SAPNumber').disable()
    this.form.get('SAPNumber').updateValueAndValidity()
  }


  // Form values storage when Form is not submitted yet
  nameFormTwo(e) {
    console.log(e.target.value)
  }

}


// andorra = "^AD\d{10}[A-Z0-9]{12}$"
// uae = "^AE\d{21}$"
// albania = "^AL\d{10}[A-Z0-9]{16}$"
// austria = "^AT\d{18}$"
// azerbaijan = "^AZ\d{2}[A-Z]{4}[A-Z0-9]{20}$"
// bosnia = "^BA\d{18}$"
// belgium = "^BE\d{14}$"
// bulgaria = "^BG\d{2}[A-Z]{4}\d{6}[A-Z0-9]{8}$"
// bahrain = "^BH\d{2}[A-Z]{4}[A-Z0-9]{14}$"
// brazil = "^BR\d{25}[A-Z]{1}[A-Z0-9]{1}$"
// belarus = "^BY\d{2}[A-Z0-9]{4}\d{4}[A-Z0-9]{16}$"
// switzerland = "^CH\d{7}[A-Z0-9]{12}$"
// costaRica = "^CR\d{20}$"
// cyprus = "^CY\d{10}[A-Z0-9]{16}$"
// czechia = "^CZ\d{22}$"
// germany = "^DE\d{20}$"
// denmark = "^DK\d{16}$"
// dominicanRepublic = "^DO\d{2}[A-Z0-9]{4}\d{20}$"
// estonia = "^EE\d{18}$"
// egypt = "^EG\d{27}$"
// spain = "^ES\d{22}$"
// finland = "^FI\d{16}$"
// faroeIslands = "^FO\d{16}$"
// france = "^([A-Z]{2}[ '+'\\'+'-]?[0-9]{2})(?=(?:[ '+'\\'+'-]?[A-Z0-9]){9,30}$)((?:[ '+'\\'+'-]?[A-Z0-9]{3,5}){2,7})([ '+'\\'+'-]?[A-Z0-9]{1,3})?$"
// uk = "^GB\d{2}[A-Z]{4}\d{14}$"
// georgia = "^GE\d{2}[A-Z]{2}\d{16}$"
// gibraltar = "^GI\d{2}[A-Z]{4}[A-Z0-9]{15}$"
// greenland = "^GL\d{16}$"
// greece = "^GR\d{9}[A-Z0-9]{16}$"
// guatemala = "^GT\d{2}[A-Z0-9]{24}$"
// croatia = "^HR\d{19}$"
// hungary = "^HU\d{26}$"
// ireland = "^IE\d{2}[A-Z]{4}\d{14}$"
// israel = "^IL\d{21}$"
// iraq = "^IQ\d{2}[A-Z]{4}\d{15}$"
// iceland = "^IS\d{24}$"
// italy = "^IT\d{2}[A-Z]{1}\d{10}[A-Z0-9]{12}$"
// jordan = "^JO\d{2}[A-Z]{4}\d{4}[A-Z0-9]{18}$"
// kuwait = "^KW\d{2}[A-Z]{4}[A-Z0-9]{22}$"
// kazakhstan = "^KZ\d{5}[A-Z0-9]{13}$"
// lebanon = "^LB\d{6}[A-Z0-9]{20}$"
// saintLucia = "^LC\d{2}[A-Z]{4}[A-Z0-9]{24}$"
// liechtenstein = "^LI\d{7}[A-Z0-9]{12}$"
// lithuania = "^LT\d{18}$"
// luxembourg = "^LU\d{5}[A-Z0-9]{13}$"
// latvia = "^LV\d{2}[A-Z]{4}[A-Z0-9]{13}$"
// monaco = "^MC\d{12}[A-Z0-9]{11}\d{2}$"
// moldova = "^MD\d{2}[A-Z0-9]{20}$"
// montenegro = "^MD\d{2}[A-Z0-9]{20}$"
// macedonia = "^MK\d{5}[A-Z0-9]{10}\d{2}$"
// mauritania = "^MR\d{25}$"
// malta = "^MT\d{2}[A-Z]{4}\d{5}[A-Z0-9]{18}$"
// mauritius = "^MU\d{2}[A-Z]{4}\d{19}[A-Z]{3}$"
// netherlands = "^NL\d{2}[A-Z]{4}\d{10}$"
// norway = "^NO\d{13}$"
// pakistan = "^PK\d{2}[A-Z]{4}[A-Z0-9]{16}$"
// poland = "^PL\d{26}$"
// palestine = "^PS\d{2}[A-Z]{4}[A-Z0-9]{21}$"
// portugal = "^PT\d{23}$"
// qatar = "^QA\d{2}[A-Z]{4}[A-Z0-9]{21}$"
// romania = "^RO\d{2}[A-Z]{4}[A-Z0-9]{16}$"
// serbia = "^RS\d{20}$"
// saudiArabia = "^SA\d{4}[A-Z0-9]{18}$"
// seychelles = "^SC\d{2}[A-Z]{4}\d{20}[A-Z]{3}$"
// sweden = "^SE\d{22}$"
// slovenia = "^SI\d{17}$"
// slovakia = "^SK\d{22}$"
// sanMarino = "^SM\d{2}[A-Z]{1}\d{10}[A-Z0-9]{12}$"
// saoTome = "^ST\d{23}$"
// elSalvador = "^SV\d{2}[A-Z]{4}\d{20}$"
// timor = "^TL\d{21}$"
// tunisia = "^TN\d{22}$"
// turkey = "^TR\d{8}[A-Z0-9]{16}$"
// ukraine = "^UA\d{8}[A-Z0-9]{19}$"
// vatican = "^VA\d{20}$"
// virginIslands = "^VG\d{2}[A-Z]{4}\d{16}$"
// kosovo = "^XK\d{18}$"