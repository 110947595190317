import { Injectable } from '@angular/core';
import { StorageService } from "./storage.service";
import { UserService } from "./api/user.service";
import { User } from "../models/user";
import { ApiService } from "./api/api.service";
import { ProfileService } from "./api/profile.service";
import { Observable, of } from "rxjs";
import { finalize, map, switchMap, tap } from "rxjs/operators";
import { AlertController, LoadingController } from "@ionic/angular";
import { Router } from "@angular/router";
import { EntityService } from './api/entity.service';
import { CoachService } from './api/coach.service';
import { ClientService } from './api/client.service';
import { CompanyService } from './api/company.service';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private _user: User | null = null;
  userObservable: Observable<any>;
  userObserver: any;

  private _jwt: string | null = null;
  jwtExpiration: Date | null = null;
  roles: string[] = [];

  constructor(
    private storage: StorageService,
    private userService: UserService,
    private profileService: ProfileService,
    private api: ApiService,
    private alertController: AlertController,
    private router: Router,
    private loadingController: LoadingController,

    /** Code added by Thomas **/
    private entityService: EntityService,
    private coach: CoachService,
    private client: ClientService,
    private company: CompanyService
  ) {
    this.userObservable = new Observable<any>(
      subscriber => {
        this.userObserver = subscriber;
        this.init();
      },
    )
  }

  async init() {
    await this.storage.init();
    const jwt = await this.storage.get('jwt_token');

    // Code added by Thomas
    if (jwt === null) {
      this.jwt = localStorage.getItem('jwt_token')
    }

    if (jwt === null) {
      this.userObserver.next(null);
      this.userObserver.complete();
      return;
    } else {
      this.jwt = jwt;
    }

    this.userService.getCurrent().subscribe(
      user => {
        this.user = user;
        this.userObserver.next(this.user);
        this.userObserver.complete();
      },
      () => {
        this.userObserver.next(null);
        this.userObserver.complete();
      },
    )
  }

  loginConnectCoach(body: { username: string, password: string }) {
    const obs = this.userService.login(body)
      .pipe(
        tap(async JWTToken => this.jwt = JWTToken.token),
        map(JWTToken => JWTToken.token),
        switchMap(() => this.userService.getCurrent()),
        // tap(x => console.log(x)),
      );

    obs
      .pipe()
      .subscribe(
        user => {
          this.user = user;
          console.log("User", this.user);
          if (user.profile != null) {
            // Code added by Thomas
            switch (user.profile.type) {
              case 'coach':
                this.coach.get(user.profile.coach.uuid).subscribe(
                  res => {
                    user.profile.coach = res
                    this.router.navigate(['/coach-home-dashboard']);
                  }
                )
                break;
              case 'client':
                this.client.get(user.profile.client.uuid).subscribe(
                  res => {
                    user.profile.client = res
                    this.router.navigate(['/coach-home-dashboard']);
                  }
                )
                break;
              case 'company':
                this.company.get(user.profile.company.uuid).subscribe(
                  res => {
                    user.profile.company = res
                    this.router.navigate(['/coach-home-dashboard']);
                  }
                )
                break;
            }
          } else {
            console.log("Else");
            this.router.navigate(['/coach-inscription-profile'])
          }

          console.log('Hello :', user)
        },
        async () => {
          const alert = await this.alertController.create({
            message: 'Identifiants incorrects',
            buttons: ['Ok'],
          });
          await alert.present();
        },
      )

    return obs;
  }

  login(body: { username: string, password: string }) {
    const obs = this.userService.login(body)
      .pipe(
        tap(async JWTToken => this.jwt = JWTToken.token),
        map(JWTToken => JWTToken.token),
        switchMap(() => this.userService.getCurrent()),
        // tap(x => console.log(x)),
      );

    this.loadingController.create({ message: 'Connexion' }).then(
      loader => {
        loader.present().then(
          () => {
            obs
              .pipe(finalize(() => loader.dismiss()))
              .subscribe(
                user => {
                  this.user = user;
                  if (user.profile != null) {
                    // Code added by Thomas
                    switch (user.profile.type) {
                      case 'coach':
                        this.coach.get(user.profile.coach.uuid).subscribe(
                          res => {
                            user.profile.coach = res
                            this.router.navigate(['/coach-home-dashboard']);
                          }
                        )
                        break;
                      case 'client':
                        this.client.get(user.profile.client.uuid).subscribe(
                          res => {
                            user.profile.client = res
                            this.router.navigate(['/coach-home-dashboard']);
                          }
                        )
                        break;
                      case 'company':
                        this.company.get(user.profile.company.uuid).subscribe(
                          res => {
                            user.profile.company = res
                            this.router.navigate(['/coach-home-dashboard']);
                          }
                        )
                        break;
                    }
                  } else {
                    this.router.navigate(['/coach-inscription-profile'])
                  }

                  console.log('Hello :', user)
                },
                async () => {
                  const alert = await this.alertController.create({
                    message: 'Identifiants incorrects',
                    buttons: ['Ok'],
                  });
                  await alert.present();
                },
              )
          }
        )
      }
    )

    return obs;
  }

  loginOnMobile(body: { username: string, password: string }) {
    const obs = this.userService.login(body)
      .pipe(
        tap(async JWTToken => this.jwt = JWTToken.token),
        map(JWTToken => JWTToken.token),
        switchMap(() => this.userService.getCurrent()),
        // tap(x => console.log(x)),
      );

    this.loadingController.create({ message: 'Connexion' }).then(
      loader => {
        loader.present().then(
          () => {
            obs
              .pipe(finalize(() => loader.dismiss()))
              .subscribe(
                user => {
                  this.user = user;
                  // Code added by Thomas
                  if (user.profile != undefined) {
                    switch (user.profile.type) {
                      case 'coach':
                        this.coach.get(user.profile.coach.uuid).subscribe(
                          res => {
                            user.profile.coach = res
                            this.router.navigate(['/m-coach-dashboard']);
                          }
                        )
                        break;
                      case 'client':
                        this.client.get(user.profile.client.uuid).subscribe(
                          res => {
                            user.profile.client = res
                            this.router.navigate(['/m-user-accueil']);
                          }
                        )
                        break;
                      case 'company':
                        this.company.get(user.profile.company.uuid).subscribe(
                          res => {
                            user.profile.company = res
                            this.router.navigate(['/coach-home-dashboard']);
                          }
                        )
                        break;
                    }
                    console.log('Hello :', user)
                  }
                  else {
                    this.router.navigate(['/m-user-inscription-step-two']);
                  }
                },
                async () => {
                  const alert = await this.alertController.create({
                    message: 'Identifiants incorrects',
                    buttons: ['Ok'],
                  });
                  await alert.present();
                },
              )
          }
        )
      }
    )
    return obs;
  }

  connectWithToken() {
    return this.userService.getCurrent().subscribe(user => {
      this._user = user
      this.coach.get(user.profile.coach.uuid).subscribe(
        res => {
          user.profile.coach = res
        }
      )
    }
    )
  }

  logout() {
    this.user = null;
    this.jwt = null;
    localStorage.removeItem('jwt_token')
    this.router.navigate(['/login']);
  }

  logoutNoRouting() {
    this.user = null;
    this.jwt = null;
    localStorage.removeItem('jwt_token')
  }

  set user(user: User | null) {
    this.userObservable = of(user as User);
    this._user = user;
  }

  get user(): User | null {
    return this._user;
  }

  get jwt(): string | null {
    return this._jwt;
  }

  set jwt(token: string | null) {
    this._jwt = token;
    this.api.token = token;
    if (token !== null) {
      this.storage.set('jwt_token', token);
      const jwt = SecurityService.parseJwt(token);
      this.jwtExpiration = new Date(jwt.exp * 1000);
      this.roles = jwt.roles;

      // Code added by Thomas
      localStorage.setItem('jwt_token', token)

    } else {
      this.storage.remove('jwt_token');
    }
  }

  private static parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));

    return JSON.parse(jsonPayload);
  };

  // For authguard
  checkForToken(): boolean | null {
    // Code added by Thomas
    if (this.jwt === null) {
      this.jwt = localStorage.getItem('jwt_token')
    }
    return !!this.jwt
  }
}
