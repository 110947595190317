import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { SecurityService } from 'src/app/services/security.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor (
    private security: SecurityService,
    private router: Router
  ) {}

  canActivate(): boolean {
    if (this.security.checkForToken()) {
      return true
    } else {
      // todo Auth Guard
      this.router.navigate(['login'])
    }
  }

  
}
