import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statistics-home-user',
  templateUrl: './statistics-home-user.component.html',
  styleUrls: ['./statistics-home-user.component.scss'],
})
export class StatisticsHomeUserComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
