import { Injectable } from '@angular/core';
import { Form } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormStorageService {

  private formTwo = new BehaviorSubject<any>(null)
  currentFormTwo = this.formTwo.asObservable();

  private formTwoBis = new BehaviorSubject<any>(null)
  currentFormTwoBis = this.formTwoBis.asObservable();

  constructor() { }

  setFormTwo(formValues: any) {
    this.formTwo.next(formValues)
  }

  setFormTwoBis(formValues: any) {
    this.formTwoBis.next(formValues)
  }
}
