import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConversationIdService {

  private firstName = new BehaviorSubject<string>('')
  currentFirstName = this.firstName.asObservable()

  private lastName = new BehaviorSubject<string>('')
  currentLastName = this.lastName.asObservable()
  
  private conversationUuid = new BehaviorSubject<string>('')
  currentConversationUuid = this.conversationUuid.asObservable()
  
  private messages = new BehaviorSubject <Array<any>>([])
  currentMessages = this.messages.asObservable()
  
  private Load = new BehaviorSubject<boolean>(false)
  currentLoad = this.Load.asObservable()
  
  constructor() { }

  setRoomData(firstName: string, lastName: string, conversationUuid: string, messages, load: boolean) {
    this.firstName.next(firstName)
    this.lastName.next(lastName)
    this.conversationUuid.next(conversationUuid)
    this.messages.next(messages)
    this.Load.next(load)
  }


}
