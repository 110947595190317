import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class IbanService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  createNewIban(body) {
    return this.http.post(this.api.getUrl(`/api/stripe/source/new/iban`), body, this.api.getOptions())
  }
}
