import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SecurityService } from 'src/app/services/security.service';
import * as moment from 'moment'
import { LoadingController } from '@ionic/angular';
import { ProfileService } from 'src/app/services/api/profile.service';
import { CoachService } from 'src/app/services/api/coach.service';
import { UserService } from 'src/app/services/api/user.service';

@Component({
  selector: 'app-info-perso',
  templateUrl: './info-perso.component.html',
  styleUrls: ['./info-perso.component.scss'],
})
export class InfoPersoComponent implements OnInit {
  public form: FormGroup;
  private loader: HTMLIonLoadingElement;

  // Page Title
  parentMessage = "Informations Personnelles";

  //
  apaProfile = false;

  // Date
  years: number[] = [];
  days: any[] = [];

  // Files variables
  diplomaFile: File | null = null;
  APADiplomaFile: File | null = null;

  // Coach Info
  userInfos;
  coachInfos;

  // Pattern
  phonePattern = "(0|\\+33|0033)[1-9][0-9]{8}"

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private security: SecurityService,
    private loadingController: LoadingController,
    private profileService: ProfileService,
    private coachService: CoachService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.minLength(10), Validators.pattern(this.phonePattern)]],
      birthDate: this.formBuilder.group({
        birthDay: ['', [Validators.required]],
        birthMonth: ['', [Validators.required]],
        birthYear: ['', [Validators.required]],
      }),
      gender: ['', [Validators.required]],
      professionalCardNumber: ['', [Validators.required]],

      diploma: ['', [Validators.required]],
      APADiploma: [{ value: '', disabled: true }],

      address: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      country: ['', [Validators.required]],
      region: ['', [Validators.required]],

      agreeApa: ['']
    });


    this.userService.getCurrent().subscribe(res => {
      this.userInfos = res

      console.log(this.userInfos)
      this.form.patchValue({
        firstName: this.userInfos.profile.coach.firstName,
        lastName: this.userInfos.profile.coach.lastName,
        address: this.userInfos.profile.address.address,
        gender: this.userInfos.profile.coach.gender,
        postalCode: this.userInfos.profile.address.postalCode,
        city: this.userInfos.profile.address.city,
        country: this.userInfos.profile.address.country,
        region: this.userInfos.profile.address.region,
      })
      this.coachService.get(this.userInfos.profile.coach.uuid).subscribe(res => {
        this.coachInfos = res
        let dateOfBirth = this.coachInfos.birthDate
        let apaDiplomaBoolean = ""
        if (this.coachInfos.APADiploma) {
          apaDiplomaBoolean = "true"
        }
        console.log(apaDiplomaBoolean)
        console.log(res)
        this.form.patchValue({
          phone: this.coachInfos.phone,
          birthDate: {
            birthDay: dateOfBirth.slice(8, 10),
            birthMonth: dateOfBirth.slice(5, 7),
            birthYear: dateOfBirth.slice(0, 4)
          },
          professionalCardNumber: this.coachInfos.professionalCardNumber,
          agreeApa: apaDiplomaBoolean

          //   diploma: ['', [Validators.required]],
          //   APADiploma: [{ value: '', disabled: true }],

          //   agreeApa: ['']
        })
      })
    })




    /*** DATE DISPLAY***/
    // Year
    let date = new Date().getFullYear()
    for (let i = 0; i <= 80; i++) {
      let year = (date - i) - 16
      this.years.push(year)
    }
    // Day
    for (let i = 1; i <= 31; i++) {
      if (i <= 9) {
        this.days.push("0" + i)
      } else {
        this.days.push(i.toString())
      }
    }
  }

  ionViewDidEnter() {

  }

  goToDashboard() {
    this.router.navigate(['/coach-home-dashboard'])
  }

  onSelectApa() {
    this.apaProfile = this.apaProfile === false;
    if (this.apaProfile) {
      this.form.controls.APADiploma.enable()
    } else {
      this.form.controls.APADiploma.disable()
    }
  }

  onFileDiplomaSelected(event) {
    this.diplomaFile = <File>event.target.files[0];
  }

  onFileAPADiplomaSelected(event) {
    this.APADiplomaFile = <File>event.target.files[0];
  }

  async onSubmit() {
    const bodyProfile = {
      user: this.userInfos.uuid,
      address: {
        city: this.form.value.city,
        address: this.form.value.address,
        postalCode: this.form.value.postalCode,
        country: this.form.value.country,
        region: this.form.value.region,
      }
    }

    const formData = new FormData();
    formData.append('firstName', this.form.value.firstName)
    formData.append('lastName', this.form.value.lastName)
    formData.append('phone', this.form.value.phone)
    formData.append('birthDate', `${this.form.controls['birthDate'].value.birthYear}-${this.form.get(['birthDate', 'birthMonth']).value}-${this.form.controls['birthDate'].value.birthDay}`)
    formData.append('gender', this.form.value.gender)
    formData.append('professionalCardNumber', this.form.value.professionalCardNumber)
    formData.append('diploma[file]', this.diplomaFile)
    formData.append('APADiploma[file]', this.APADiplomaFile)

    let func = null;
    this.loader = await this.loadingController.create({ message: 'Enregistrement des modifications...' })
    this.loader.present();


    func = this.profileService.patch(bodyProfile, this.userInfos.profile.uuid)
      .subscribe(
        profile => {
          formData.append('profile', profile.uuid)
          console.log(profile)

          /******/
          localStorage.removeItem('profileUuid')
          localStorage.setItem('profileUuid', profile.uuid)
          /******/

          this.coachService.patch(formData, profile.coach.uuid)
            .subscribe(
              res => {
                console.log(res)
                /******/
                localStorage.removeItem('coachUuid')
                localStorage.setItem('coachUuid', res.uuid)
                /******/

                this.loader.dismiss()
              },
              err => {
                localStorage.removeItem('profileUuid')
              }
            )
        }
      )
    // this.loader.dismiss()
    // this.ngOnInit()
  }


}
