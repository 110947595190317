import { Injectable } from '@angular/core';
import { Profile } from "../../models/profile";
import { Observable } from "rxjs";
import { ApiService } from "./api.service";
import { HttpClient } from "@angular/common/http";
import { Pagination } from "../../models/pagination";

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(
    private api: ApiService, 
    private http: HttpClient
  ) { }

  get(uuid: string): Observable<Profile> {
    return this.http.get<Profile>(this.api.getUrl(`/api/profile/${uuid}`), this.api.getOptions());
  }

  post(body): Observable<Profile> {
    return this.http.post<Profile>(this.api.getUrl('/api/profile'), body, this.api.getOptions());
  }

  patch(body, uuid): Observable<Profile | null> {
    return this.http.patch<Profile>(this.api.getUrl(`/api/profile/${uuid}`), body, this.api.getOptions());
  }

  postProfilePicture(body) {
    return this.http.post(this.api.getUrl('/api/profile/picture'), body, this.api.getOptions());
  }

  putProfilePicture(uuid, body) {
    return this.http.post(this.api.getUrl(`/api/profile/picture/${uuid}`), body, this.api.getOptions());
  }
}
