import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { ApiService } from './api.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TestimonyService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  get() {
    return this.http.get(this.api.getUrl(`/api/testimony`), this.api.getOptions());
  }

  getCoach(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/testimony/coach/${uuid}`), this.api.getOptions());
  }

  getSpecific(uuid: string) {
    return this.http.get(this.api.getUrl(`/api/testimony/${uuid}`), this.api.getOptions());
  }

  post(body) {
    return this.http.post(this.api.getUrl('/api/testimony'), body, this.api.getOptions());
  }

  report(uuid: string) {
    return this.http.post(this.api.getUrl(`/api/testimony/${uuid}/report`), this.api.getOptions());
  }
}