import { Component, OnInit } from '@angular/core';
import { CoachService } from 'src/app/services/api/coach.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss'],
})
export class StatisticsComponent implements OnInit {
  // Page Title
  parentMessage = "Mes paiements";
  
  fromMonth = '01'
  fromYear;
  toMonth;
  toYear;

  //
  statistics;
  statsFromDates

  // Years
  listYears:number[] = [];


  constructor(
    private coachService: CoachService
  ) { }

  ngOnInit() {
    // get formatted date of the day
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm:any;
    let dd:any;
    mm = today.getMonth() + 1; // Months start at 0!
    console.log(mm)
    dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    /*****/
    this.toMonth = mm;
    /*****/
    const formattedToday = dd + '/' + mm + '/' + yyyy;
    console.log(formattedToday)

    this.coachService.getStats('01-01-2022', formattedToday).subscribe(res => {
      this.statistics = res;
      console.log(this.statistics)
    })

    for (let i = 2000; i <= yyyy; i++) {
      this.listYears.push(i)
    }
    this.fromYear = this.listYears[this.listYears.length - 1]
    this.toYear = this.listYears[this.listYears.length - 1]
    console.log(this.listYears)

    this.onStatFromDates()
  }

  onStatFromDates() {
    this.coachService.getStats(`01-${this.fromMonth}-${this.fromYear}`, `01-${this.toMonth}-${this.toYear}`).subscribe(res => {
      this.statsFromDates = res;
    })
  }
}
