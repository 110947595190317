import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';

import { AgmCoreModule } from '@agm/core';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { CommonModule } from '@angular/common'

//GOOGLE PLACE AUTOCOMPLETE
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import { GoogleLoginProvider } from 'angularx-social-login';
import { FacebookLoginProvider } from "angularx-social-login";

import { MatCarouselModule } from '@ngmodule/material-carousel';


import { NgChartsModule } from 'ng2-charts';

import { CalendarModule } from 'angular-calendar';
import { SchedulerModule } from 'angular-calendar-scheduler';

//FULLCALENDAR
//import { Calendar } from '@fullcalendar/core';
//import dayGridPlugin from '@fullcalendar/daygrid';
import { FullCalendarModule } from '@fullcalendar/angular';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';


/** ANGULAR MATERIAL **/
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge'
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { MatRippleModule } from '@angular/material/core';


import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

/**ANGULAR CDK */
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';


import { CarouselModule } from 'primeng/carousel';

import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';

// APPLICATION DESKTOP //
/** NEW COMPONENTS **/
// SHARED COMPONENTS
import { HeaderComponent } from './web/shared-components/header/header.component';
import { HeaderInscriptionComponent } from './web/shared-components/header-inscription/header-inscription.component';
import { FooterComponent } from './web/shared-components/footer/footer.component';

/** COACH **/
// FORM
import { StatusComponent } from './web/coach/form/status/status.component';
import { ProfileComponent } from './web/coach/form/profile/profile.component';
import { StatusBisComponent } from './web/coach/form/status-bis/status-bis.component';
import { FieldsComponent } from './web/coach/form/fields/fields.component';
import { DescriptionComponent } from './web/coach/form/description/description.component';
import { DoneComponent } from './web/coach/form/done/done.component';

// LANDING PAGE
import { LandingPageComponent } from './web/coach/landing-page/landing-page.component';
import { GymComponent } from './web/coach/form/gym/gym.component';

// MODAL COACH
import { ModalStatusComponent } from './web/coach/form/modal/modal-status/modal-status.component';
import { ModalSapComponent } from './web/coach/form/modal/modal-sap/modal-sap.component';
import { ModalRegisteredComponent } from './web/coach/form/modal/modal-registered/modal-registered.component';
import { ModalMailComponent } from './web/coach/form/modal/modal-mail/modal-mail.component';
import { EmailComponent } from './web/coach/form/email/email.component';
import { ModalCgcComponent } from './web/coach/form/modal/modal-cgc/modal-cgc.component';

// DASHBOARD
import { DashboardComponent } from './web/coach/home/dashboard/dashboard.component';
import { AgendaComponent } from './web/coach/home/dashboard/agenda/agenda.component';
import { CreateBootcampComponent } from './web/coach/home/dashboard/create-bootcamp/create-bootcamp.component';
import { InfoBankComponent } from './web/coach/home/dashboard/info-bank/info-bank.component';
import { InfoPersoComponent } from './web/coach/home/dashboard/info-perso/info-perso.component';
import { InvoicesComponent } from './web/coach/home/dashboard/invoices/invoices.component';
import { StatisticsComponent } from './web/coach/home/dashboard/statistics/statistics.component';
import { StatusProComponent } from './web/coach/home/dashboard/status-pro/status-pro.component';
import { MyProfileComponent } from './web/coach/home/dashboard/my-profile/my-profile.component';
import { MessagerieComponent } from './web/coach/home/dashboard/messagerie/messagerie.component';
import { ConversationsComponent } from './web/coach/home/dashboard/messagerie/conversations/conversations.component';
import { MessagesComponent } from './web/coach/home/dashboard/messagerie/messages/messages.component';

// USER
import { HomeUserComponent } from './web/user/home-user/home-user.component';
import { FindCoachUserComponent } from './web/user/home-user/find-coach-user/find-coach-user.component';
import { WelcomeUserComponent } from './web/user/home-user/welcome-user/welcome-user.component';
import { StatisticsHomeUserComponent } from './web/user/home-user/statistics-home-user/statistics-home-user.component';
import { ArgumentsHomeUserComponent } from './web/user/home-user/arguments-home-user/arguments-home-user.component';
import { TemoignageUserComponent } from './web/user/home-user/temoignage-user/temoignage-user.component';
import { SponsorsUserComponent } from './web/user/home-user/sponsors-user/sponsors-user.component';
import { FaqUserComponent } from './web/user/faq-user/faq-user.component';


import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { IonicStorageModule } from '@ionic/storage-angular';
import { StorageService } from './services/storage.service';


import { NgCalendarModule } from 'ionic2-calendar';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { WLoginComponent } from './web/w-login/w-login.component';
import { SubHeaderComponent } from './web/shared-components/sub-header/sub-header.component';
import { SideMenuComponent } from './web/shared-components/side-menu/side-menu.component';
import { SidebarComponent } from './web/shared-components/sidebar/sidebar.component';
import { CgvCguComponent } from './web/others/cgv-cgu/cgv-cgu.component';
import { MentionsLegalesComponent } from './web/others/mentions-legales/mentions-legales.component';
import { BlogComponent } from './web/others/blog/blog.component';
import { ModalMentionsLegalesComponent } from './web/coach/form/modal/modal-mentions-legales/modal-mentions-legales.component';
import { ModalCguComponent } from './web/coach/form/modal/modal-cgu/modal-cgu.component';
import { ModalCgvComponent } from './web/coach/form/modal/modal-cgv/modal-cgv.component';

//import { CalendarWeekHoursViewModule } from '@imanro/angular-calendar-week-hours-view'


// SHARED
// import { FormErrorComponent } from './helper/form-error/form-error.component';

// MOBILE

// Notifications
import { NotificationsComponent } from './web/shared-components/notifications/notifications.component';
import { MatMenuModule } from '@angular/material/menu';
import { BootcampCreatedComponent } from './web/coach/home/dashboard/modal/bootcamp-created/bootcamp-created.component';
import { HeaderLoggedOutComponent } from './web/shared-components/header-logged-out/header-logged-out.component';
import { ChatComponent } from './web/coach/home/dashboard/chat/chat.component';



FullCalendarModule.registerPlugins([
  interactionPlugin,
  dayGridPlugin,
  timeGridPlugin
])



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HeaderLoggedOutComponent,
    SideMenuComponent,
    HeaderInscriptionComponent,
    FooterComponent,
    EmailComponent,
    ProfileComponent,
    StatusComponent,
    StatusBisComponent,
    FieldsComponent,
    DescriptionComponent,
    LandingPageComponent,
    GymComponent,
    DoneComponent,
    ModalStatusComponent,
    ModalSapComponent,
    ModalRegisteredComponent,
    ModalMailComponent,
    ModalCgcComponent,
    ModalCguComponent,
    ModalCgvComponent,
    ModalMentionsLegalesComponent,
    DashboardComponent,
    AgendaComponent,
    CreateBootcampComponent,
    InfoBankComponent,
    InfoPersoComponent,
    InvoicesComponent,
    ChatComponent,
    MessagerieComponent,
    ConversationsComponent,
    MyProfileComponent,
    StatisticsComponent,
    StatusProComponent,
    HomeUserComponent,
    FindCoachUserComponent,
    WelcomeUserComponent,
    StatisticsHomeUserComponent,
    ArgumentsHomeUserComponent,
    TemoignageUserComponent,
    SponsorsUserComponent,
    FaqUserComponent,
    MessagesComponent,
    WLoginComponent,
    SubHeaderComponent,
    SidebarComponent,
    CgvCguComponent,
    MentionsLegalesComponent,
    BlogComponent,
    NotificationsComponent,
    BootcampCreatedComponent

  ],
  entryComponents: [
    ModalStatusComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSelectModule,
    MatDialogModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    CdkAccordionModule,
    ClipboardModule,
    CarouselModule,
    NgbModule,
    NgChartsModule,
    NgxMaterialTimepickerModule,
    MatCheckboxModule,
    MatBadgeModule,
    MatTooltipModule,
    CalendarModule,
    SchedulerModule.forRoot({ locale: 'en', headerDateFormat: 'daysRange' }),
    NgCalendarModule,
    NgMultiSelectDropDownModule,
    //CalendarWeekHoursViewModule,
    MatRippleModule,

    //Calendar,
    //dayGridPlugin,
    FullCalendarModule,

    CommonModule,

    MatExpansionModule,
    GooglePlaceModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAnA7JB9tiXqVnTbVp6uWjEqLHqzG_WJ1M',
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule,

    MatCarouselModule.forRoot(),
    FormsModule,

    SocialLoginModule,
    MatMenuModule
  ],
  providers: //[{provide: RouteReuseStrategy },
    [
      {
        provide: 'SocialAuthServiceConfig',
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: GoogleLoginProvider.PROVIDER_ID,
              provider: new GoogleLoginProvider('74008783875-g25bug3fh2f0hamvmrimok03hvcd3j6h.apps.googleusercontent.com'),
            },
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider("1472530256495596")
            }
          ],
        } as SocialAuthServiceConfig
      },
      /*{ 
        provide: 'SocialAuthServiceConfig', 
        useValue: {
          autoLogin: false,
          providers: [
            {
              id: FacebookLoginProvider.PROVIDER_ID,
              provider: new FacebookLoginProvider("YourFacebookAppID")
            }
          ],
        } as SocialAuthServiceConfig
      },*/
      SplashScreen,
      StatusBar,
      {
        provide: RouteReuseStrategy,
        useClass: IonicRouteStrategy,
      },
      OneSignal,
    ],
  bootstrap: [AppComponent],
  // schemas: [
  //   CUSTOM_ELEMENTS_SCHEMA
  // ]
})
export class AppModule {
  constructor(private storageService: StorageService) {
  }
}
