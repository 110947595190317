import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/api/user.service';

@Component({
  selector: 'app-messagerie',
  templateUrl: './messagerie.component.html',
  styleUrls: ['./messagerie.component.scss'],
})
export class MessagerieComponent implements OnInit {
  // Page Title
  parentMessage = "Messagerie";

  // Usernam
  us

  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.userService.getCurrent().subscribe(res => {
      console.log(res)
    })
  }

}
