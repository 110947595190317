import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from 'src/app/models/invoice';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(
    private api: ApiService,
    private http: HttpClient
  ) { }

  getInvoices() {
    return this.http.get(this.api.getUrl('/api/invoice'), this.api.getOptions());
  }

  postInvoice(body) {
    return this.http.post(this.api.getUrl('/api/invoice'), body, this.api.getOptions());
  }
}
