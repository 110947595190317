import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ToggleMenuService {

  private collapsed = new BehaviorSubject(true)
  currentCollapsed = this.collapsed.asObservable()

  constructor() { }

  changeCollapsed(collapsed: boolean) {
    this.collapsed.next(collapsed)
  }
}
