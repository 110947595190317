import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalGettersService } from 'src/app/services/api/global-getters.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { SecurityService } from 'src/app/services/security.service';
import { UserService } from 'src/app/services/api/user.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {


  pages;
  actualPages;
  notifications = <any>[];

  // Service to update notifications display
  nbrOfNotifications;

  //
  notificationsOnInit;
  
  //
  unreadNotifications: any;
  nbrOfPagesUN;
  

  userInfos;
  notificationsTotal;
  //
  page : number;
  pageLimit: number;

  constructor(
    private globalGetters: GlobalGettersService,
    private security: SecurityService,
    private notificationsService: NotificationsService,
    private router: Router,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.notificationsService.currentNotifications.subscribe(res => this.nbrOfNotifications = res)

    this.userService.getCurrent().subscribe(res => {
      this.userInfos = res
      console.log(this.userInfos)
    })


    this.globalGetters.getNoticationSummary().subscribe(res => {
      console.log(res)
      this.notificationsOnInit = res["notifications"]
    })

    this.getNotifications(this.page = 1, true)
    console.log("ici")
    console.log(this.nbrOfNotifications)
    console.log("ici")
    
  }

  

  ionViewWillEnter() {
    if ((this.notificationsOnInit) && (this.notificationsOnInit !== this.nbrOfNotifications)) {
      this.getNotifications(this.page = 1, true)
    }
  }

  getNotifications(page: number, isRead: boolean): void {
    this.globalGetters.getNotifications(page, isRead, 20).subscribe(res => {
      console.log(res)
      this.notificationsTotal = res["totalItemCount"]
      this.pageLimit = res["pageCount"]

      this.notifications = [...res['items']]
      this.page++
      console.log(this.notificationsTotal)
    })
  }
  

  loadData(event) {
    if (this.page  >= this.pageLimit) {
      return
    }

    this.globalGetters.getNotifications(this.page, true, 20).subscribe(res => {
      this.notifications = [ ...this.notifications, ...res['items']]
      this.page++
      event.target.complete()
    })
  }

  goToCoachProfil(roomUuid, uuid) {
    this.router.navigate(['/coach-home-messages/']);
    this.readANotification(uuid, roomUuid)
  }

  goToUserProfil(roomUuid, uuid) {
    this.router.navigate(['/m-user-messages//' + roomUuid]);
    this.readANotification(uuid, roomUuid)
  }

  readANotification(uuid: string, roomUuid) {     
    // Get all the unread notications
    this.globalGetters.getNotifications(1, false, 100).subscribe(res => {
      this.unreadNotifications = res["items"]
      this.nbrOfPagesUN = res["pageCount"]
      let itemsProcessed = 1
      if (this.nbrOfPagesUN > 1) {
        for (let i = 2; i < this.nbrOfPagesUN ; i++) {
          this.globalGetters.getNotifications(i, false, 100).subscribe(res => {
            this.unreadNotifications = [ ...this.unreadNotifications, ...res["items"] ]
            itemsProcessed++
            if (itemsProcessed === this.nbrOfPagesUN) {
              // Read all the notications from the same room
              let notifcationsProcessed
              this.unreadNotifications.forEach((item) => {
                if (item.metadata?.room === roomUuid) {
                  this.globalGetters.getReadNotification(item.uuid).subscribe(res => {
                    console.log(res)
                    notifcationsProcessed++
                    if (notifcationsProcessed === this.unreadNotifications.length) {
                      this.updateNotifications()
                    }
                  })
                } else {
                  notifcationsProcessed++
                  if (notifcationsProcessed === this.unreadNotifications.length) {
                    this.updateNotifications()
                  }
                }
              })
            }
          })
        }
      } else {
        this.unreadNotifications.forEach((item, index) => {
          if (item.metadata?.room === roomUuid) {
            this.globalGetters.getReadNotification(item.uuid).subscribe(res => {
              if (this.unreadNotifications.length === (index + 1)) {
                this.updateNotifications()
              }
            })
          }
        })
      }
    })
  }

  readAllRelativesNotifications() {

  }

  updateNotifications() {
    this.globalGetters.getNoticationSummary().subscribe(res => {
      this.notificationsService.setNotifications(res["notifications"])
    })
  }




}
