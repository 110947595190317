import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-temoignage-user',
  templateUrl: './temoignage-user.component.html',
  styleUrls: ['./temoignage-user.component.scss'],
})
export class TemoignageUserComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
