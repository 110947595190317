import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import * as moment from 'moment';
import { BootcampService } from 'src/app/services/api/bootcamp.service';
import { SportService } from 'src/app/services/api/sport.service';
import { SecurityService } from 'src/app/services/security.service';
import { BootcampCreatedComponent } from '../modal/bootcamp-created/bootcamp-created.component';

@Component({
  selector: 'app-create-bootcamp',
  templateUrl: './create-bootcamp.component.html',
  styleUrls: ['./create-bootcamp.component.scss'],
})
export class CreateBootcampComponent implements OnInit {
  public form: FormGroup;
  private loader: HTMLIonLoadingElement;

  // Page Title
  parentMessage = "Création d'un bootcamp";

  url: any;
  message: string;
  imagePath: string;

  // Sports
  sportsRes: any;
  sports: [];

  // MAT INPUT
  fromDay: string;
  fromMonth: string | number;
  fromYear;
  fromHour = "09:00:00"
  toHour = "17:00:00"

  // Files variables
  picture: File | null = null;

  // Date
  years: number[] = [];
  days: any[] = [];

  //
  errorMsgDate;

  // Hours
  hours = [
    { index: 0, name: "00h", value: "00:00:00" },
    { index: 1, name: "00h30", value: "00:30:00" },
    { index: 2, name: "01h", value: "01:00:00" },
    { index: 3, name: "01h30", value: "01:30:00" },
    { index: 4, name: "02h", value: "02:00:00" },
    { index: 5, name: "02h30", value: "02:30:00" },
    { index: 6, name: "03h", value: "03:00:00" },
    { index: 7, name: "03h30", value: "03:30:00" },
    { index: 8, name: "04h", value: "04:00:00" },
    { index: 9, name: "04h30", value: "04:30:00" },
    { index: 10, name: "05h", value: "05:00:00" },
    { index: 11, name: "05h30", value: "05:30:00" },
    { index: 12, name: "06h", value: "06:00:00" },
    { index: 13, name: "06h30", value: "06:30:00" },
    { index: 14, name: "07h", value: "07:00:00" },
    { index: 15, name: "07h30", value: "07:30:00" },
    { index: 16, name: "08h", value: "08:00:00" },
    { index: 17, name: "08h30", value: "08:30:00" },
    { index: 18, name: "09h", value: "09:00:00" },
    { index: 19, name: "09h30", value: "09:30:00" },
    { index: 20, name: "10h", value: "10:00:00" },
    { index: 21, name: "10h30", value: "10:30:00" },
    { index: 22, name: "11h", value: "11:00:00" },
    { index: 23, name: "11h30", value: "11:30:00" },
    { index: 24, name: "12h", value: "12:00:00" },
    { index: 25, name: "12h30", value: "12:30:00" },
    { index: 26, name: "13h", value: "13:00:00" },
    { index: 27, name: "13h30", value: "13:30:00" },
    { index: 28, name: "14h", value: "14:00:00" },
    { index: 29, name: "14h30", value: "14:30:00" },
    { index: 30, name: "15h", value: "15:00:00" },
    { index: 31, name: "15h30", value: "15:30:00" },
    { index: 32, name: "16h", value: "16:00:00" },
    { index: 33, name: "16h30", value: "16:30:00" },
    { index: 34, name: "17h", value: "17:00:00" },
    { index: 35, name: "17h30", value: "17:30:00" },
    { index: 36, name: "18h", value: "18:00:00" },
    { index: 37, name: "18h30", value: "18:30:00" },
    { index: 38, name: "19h", value: "19:00:00" },
    { index: 39, name: "19h30", value: "19:30:00" },
    { index: 40, name: "20h", value: "20:00:00" },
    { index: 41, name: "20h30", value: "20:30:00" },
    { index: 42, name: "21h", value: "21:00:00" },
    { index: 43, name: "21h30", value: "21:30:00" },
    { index: 44, name: "22h", value: "22:00:00" },
    { index: 45, name: "22h30", value: "22:30:00" },
    { index: 46, name: "23h", value: "23:00:00" },
    { index: 47, name: "23h30", value: "23:30:00" },
  ]

  months = [
    { index: 0, value: "01", name: "Janvier" },
    { index: 1, value: "02", name: "Février" },
    { index: 2, value: "03", name: "Mars" },
    { index: 3, value: "04", name: "Avril" },
    { index: 4, value: "05", name: "Mai" },
    { index: 5, value: "06", name: "Juin" },
    { index: 6, value: "07", name: "Juillet" },
    { index: 7, value: "08", name: "Août" },
    { index: 8, value: "09", name: "Septembre" },
    { index: 9, value: "10", name: "Octobre" },
    { index: 10, value: "11", name: "Novembre" },
    { index: 11, value: "12", name: "Décembre" },
  ]

  //-----------------GOOGLE MAPS---------------------------
  options = {
    offset: 3,
    componentRestrictions: { country: "fr" }
    /*fields: ["address_components", "geometry", "icon", "name"],
    strictBounds: false,*/
    //types: ["gym"],
  };
  title = 'google-places-autocomplete';
  userAddress: string = ''
  userLatitude: string = ''
  userLongitude: string = ''

  //
  type: any;
  address: any;
  city: any;
  country: any;
  postalCode: any;
  streetNumber: any;
  streetAddress: any;
  region: any;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private security: SecurityService,
    private bootcampService: BootcampService,
    private loadingController: LoadingController,
    private sportService: SportService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {

    this.form = this.formBuilder.group({
      photo: [null, [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      instructions: ['', [Validators.required]],
      // startAt: ['', [Validators.required]],
      // endAt: ['', [Validators.required]],
      day: ['', [Validators.required]],
      month: ['', [Validators.required]],
      year: ['', [Validators.required]],
      hourStart: ['', [Validators.required]],
      hourEnd: ['', [Validators.required]],
      maxParticipants: [15, [Validators.required]],
      // address: this.formBuilder.group({
      //   city: ['', [Validators.required]],
      //   address: ['', [Validators.required]],
      //   postalCode: ['', [Validators.required]],
      //   country: ['', [Validators.required]],
      //   region: ['', [Validators.required]],
      // }),
      sport: ['', [Validators.required]],
      place: ['', [Validators.required]]
    })

    // Show All Sports
    this.sportService.get().subscribe(res => {
      this.sportsRes = res;
      this.sports = this.sportsRes.items
    })

    /*** DATE DISPLAY***/
    // Year
    let date = new Date().getFullYear()
    this.fromYear = date
    for (let i = 0; i <= 5; i++) {
      let year = (date + i)
      this.years.push(year)
    }
    // Day
    for (let i = 1; i <= 31; i++) {
      if (i <= 9) {
        this.days.push("0" + i)
      } else {
        this.days.push(i.toString())
      }
    }

    // DAY
    let day = new Date().getDate()
    this.fromDay = day.toString()
    console.log("jour")
    console.log(day)

    // MONTH
    let month: number | string = new Date().getMonth()
    console.log(month)
    month = month + 1
    console.log(month)
    if (month <= 9) {
      month = "0" + month
    } else {
      month.toString()
    }
    this.fromMonth = month
    console.log(month)
  }

  onFileChanged(event) {
    const files = event.target.files;
    if (files.length === 0)
      return;

    const mimeType = files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      this.message = 'Seul les fichiers images sont acceptés';
      return;
    }

    const reader = new FileReader();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    reader.onload = (_event) => {
      this.url = reader.result;
    };

    this.picture = <File>event.target.files[0];
    this.form.patchValue({
      photo: true
    })
  }

  onChangeHour() {
    const startHour = this.hours.find(el => el.value === this.form.value.hourStart)
    const endHour = this.hours.find(el => el.value === this.form.value.hourEnd)
  
    if (startHour.index >= endHour.index) {
      console.log("form invalid")
    }
  }

  onDate() {
    let validDate = moment(`${this.form.value.year}-${this.form.value.month}-${this.form.value.day}`).isValid();
    console.log(validDate)
  }

  isInThePast(date) {
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    today.getTime()
    const formatDate = new Date(date)
    formatDate.setHours(0, 0, 0, 0)
    formatDate.getTime()
    
    console.log("compare")
    console.log(formatDate)
    console.log(today)

    console.log(formatDate)

    return formatDate >= today;
  }

  async onSubmit() {
    console.log(this.form.getRawValue())
    
    this.findInvalidControls()
    let birthDateFormatted = `${this.form.value.year}-${this.form.value.month}-${this.form.value.day}`
    console.log("1")
    console.log(this.isInThePast(birthDateFormatted))
    if(!this.isInThePast(birthDateFormatted)) {
      console.log('2')
      this.errorMsgDate = "Vous ne pouvez pas choisir une date dans le passé"
      return
    } else {
      this.errorMsgDate = ""
    }
    console.log('3')

    let dateValid = moment(birthDateFormatted).isValid()
    console.log(dateValid)
    if (!dateValid) {
      this.errorMsgDate = "La date choisie n'est pas valide"
      return
    } else {
      this.errorMsgDate = ""
    }

    const startHour = this.hours.find(el => el.value === this.form.value.hourStart)
    const endHour = this.hours.find(el => el.value === this.form.value.hourEnd)
    if (startHour.index >= endHour.index) {
      this.errorMsgDate = "L'heure de fin doit être supérieure à l'heure de début"
      return
    } else {
      this.errorMsgDate = ""
    }

    if (this.form.valid) {
      console.log('form is valid')

      const body = {
        title: this.form.value.title,
        description: this.form.value.description,
        instructions: this.form.value.instructions,
        startAt: this.form.value.startAt,
        endAt: this.form.value.endAt,
        maxParticipants: this.form.value.maxParticipants,
        sport: this.form.value.sport,
      }

      console.log(body)
      let startAt = `${this.form.value.year}-${this.form.value.month}-${this.form.value.day} ${this.form.value.hourStart}`
      let endAt = `${this.form.value.year}-${this.form.value.month}-${this.form.value.day} ${this.form.value.hourEnd}`

      const formData = new FormData();
      formData.append('title', this.form.value.title)
      formData.append('description', this.form.value.description)
      formData.append('instructions', this.form.value.instructions)
      formData.append('startAt', startAt)
      formData.append('endAt', endAt)
      formData.append('address[city]', this.city)
      formData.append('address[address]', `${this.streetNumber}, ${this.streetAddress} `)
      formData.append('address[postalCode]', this.postalCode)
      formData.append('address[country]', this.country)
      formData.append('address[region]', this.region)
      formData.append('address[longitude]', this.userLongitude)
      formData.append('address[latitude]', this.userLatitude)
      formData.append('minParticipants', '15')
      formData.append('maxParticipants', this.form.value.maxParticipants)
      formData.append('sport', this.form.value.sport)
      formData.append('thumbnail[file]', this.picture)

      console.log(formData)

      let func = null;
      this.loader = await this.loadingController.create({ message: 'Enregistrement du bootcamp' })
      await this.loader.present();

      func = this.bootcampService.post(formData)
        .subscribe(res => {
          console.log(res)

          this.loader.dismiss();

          const dialogRef = this.dialog.open(BootcampCreatedComponent, {
            width: '550px'
          });

          dialogRef.afterClosed().subscribe(() =>  this.ngOnInit());         

        },
        (err) => {
          console.log(err);
          this.loader.dismiss();
        })

    }
  }

  onNgOnInit() {
    this.ngOnInit();
  }

  findInvalidControls() {
    const invalid = [];
    const controls = this.form.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    return invalid;
  }

  // RECUPERE LES INFORMATIONS DE LOCALISATION DU CHAMPS D'AUTOCOMLETION
  handleAddressChange(address: any) {
    this.userAddress = address.formatted_address
    this.userLatitude = address.geometry.location.lat()
    this.userLongitude = address.geometry.location.lng()
    this.type = address.types
    this.address = address.formatted_address;

    for (const el of address.address_components) {
      if (el.types[0] == 'locality') {
        this.city = el.long_name
      }
      else if (el.types[0] == 'country') {
        this.country = el.long_name
      }
      else if (el.types[0] == 'postal_code') {
        this.postalCode = el.long_name
      }
      else if (el.types[0] == 'street_number') {
        this.streetNumber = el.long_name
      }
      else if (el.types[0] == 'route') {
        this.streetAddress = el.long_name
      }
      else if (el.types[0] == 'administrative_area_level_1') {
        this.region = el.long_name
      }
    }
  }

}
