import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-find-coach-user',
  templateUrl: './find-coach-user.component.html',
  styleUrls: ['./find-coach-user.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translateX(0)' })),
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in')
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ transform: 'translateX(100%)' }))
      ])
    ])
  ]
})
export class FindCoachUserComponent implements OnInit {
  selectedType = "option1";
  date = new FormControl(new Date());
  selectedDiscipline = "option1";
  
  flyInVar: string = 'nothing';
  list_order: number = 0;
  isDisabled = true;

  myTimeout;
  slide() {
    var delay : number;
    if (this.list_order == 3) {
      delay = 0;
    }
    else {
      delay = this.list_order + 1;
    }
    this.list_order = -1
    this.myTimeout = setTimeout(() => this.list_order = delay,301)
  }


  onDone(i) {
    if (i == this.list_order) {
      this.myTimeout = setTimeout(() => this.slide(),3000)
    }
  }

  switchTo(i) {
    this.myTimeout = setTimeout(() => this.list_order = i,0 )
    //this.myTimeout = setTimeout(() => this.list_order = i,10)
    setTimeout(() => clearTimeout(this.myTimeout),2000)
    
    //this.myTimeout = setTimeout(() => this.isDisabled = true,3000)
  }

  change() {
    this.list_order = 3;
  }

  constructor() { }

  ngOnInit() {
    //this.isDisabled = false;
    this.myTimeout = setTimeout(() => this.isDisabled = false,2000);


    /*document.addEventListener('DOMContentLoaded', e => {
      
      var canvas = <HTMLCanvasElement>document.getElementById('canvas');
        if (canvas.getContext) {
          var ctx = canvas.getContext('2d');

          ctx.lineWidth = 5;
          ctx.beginPath();
          ctx.arc(75, 75, 50, 0, Math.PI * 2, true);  // Cercle extérieur
          ctx.moveTo(0,150);
          ctx.font = '24px serif';
          ctx.fillText('Hello world', 10, 50);
          ctx.stroke();
          ctx.fillStyle = 'yellow';
          ctx.beginPath();
          ctx.arc(200, 106, 50, Math.PI/2, 3*Math.PI/2, true);
          ctx.lineTo(200, 106);
          ctx.fill();
        }
    })*/


    
  }

}
