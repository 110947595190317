import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private notifications = new BehaviorSubject<number>(0);
  currentNotifications = this.notifications.asObservable();

  private notifInProgress = new BehaviorSubject<boolean>(false);
  currentNotifInProgress = this.notifInProgress.asObservable();

  constructor() { }

  setNotifications(nbrNotifications:number) {
    this.notifications.next(nbrNotifications)
  }

  setNotifInProgress(isOver:boolean) {
    this.notifInProgress.next(isOver)
  }
}